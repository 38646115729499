import React, {Component} from 'react';

import profile from '../../images/profile.jpg';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class accountInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previousPassword: '',
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            changes: {
                oldPassword: false,
                newPassword: false,
                confirmPassword: false,
                button1: false,
                button2: false,
            },
            passwordDisplay: 'none',
        }

        this.handleChange = this.handleChange.bind(this);
        this.pushChanges = this.pushChanges.bind(this);

        this.renderButton = this.renderButton.bind(this);
        this.renderPasswordDisplay = this.renderPasswordDisplay.bind(this);
    }

    componentWillMount() {
        const p = this.props.data('users', this.props.firebase.auth().currentUser.uid);
        this.setState({previousPassword: p['password']});
    }

    handleChange(event, label) {
        const d = this.props.data('users', this.props.firebase.auth().currentUser.uid);
        var changes = this.state.changes;
        if (label === 'oldPassword') {
            this.setState({oldPassword: event.target.value});
            if (event.target.value.length === 0) {
                changes['oldPassword'] = false;
            } else {
                changes['oldPassword'] = true;
            }
        } else if (label === 'newPassword') {
            this.setState({newPassword: event.target.value});
            if (event.target.value.length === 0) {
                changes['newPassword'] = false;
            } else {
                changes['newPassword'] = true;
            }
        } else if (label === 'confirmPassword') {
            this.setState({confirmPassword: event.target.value});
            if (event.target.value.length === 0) {
                changes['confirmPassword'] = false;
            } else {
                changes['confirmPassword'] = true;
            }
        } else if (label === 'button2') {
            //UPDATE
            //this.setState({lastName: event.target.value});
            const contents = [
                'oldPassword',
                'newPassword',
                'confirmPassword',
            ];
            this.pushChanges(this.props.firebase.auth().currentUser.uid, contents, 'password');
        } 

        if ((this.state.changes.oldPassword) && (this.state.changes.newPassword) && (this.state.changes.confirmPassword)) {
            changes['button2'] = true;
        } else {
            changes['button2'] = false;
        }
        this.setState({changes: changes});
    }

    pushChanges(id, contentlist, type=false) {
        
        if (type === 'password') {
            if (true) {
                if (this.state.newPassword === this.state.confirmPassword) {
                    //this.props.updateData('users', this.props.firebase.auth().currentUser.uid, 'password', this.state.newPassword);
                    this.props.firebase.auth().signInWithEmailAndPassword(this.props.data('users', this.props.firebase.auth().currentUser.uid)['email'], this.state.oldPassword).then(() => {
                        this.props.firebase.auth().currentUser.updatePassword(this.state.newPassword).then(() => {
                            this.setState({passwordDisplay: 'success'});
                        }).catch((error) => {
                            //console.log(error);
                            this.setState({passwordDisplay: 'strongerPassword'});
                        });
                    }).catch((error) => {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        this.setState({passwordDisplay: 'oldPassword'});
                        //this.setState({passwordDisplay: 'other'});
                        // ...
                    });
                } else {
                    this.setState({passwordDisplay: 'matchPassword'});
                }
            } else {
                //this.setState({passwordDisplay: 'oldPassword'});
            }
        }
        var incompletelist = this.resolveIncomplete(contentlist);
        if (!incompletelist) {
            //everything filled out
            for (var val in contentlist) {
                //this.props.updateData('users', this.props.firebase.auth().currentUser.uid, contentlist[val], this.state[contentlist[val]]);
            }
        } else {
            this.setState({passwordDisplay: 'allFields'});
            //alert('TODO: ui for incorrect fields');
        }
    }

    resolveIncomplete(contentlist) {
        var incompletelist = [];
        for (var val in contentlist) {
            if (this.state[contentlist[val]] === '') {
                incompletelist.push(contentlist[val]);
            }
        }
        if (incompletelist.length === 0) {
            return false;
        } else {
            return incompletelist;
        }
    }

    renderButton(button, active) {
        if (button === 'button1') {
            if (active) {
                return (
                    <div className='btn-one' onClick={(e) => this.handleChange(e, 'button1')}>Update</div>
                )
            } else {
                return (
                    <div className='btn-inactive' onClick={(e) => this.handleChange(e, 'button1')} disabled >Update</div>
                )
            }
        } else if (button === 'button2') {
            if (active) {
                return (
                    <div className='btn-one' onClick={(e) => this.handleChange(e, 'button2')}>Update</div>
                )
            } else {
                return (
                    <div className='btn-inactive' onClick={(e) => this.handleChange(e, 'button2')} disabled >Update</div>
                )
            }
        }
    }

    renderPasswordDisplay() {
        if (this.state.passwordDisplay === "none") {
            return (
                <div className='incorrect-field'></div>
            )
        } else if (this.state.passwordDisplay === "oldPassword") {
            return (
                <div className='incorrect-field'>Please check the old password you entered</div>
            )
        } else if (this.state.passwordDisplay === "matchPassword") {
            return (
                <div className='incorrect-field'>New passwords did not match</div>
            )
        } else if (this.state.passwordDisplay === "success") {
            return (
                <div className='incorrect-field'>Successfully updated password</div>
            )
        } else if (this.state.passwordDisplay === "strongerPassword") {
            return (
                <div className='incorrect-field'>Please enter a stronger password (at least 6 characters)</div>
            )
        } else if (this.state.passwordDisplay === "allFields") {
            return (
                <div className='incorrect-field'>Please fill out all fields</div>
            )
        } else {
            return (
                <div className='incorrect-field'>We are unable to process your request at this time</div>
            )
        }
    }

    render() {
        return(
        <div>
            <head>
                <meta charSet='UTF-8'/>
                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                <title>Profile</title>
            </head>
            <body>
                <section className='se-page'>
                    <section className='se-navigation-section'>
                    <Link to="/myprofile?id=profileview">
                        <div className='se-navigation-row'>My Profile</div>
                    </Link>
                    <Link to="/myprofile?id=accountinformation">
                        <div className='se-navigation-row'>
                            <div className='active-nav'>Account Information</div>
                        </div>
                    </Link>
                    <Link to="/myprofile?id=payout">
                        <div className='se-navigation-row'>Payout</div>
                    </Link>
                    <Link to="/myprofile?id=notificationsettings">
                        <div className='se-navigation-row'>Notification Settings</div>
                    </Link>
                    </section>
                    <section className='se-content-section'>
                        {/*
                        <div className='se-content-container'>
                            <div className='se-content-header'>Account Information</div>
                            <div className='se-content'>
                                <div className='se-content-subheader'>Information on this page will never be publically shared. We will use this for login, notification, and verification purposes.</div>
                                <div className='se-content-row'>
                                    <label>Email</label>
                                    <div>{this.props.data('users', this.props.firebase.auth().currentUser.uid)['email']}</div>
                                </div>
                                <div className='se-content-row'>
                                    <label>Phone Number</label>
                                    <div className='se-phone-container'>
                                    <input type='text' value={this.state.phone} onChange={(e) => this.handleChange(e, 'phone')}/>
                                    </div>
                                </div>
                                <div className='se-btn-row'>
                                    <label></label>
                                    {this.renderButton('button1', this.state.changes.button1)}
                                </div>
                            </div>
                        </div>
                        */}
                        <div className='se-content-container'>
                            <div className='se-content-header'>Change Password</div>
                            <div className='se-content'>
                            <div className='se-content-subheader'></div>
                                <div className='se-content-row'>
                                    <label>Old password</label>
                                    <input type='password' value={this.state.oldPassword} onChange={(e) => this.handleChange(e, 'oldPassword')}/>
                                </div>
                                <div className='se-content-row'>
                                    <label>New password</label>
                                    <input type='password' value={this.state.newPassword} onChange={(e) => this.handleChange(e, 'newPassword')}/>
                                </div>
                                <div className='se-content-row'>
                                    <label>Confirm password</label>
                                    <input type='password' value={this.state.confirmPassword} onChange={(e) => this.handleChange(e, 'confirmPassword')}/>
                                </div>   
                                <div className='se-content-row'>
                                    {this.renderPasswordDisplay()}
                                </div>                          
                                <div className='se-btn-row'>
                                    {this.renderButton('button2', this.state.changes.button2)}
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                
            </body>
        </div>
        )   
    }
}

export default accountInfo;