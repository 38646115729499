import React, {Component} from 'react';

import profile from '../../images/profile.jpg';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newMessages: false,
            newEvents: false,
            changes: {
                newMessages: false,
                newEvents: false,
                button1: false,
            },
        }

        this.handleChange = this.handleChange.bind(this);
        this.pushChanges = this.pushChanges.bind(this);
        this.renderButton = this.renderButton.bind(this);
    }

    componentWillMount() {
        const p = this.props.data('users', this.props.firebase.auth().currentUser.uid);
        this.setState({newMessages: p['newMessages']});
        this.setState({newEvents: p['newEvents']});
    }

    handleChange(event, label) {
        const d = this.props.data('users', this.props.firebase.auth().currentUser.uid);
        var changes = this.state.changes;
        if (label === 'newMessages') {
            this.setState({newMessages: !this.state.newMessages});
            changes['newMessages'] = (d['newMessages'] === this.state.newMessages);
        } else if (label === 'newEvents') {
            this.setState({newEvents: !this.state.newEvents});
            changes['newEvents'] = (d['newEvents'] === this.state.newEvents);
        } else if (label === 'button1') {
            const contents = [
                'newMessages',
                'newEvents', 
            ];
            this.pushChanges(this.props.firebase.auth().currentUser.uid, contents);
        }
        changes['button1'] = (this.state.changes.newMessages || this.state.changes.newEvents);
        this.setState({changes: changes});
    }

    pushChanges(id, contentlist) {
        var incompletelist = this.resolveIncomplete(contentlist);
        if (!incompletelist) {
            //everything filled out
            for (var val in contentlist) {
                this.props.updateData('users', this.props.firebase.auth().currentUser.uid, contentlist[val], this.state[contentlist[val]]);
            }
        } else {
            alert('TODO: ui for incorrect fields');
        }
    }

    resolveIncomplete(contentlist) {
        return false;
    }

    renderButton(button, active) {
        if (button === 'button1') {
            if (active) {
                return (
                    <div className='btn-one' onClick={(e) => this.handleChange(e, 'button1')}>Update</div>
                )
            } else {
                return (
                    <div className='btn-inactive' onClick={(e) => this.handleChange(e, 'button1')} disabled >Update</div>
                )
            }
        } else if (button === 'button2') {
            if (active) {
                return (
                    <div className='btn-one' onClick={(e) => this.handleChange(e, 'button2')}>Update</div>
                )
            } else {
                return (
                    <div className='btn-inactive' onClick={(e) => this.handleChange(e, 'button2')} disabled >Update</div>
                )
            }
        }
    }

    
    render() {
        return(
        <div>
            <head>
                <meta charSet='UTF-8'/>
                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                <title>Profile</title>
            </head>
            <body>
                <section className='se-page'>
                    <section className='se-navigation-section'>
                        <Link to="/myprofile?id=profileview">
                            <div className='se-navigation-row'>My Profile</div>
                        </Link>
                        <Link to="/myprofile?id=accountinformation">
                            <div className='se-navigation-row'>Account Information</div>
                        </Link>
                        <Link to="/myprofile?id=payout">
                            <div className='se-navigation-row'>Payout</div>
                        </Link>
                        <Link to="/myprofile?id=notificationsettings">
                            <div className='se-navigation-row'>
                                <div className='active-nav'>Notification Settings</div>
                            </div>
                        </Link>
                    </section>
                    <section className='se-content-section'>
                        <div className='se-content-container'>
                            <div className='se-content-header'>Notifications</div>
                            <div className='se-content'>
                                <div className='se-content-subheader'>We're not really big on bothering people, so you won't hear from us unless it's important.</div>
                                <div className='se-notification-row'>
                                    <input type='checkbox' checked={this.state.newMessages} onChange={(e) => this.handleChange(e, 'newMessages')} />
                                    <label>New messages from organizers and guests, changes in reservation status, upcoming trip reminders</label>
                                </div>
                                <div className='se-notification-row'>
                                    <input type='checkbox' checked={this.state.newEvents} onChange={(e) => this.handleChange(e, 'newEvents')}/>
                                    <label>New events</label>
                                </div>
                                <div className='se-btn-row'>
                                    {this.renderButton('button1', this.state.changes.button1)}
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                
            </body>
        </div>
        )   
    }
}

export default notification;