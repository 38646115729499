import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import island from '../../images/island.svg';

class NoChats extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    render() {
        return (
            <section className='mt-blank-page'>
                <div className='msg-blank-header'>   
                    You don't have any active chats yet
                    <img src={island}/>
                </div>
                <div className='mt-blank-body'>
                    <Link to="/events?id=home"><div className='btn-one mt-blank-page-btn'>Browse Events</div></Link>
                </div>
            </section>
        )
    }

    /*
    <div style='margin: 0 auto; align-self: center; width: 365px; padding: 10px; border-bottom: 1px solid #c4c4c4;'>
        <div class='noti-title' style='align-self: center; margin: 0 auto; margin-top: 20px; font-size: 30px; color: #54596e; font-weight: 600; text-align: center;' >You have a new message</div>
        <div class='noti-subtitle' style='margin: 20px; align-self: center; text-align: center; color: #888888;'>Log in to see what someone wrote you!</div>
        <div style='display: flex; margin: 0 auto; justify-content: center'><a href='https://travelfam.io/messages' style='text-decoration: none; background-color: #e14658; color: #fff; font-size: 14px; padding: 10px 20px; text-align: center; font-weight: 600; margin: 10px auto; margin-top: 0px; border-radius: 7px; align-self: center;' >Login</a></div>
    </div>
    */
}

export default NoChats;