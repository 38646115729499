import React from 'react';
//import ReactEncrypt from 'react-encrypt'
import preloader from '../../images/preloader.gif';
import Loading from '../Loading';
import Landing from '../Landing';
import ProfileView from '../Profile';
import Profile from '../Profile';

import Review from '../Review';
import PaymentRoute from '../Payments'
import Account from '../Account';
import SingleListingView from '../SingleListingView';
import Trips from '../Trips';
import SignUp from '../SignUp';
import ListingCreate from '../ListingCreate';
import Login from '../Login';
import RetrievePw from '../Login/retrievePw.js';
import WaitlistConfirm from '../SignUp/WaitlistConfirm.js';
import MyTripsNew from '../Trips';
import DeleteConfirmation from '../SingleListingView/DeleteConfirmation.js';
import CancelConfirmation from '../SingleListingView/CancelConfirmation.js';
import Events from '../Events';
import MapModal from '../Map/MapModal.js';
import Map from '../Map';
import PropTypes from 'prop-types';
import Privacy from '../Landing/privacy.js';
import TOS from '../Landing/TOS.js';
import Messages from '../MessageView';
import PaymentDetails from '../SingleListingView/PaymentDetails';
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";
import '../../css/index.css'
import firebase from '../Firebase';
//import Key from '../Firebase/key.js';
import NewMyTripsDetails from '../SingleListingView/newMyTripsDetails'
var pendingRequests = {};
const monthLookup = {
  '1': 'Jan',
  '2': 'Feb',
  '3': 'Mar',
  '4': 'Apr',
  '5': 'May',
  '6': 'Jun',
  '7': 'Jul',
  '8': 'Aug',
  '9': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};
var signUpPending = false;
window.$backend = 'https://travelfamserver-265518.appspot.com/'
// window.$backend = 'http://localhost:8080'

class App extends React.Component {
  static contextTypes = {
    encrypt: PropTypes.func.isRequired,
    decrypt: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      loading: true,
      viewId: "",
      ownIdLink: "",
      listingDict: null,
      channelPrev: "channel?id=twitchcon_sandiego_2019",
      localData: {}, //TODO: EMPTY
      nothing: '',
      privateCollections: ['users'], //TODO: ADD LISTINGS
      privateCategory: ['chatIds', 'day', 'email', 'month', 'myTrips', 'year', 'submerchant', 'payoutRequested'],

      view: '',
    }
    this.renderView = this.renderView.bind(this);
    this.data = this.data.bind(this);
    this.readSubcollection = this.readSubcollection.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateDoc = this.updateDoc.bind(this);
    this.writeData = this.writeData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.request = this.request.bind(this);

    this.isUser = this.isUser.bind(this);
    this.formatDateRange = this.formatDateRange.bind(this);
    this.setSignUpPending = this.setSignUpPending.bind(this);

    this.emptyCache = this.emptyCache.bind(this); //TODO: find better solution >:(
    this.encrypt = this.encrypt.bind(this); //TODO: retake cs70
    this.decrypt = this.decrypt.bind(this);

    this.isMemory = this.isMemory.bind(this);

  }


  componentWillMount() {

    var a = this.encrypt('+16504489397');
    this.decrypt(a);
    this.setState({ nothing: 'a' });
  }

  componentDidMount() {
    //IF FIREBASE USER LOGGED IN
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user.displayName === 'done') {
          this.data('users', user.uid);
          this.setState({ ownIdLink: "profile?id=" + user.uid });
          this.setState({ currentUser: user });
          this.setState({ loading: false });
        } else {
          if (!signUpPending) {
            this.setState({ loading: false });
          }
        }
      } else {
        this.setState({ loading: false });
      }
      //this.setState({loading: false});
    });
  }

  encrypt(val) {

    //const e = encrypt(val);
    //console.log(e)
    //return e;
  }

  decrypt(val) {
    //const d = decrypt(val);
    //console.log(d);
    //return d;
  }

  setSignUpPending(val) {
    signUpPending = val;
  }

  //TODO: find better solution
  emptyCache(tree, id = false) {
    var localData = this.state.localData;
    if (id) {
      delete localData[tree][id];
    } else {
      delete localData[tree];
    }
    this.setState({ localData: localData });
  }

  data(tree, id) {

    if ((tree + id) in pendingRequests) {
      console.log('Request ' + tree + '/' + id + ' already in queue')
      return;
    }
    var current = this.state.localData;
    if (current[tree] === undefined) {
      current[tree] = {};
    }
    if (current[tree][id] === undefined) {
      //current[tree][id] = TODO: firebase pull
      pendingRequests[tree + id] = true;
      //console.log('datacall: ', tree, id);

      var read = firebase.firestore().collection(tree).doc(id)
      read.get().then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          delete pendingRequests[tree + id];
          current[tree][id] = doc.data();
          this.setState({ localData: current });
          return null;
        }
      }).catch(err => {
        console.log('Error getting document', err);
      });
      if (this.state.privateCollections.includes(tree)) {
        if ((tree + id + 'private') in pendingRequests) {
          return;
        }
        pendingRequests[tree + id + 'private'] = true;
        var readPrivate = firebase.firestore().collection(tree).doc(id).collection('private').doc('data')
        readPrivate.get().then(doc => {
          if (!doc.exists) {
            console.log('No such document!');
          } else {
            delete pendingRequests[tree + id + 'private'];
            current[tree][id] = { ...current[tree][id], ...doc.data() };
            this.setState({ localData: current });
            console.log(current[tree][id]);
            return null;
          }
        }).catch(err => {
          console.log('Error getting PRIVATE document ' + tree + ' ' + id, err);
        });
      }
    }
    return current[tree][id];
  }

  readSubcollection(tree, id, subCollection) {

    if ((tree + id + subCollection) in pendingRequests) {
      console.log('Request ' + tree + '/' + id + '/' + subCollection + ' already in queue')
      return;
    }
    var current = this.state.localData;
    if (current[tree] === undefined) {
      current[tree] = {};
    }
    if (current[tree][id] === undefined) {
      //current[tree][id] = TODO: firebase pull
      pendingRequests[tree + id + subCollection] = true;
      //console.log('datacall: ', tree, id);

      var read = firebase.firestore().collection(tree).doc(id).collection(subCollection)
      read.get().then(doc => {
        if (!doc.docs) {
          console.log('No such document!');
        } else {
          delete pendingRequests[tree + id + subCollection];
          current[tree][id] = doc.docs.map(d => d.data());
          this.setState({ localData: current });
          return null;
        }
      }).catch(err => {
        console.log('Error getting document', err);
      });
    }
    return current[tree][id];
  }

  // readCollection(tree) {

  //   if ((tree) in pendingRequests) {
  //     console.log('Request ' + tree + ' already in queue')
  //     return;
  //   }
  //   var current = this.state.localData;
  //   if (current[tree] === undefined) {
  //     current[tree] = {};
  //     //current[tree][id] = TODO: firebase pull
  //     pendingRequests[tree] = true;
  //     //console.log('datacall: ', tree, id);

  //     var read = firebase.firestore().collection(tree)
  //     read.get().then(doc => {
  //       if (!doc.docs) {
  //         console.log('No such document!');
  //       } else {
  //         delete pendingRequests[tree];
  //         current[tree] = doc.docs.map(d => d.data());
  //         this.setState({ localData: current });
  //         return null;
  //       }
  //     }).catch(err => {
  //       console.log('Error getting document', err);
  //     });
  //   }
  //   return current[tree];
  // }

  updateData(tree, id, category, value) {
    var current = this.state.localData;
    if (current[tree] === undefined) {
      current[tree] = {};
    }
    if (current[tree][id] === undefined) {
      current[tree][id] = {};
    }
    current[tree][id][category] = value;
    var priv = (this.state.privateCategory.includes(category) && this.state.privateCollections.includes(tree));
    if (priv) {
      var update = firebase.firestore().collection(tree).doc(id).collection('private').doc('data')
    } else {
      var update = firebase.firestore().collection(tree).doc(id)
    }
    return update.set({
      [category]: value
    }, { merge: true }).then(() => {
      this.setState({ localData: current });
    }).catch((error) => {
      console.log(error);
    });
  }

  updateDoc(tree, id, data) {
    var current = this.state.localData;
    if (current[tree] === undefined) {
      current[tree] = {};
    }
    if (current[tree][id] === undefined) {
      current[tree][id] = {};
    }
    current[tree][id] = data;
    var update = firebase.firestore().collection(tree).doc(id)
    return update.set(data, { merge: true }).then(() => {
      this.setState({ localData: current });
    }).catch((error) => {
      console.log(error);
    });
  }

  //writes data to localData and writes to firestore
  //tree = collection
  //id = document id
  //category = key
  //value = value
  writeData(tree, data, id = false, priv = false) {
    //write data to firestore first because we need to get the generated id
    if (!id) {
      var write = firebase.firestore().collection(tree).doc()
      return write.set(data).then(() => {
        //write data to localData
        var id = write.id
        var current = this.state.localData
        if (current[tree] === undefined) {
          current[tree] = {}
        }
        if (current[tree][id] === undefined) {
          current[tree][id] = {}
        }
        for (var key in data) {
          current[tree][id][key] = data[key]
        }

        this.setState({ localData: current })

      }).catch((error) => {
        console.log("error", error)
      })
    } else {
      if (priv) {
        var write = firebase.firestore().collection(tree).doc(id).collection('private').doc('data')
      } else {
        var write = firebase.firestore().collection(tree).doc(id)
      }
      return write.set(data).then(() => {
        //write data to localData
        var id = write.id
        var current = this.state.localData
        if (current[tree] === undefined) {
          current[tree] = {}
        }
        if (current[tree][id] === undefined) {
          current[tree][id] = {}
        }
        for (var key in data) {
          current[tree][id][key] = data[key]
        }

        this.setState({ localData: current })

      }).catch((error) => {
        console.log("error", error)
      })
    }

  }

  deleteData(tree, id, priv = false) {
    if (priv) {
      var write = firebase.firestore().collection(tree).doc(id).collection('private').doc('data')
    } else {
      var write = firebase.firestore().collection(tree).doc(id)
    }
    write.delete().then(() => {
      //delete data to localData
      var current = this.state.localData[tree];
      delete current[id];
      this.setState({ localData: current })
      // console.log('data deleted, ', id);
    }).catch((error) => {
      console.log("error", error)
    })
  }

  async request(type, data = null) {
    console.log('in Request: ' + type)
    var returnBool = false
      let authToken = null
    
    try{
      authToken = await firebase.auth().currentUser.getIdToken(true)
    } catch (err) {
      console.log(err)
    }

    let message = await fetch(window.$backend, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ func: 'processRequest', type: type, data: data, auth: authToken })
    });
    console.log(message)
    let response = await message.json()
    console.log(response)
    return response
  }

  isMemory(date) {
    const d = new Date();
    const dateArray = date.split("/");
    const year = dateArray[2];
    const month = dateArray[0];
    const day = dateArray[1];
    if (year == d.getFullYear()) {
      if (month == (d.getMonth() + 1)) {
        return day < d.getDate();
      } else if (month < (d.getMonth() + 1)) {
        return true;
      } else if (month > (d.getMonth() + 1)) {
        return false;
      }
    } else if (year < d.getFullYear()) {
      return true;
    } else if (year > d.getFullYear()) {
      return false;
    }
  }

  formatDateRange(from, to, parse = false) {
    const fromArray = from.split("/");
    const toArray = to.split("/");
    const fromString = monthLookup[fromArray[0]] + " " + fromArray[1];
    const toString = monthLookup[toArray[0]] + " " + toArray[1];
    if (!parse) {
      const date = fromString + " - " + toString + ", " + toArray[2];
      return date;
    } else if (parse === 'from') {
      const date = fromString + ", " + fromArray[2];
      return date;
    } else if (parse === 'to') {
      const date = toString + ", " + toArray[2];
      return date;
    }
  }

  isUser() {
    return firebase.auth().currentUser && (firebase.auth().currentUser.displayName === 'done');
  }

  renderView(name, pathInput = " ", pathInput2 = " ") {
    if (this.isUser()) {
      const pages = ['Login', 'SignUp'];
      if (pages.includes(name)) {
        return (
          <Redirect to="/events?id=home"></Redirect>
        )
      }
    } else {
      const pages = ['ListingCreate'];
      if (pages.includes(name)) {
        return (
          <Redirect to="/login"></Redirect>
        )
      }
    }

    if (name === "Landing") {
      return (
        <Landing
          firebase={firebase}
          data={this.data}
          updateData={this.updateData}
          emptyCache={this.emptyCache}
          request={this.request}
        ></Landing>
      )
    } else if (name === "MyTrips") {
      return (
        <Trips
          firebase={firebase}
          request={this.request}
          currentUser={this.state.currentUser}
          listingDict={this.state.listingDict}
          data={this.data}
          updateData={this.updateData}
          emptyCache={this.emptyCache}
        ></Trips>
      )
    } else if (name === "myTripsDetails") {
      return (
        <NewMyTripsDetails
          firebase={firebase}
          request={this.request}
          tripId={pathInput}
          organizerId={pathInput2}
          data={this.data}
          readSubcollection={this.readSubcollection}
          updateData={this.updateData}
          formatDateRange={this.formatDateRange}
          deleteData={this.deleteData}
          writeData={this.writeData}
          emptyCache={this.emptyCache}
        ></NewMyTripsDetails>
      )
    } else if (name === "SingleListingView") {
      const pathInputId = pathInput.location.search.split("=")[1];
      return (
        <SingleListingView
          firebase={firebase}
          request={this.request}
          data={this.data}
          updateData={this.updateData}
          viewId={pathInputId}
          deleteData={this.deleteData}
          emptyCache={this.emptyCache}
        ></SingleListingView>
      )
    } else if (name === "DeleteConfirmation") {
      return (
        <DeleteConfirmation
          firebase={firebase}
          request={this.request}
          currentUser={this.state.currentUser}
          data={this.data}
          updateData={this.updateData}
          emptyCache={this.emptyCache}
        ></DeleteConfirmation>
      )
    } else if (name === "MapModal") {
      return (
        <MapModal
          firebase={firebase}
          currentUser={this.state.currentUser}
          data={this.data}
          updateData={this.updateData}
          emptyCache={this.emptyCache}
        ></MapModal>
      )
    } else if (name === "WaitlistConfirm") {
      return (
        <WaitlistConfirm
          firebase={firebase}
          request={this.request}
          currentUser={this.state.currentUser}
          data={this.data}
          updateData={this.updateData}
          emptyCache={this.emptyCache}
        ></WaitlistConfirm>
      )
    } else if (name === "CancelConfirmation") {
      return (
        <CancelConfirmation
          firebase={firebase}
          request={this.request}
          currentUser={this.state.currentUser}
          data={this.data}
          updateData={this.updateData}
          emptyCache={this.emptyCache}
        ></CancelConfirmation>
      )
    } else if (name === "ProfileView") {
      const pathInputId = pathInput.location.search.split("=")[1];
      return (
        <ProfileView
          firebase={firebase}
          request={this.request}
          currentUser={this.state.currentUser}
          viewId={pathInputId}
          data={this.data}
          updateData={this.updateData}
          emptyCache={this.emptyCache}
        ></ProfileView>
      )
    } else if (name === "ListingCreate") {
      const pathInputId = pathInput.location.search.split("=")[1];
      return (
        <ListingCreate
          localData={this.state.localData}
          request={this.request}
          firebase={firebase}
          data={this.data}
          updateData={this.updateData}
          updateDoc={this.updateDoc}
          writeData={this.writeData}
          view={pathInputId}
          getFireId={this.getFireId}
          emptyCache={this.emptyCache}
          formatDateRange={this.formatDateRange}
        ></ListingCreate>
      )
    } else if (name === "ListingEdit") {
      const tripId = pathInput.location.search.split("=")[1].split("&")[0];
      const view = pathInput.location.search.split("=")[1].split("&")[1];

      return (
        <ListingCreate
          localData={this.state.localData}
          request={this.request}
          firebase={firebase}
          data={this.data}
          updateData={this.updateData}
          updateDoc={this.updateDoc}
          writeData={this.writeData}
          tripId={tripId}
          view={view}
          getFireId={this.getFireId}
          emptyCache={this.emptyCache}
          formatDateRange={this.formatDateRange}
          listingEdit={true}
        ></ListingCreate>
      )
    } else if (name === "Events") {
      const pathInputId = pathInput.location.search.split("=")[1];
      return (
        <Events
          firebase={firebase}
          request={this.request}
          currentUser={this.state.currentUser}
          data={this.data}
          id={pathInputId}
          updateData={this.updateData}
          formatDateRange={this.formatDateRange}
          emptyCache={this.emptyCache}
          isMemory={this.isMemory}
        ></Events>
      )
    } else if (name === "myTripsNew") {
      //Handle Stripe Connect Response
      let stripeCode
      let paramName
      if (pathInput !== " " && pathInput.split("?") && pathInput) {
        paramName = pathInput.split("?")[1].split("=")[0]
        stripeCode = pathInput.split("?")[1].split("=")[1]
        console.log(stripeCode)
      }
      return (
        <MyTripsNew
          firebase={firebase}
          stripeCode={stripeCode}
          paramName={paramName}
          request={this.request}
          currentUser={this.state.currentUser}
          data={this.data}
          updateData={this.updateData}
          writeData={this.writeData}
          listingDict={this.state.listingDict}
          formatDateRange={this.formatDateRange}
          emptyCache={this.emptyCache}
          isMemory={this.isMemory}
        ></MyTripsNew>
      )
    } else if (name === "privacy") {
      return (
        <Privacy
          firebase={firebase}
          data={this.data}
          emptyCache={this.emptyCache}
        ></Privacy>
      )
    } else if (name === "TOS") {
      return (
        <TOS
          firebase={firebase}
          data={this.data}
          emptyCache={this.emptyCache}
        ></TOS>
      )
    } else if (name === "Login") {
      return (
        <Login
          firebase={firebase}
          data={this.data}
          updateData={this.updateData}
          writeData={this.writeData}
          emptyCache={this.emptyCache}
          encrypt={this.encrypt}
          decrypt={this.decrypt}
        ></Login>
      )
    } else if (name === "retrievePw") {
      return (
        <RetrievePw
          firebase={firebase}
          currentUser={this.state.currentUser}
          data={this.data}
          updateData={this.updateData}
          writeData={this.writeData}
          emptyCache={this.emptyCache}
        ></RetrievePw>
      )
    } else if (name === "SignUp") {
      const pathInputId = pathInput.location.search.split("=")[1];
      return (
        <SignUp
          firebase={firebase}
          data={this.data}
          updateData={this.updateData}
          writeData={this.writeData}
          view={pathInputId}
          setSignUpPending={this.setSignUpPending}
          emptyCache={this.emptyCache}
          encrypt={this.encrypt}
          decrypt={this.decrypt}
          request={this.request}
        ></SignUp>
      )
    } else if (name === "MyProfile") {
      const pathInputId = pathInput.location.search.split("=")[1];
      return (
        <Profile
          firebase={firebase}
          readSubcollection={this.readSubcollection}
          request={this.request}
          data={this.data}
          updateData={this.updateData}
          writeData={this.writeData}
          view={pathInputId}
          emptyCache={this.emptyCache}
        ></Profile>
      )
    } else if (name === "messages") {
      const pathInputId = pathInput.location.search.split("=")[1];
      console.log(pathInputId);
      return (
        <Messages
          firebase={firebase}
          request={this.request}
          currentUser={this.state.currentUser}
          data={this.data}
          updateData={this.updateData}
          emptyCache={this.emptyCache}
          viewId={pathInputId}
        ></Messages>
      )
    } else if (name === "PaymentDetails") {
      const pathInputId = pathInput.location.search.split("=")[1];
      console.log(pathInputId);
      return (
        <PaymentDetails
          firebase={firebase}
          currentUser={this.state.currentUser}
          data={this.data}
          updateData={this.updateData}
          emptyCache={this.emptyCache}
          viewId={pathInputId}
        ></PaymentDetails>
      )
    } else if (name === "Payment") {
      var viewId = ''
      if (pathInput2 === 'confirmation') {
        viewId = '2'
      } else if (pathInput2.includes('topup')) {
        viewId = '3'
      } else { viewId = '1' }
      return (
        <PaymentRoute
          firebase={firebase}
          request={this.request}
          currentUser={this.state.currentUser}
          formatDateRange={this.formatDateRange}
          tripId={pathInput}
          requestedSpots={pathInput2}
          data={this.data}
          updateData={this.updateData}
          writeData={this.writeData}
          emptyCache={this.emptyCache}
          viewId={viewId}
        ></PaymentRoute>
      )
    } else if (name === "Map") {
      return (
        <Map
          firebase={firebase}
          request={this.request}
          data={this.data}
          memory={this.isMemory}
        ></Map>
      )
    } else if (name === "NotFound") {
      return (
        <Loading notFound={true}></Loading>
      )
    } else if (name === "Account") {
      const pathInputId = pathInput.location.search.split("=")[1];
      return (
        <Account
          firebase={firebase}
          request={this.request}
          data={this.data}
          updateData={this.updateData}
          writeData={this.writeData}
          viewId={pathInputId}
          emptyCache={this.emptyCache}
        ></Account>
      )
    } else if (name === "Review") {
      const pathInputId = pathInput.location.search.split("=")[1];
      return (
        <Review
          firebase={firebase}
          request={this.request}
          data={this.data}
          updateData={this.updateData}
          writeData={this.writeData}
          viewId={pathInputId}
          emptyCache={this.emptyCache}
          formatDateRange={this.formatDateRange}
        ></Review>
      )
    }
  }


  render() {
    if (this.state.loading) {
      return (
        <div className='preloader'>
          <img src={preloader} />
        </div>
      )
    } else {
      return (
        <div>
          <Router>
            <Switch>
              <Route exact path="/" component={() => this.renderView("Landing")} />
              <Route path="/my-trips" component={() => (
                firebase.auth().currentUser ? (
                  this.renderView("MyTrips")
                ) : (
                    <Redirect to="/login" />
                  )
              )} />
              <Route path="/login" component={() => this.renderView("Login")} />
              <Route
                path="/singlelistingview:tripId"
                render={(props) => <SingleListingView {...props} firebase={firebase} currentUser={this.state.currentUser}></SingleListingView>}
              />
              <Route path="/listing" component={(id) => (
                (id.location.search === "?id=default") ? (
                  <Redirect to='TODO' />
                ) : (
                    this.renderView("SingleListingView", id)
                  )
              )} />

              <Route
                path="/mytripsdetails/:tripId/:organizerId"
                // render={(props) => <MyTripsDetails {...props} firebase = {firebase} listingDict = {this.state.listingDict} currentUser = {this.state.currentUser} ></MyTripsDetails>}
                component={(props) => this.renderView("myTripsDetails", props.match.params.tripId, props.match.params.organizerId)}
              />
              <Route path="/Payment/:tripId/:requestedSpots" component={(props) => this.renderView("Payment", props.match.params.tripId, props.match.params.requestedSpots)} />
              <Route path="/MessageView" component={() => this.renderView("MessageView")} />
              <Route path="/myprofile" component={(id) => this.renderView("MyProfile", id)} />
              <Route path="/profile" component={(id) => (
                (id.location.search === "?id=own") ? (
                  <Redirect to={this.state.ownIdLink} />
                ) : (
                    this.renderView("Profile", id)
                  )
              )} />
              <Route path="/events" component={(id) => this.renderView("Events", id)} />

              <Route path="/logout" component={() => this.renderView("Logout")} />

              <Route path="/myTripsNew" component={(props) => this.renderView("myTripsNew", props.location.search)} />

              <Route path="/signup" component={(id) => this.renderView("SignUp", id)} />
              <Route path="/retrievePw" component={() => this.renderView("retrievePw")} />

              <Route path="/privacy" component={() => this.renderView("privacy")} />
              <Route path="/TOS" component={() => this.renderView("TOS")} />

              <Route path="/messages" component={(id) => this.renderView("messages", id)} />

              <Route path="/PaymentDetails" component={(id) => this.renderView("PaymentDetails", id)} />

              <Route path="/listingcreate" component={(id) => this.renderView("ListingCreate", id)} />

              <Route path="/listingedit" component={(id) => this.renderView("ListingEdit", id)} />

              <Route path="/review" component={(id) => this.renderView("Review", id)} />

              <Route path="/navbar" component={() => this.renderView("NavBar")} />

              <Route path="/map" component={() => this.renderView("Map")} />
              <Route path="/signup3" component={() => this.renderView("Signup3")} />


              <Route path="/DeleteConfirmation" component={() => this.renderView("DeleteConfirmation")} />
              <Route path="/CancelConfirmation" component={() => this.renderView("CancelConfirmation")} />


              <Route path="/MapModal" component={() => this.renderView("MapModal")} />

              <Route path="/WaitlistConfirm" component={() => this.renderView("WaitlistConfirm")} />

              <Route path="/Error" component={() => this.renderView("Error")} />

              <Route path="/account" component={(id) => this.renderView("Account", id)} />

              <Route component={() => this.renderView("NotFound")} />
            </Switch>
          </Router>
        </div>
      )
    }
  }

}

export default App;