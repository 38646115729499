import * as firebase from 'firebase';

let config = {
    apiKey: "AIzaSyC8iOqFFz_FUnxFUSi3wJCAC2VBUvLgRjA",
    authDomain: "travelfamplus.firebaseapp.com",
    databaseURL: "https://travelfamplus.firebaseio.com",
    projectId: "travelfamplus",
    storageBucket: "travelfamplus.appspot.com",
    messagingSenderId: "396811803364",
    appId: "1:396811803364:web:4057f34b65b56853"
  };
firebase.initializeApp(config);
export default firebase;