import React, {Component} from 'react';
import logo from '../../images/logo.svg';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class signup2b extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            referral: '',
            incomplete: {
                firstName: false,
                lastName: false,
                email: false,
                referral: false,
            },
            display: 'none',
            nextStepDirection: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.pushForm = this.pushForm.bind(this);
        this.validInputs = this.validInputs.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
    }

    handleChange(event, label) {
        if (label === 'firstName') {
            this.setState({firstName: event.target.value});
        } else if (label === 'lastName') {
            this.setState({lastName: event.target.value});
        } else if (label === 'email') {
            this.setState({email: event.target.value});
        } else if (label === 'referral') {
            this.setState({referral: event.target.value});
        }
    }

    pushForm() {
        if (this.validInputs()) {
            this.props.request('notification', {
                category: 'waitlistAdd',
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.props.phone,
                referral: this.state.referral,
                timestamp: Math.floor(Date.now() / 1000),
                event: this.state.event,
            }).then(() => {
                this.setState({nextStepDirection: '/signup?id=2c'});
            }).catch((err) => {
                console.log(err);
            })
        } else {
            this.setState({display: 'incomplete'});
            //alert("TODO: ui for incomplete fields");
        }
    }

    validInputs() {
        var incompleteDict = this.state.incomplete;
        var anyIncomplete = false;
        if (this.state.firstName.length === 0) {
            incompleteDict['firstName'] = true;
            anyIncomplete = true;
        }
        if (this.state.lastName.length === 0) {
            incompleteDict['lastName'] = true;
            anyIncomplete = true;
        }
        if (this.state.email.length === 0) {
            incompleteDict['email'] = true;
            anyIncomplete = true;
        }
        if (this.state.referral.length === 0) {
            incompleteDict['referral'] = true;
            anyIncomplete = true;
        }
        this.setState({incomplete: incompleteDict});
        return !anyIncomplete;
    }

    renderMessage() {
        if (this.state.display === "none") {
            return (
                <div className='incorrect-field'>&#160;</div>
            )
        } else if (this.state.display === "done") {
            return (
                <div className='incorrect-field'>Message Sent</div>
            )
        } else if (this.state.display === "incomplete") {
            return (
                <div className='incorrect-field'>Please fill out all fields</div>
            )
        }
    }

    render() {
        if (this.props.phone.length === 0) {
            return (
                <Redirect to="/signup?id=1"></Redirect>
            )
        } else if (this.state.nextStepDirection === '/signup?id=2c') {
            return (
                <Redirect to="/signup?id=2c"></Redirect>
            )
        } else {
            return(
                <div>
                    <head>
                        <meta charSet='UTF-8'/>
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                        <title>signup2</title>
                    </head>
                    <body>
                        <section className='log-page'>
                            <section className='log-content'>
                                <div className='log-logo'><img src={logo}/></div>
                                <div className='log-header-regular'><strong>{formatPhoneNumber(this.props.phone)}</strong><br/>doesn't have an account yet</div>
                                <div className='log-subheader-text' style={{textAlign:'center', marginTop:'0px'}}>We are growing the TravelFam community slowly at first. You can join the waitlist and we'll let you know when an account is ready for you.</div>
                                <div className='log-input-container'>
                                    <input type='text' placeholder='First Name' onChange={(e) => this.handleChange(e, 'firstName')} required={this.state.incomplete.firstName}/>
                                </div>
                                <div className='log-input-container'>
                                    <input type='text' placeholder='Last Name' onChange={(e) => this.handleChange(e, 'lastName')} required={this.state.incomplete.lastName}/>
                                </div>
                                <div className='log-input-container'>
                                    <input type='text' placeholder='Enter your email' onChange={(e) => this.handleChange(e, 'email')} required={this.state.incomplete.email}/>
                                </div>
                                <div className='log-input-container'>
                                    <input type='text' placeholder='Who referred you to TravelFam?' onChange={(e) => this.handleChange(e, 'referral')} required={this.state.incomplete.referral}/>
                                </div>
                                <div className='log-footer'>
                                    <div className='btn-one' onClick={() => this.pushForm()}>Join Waitlist</div>
                                </div>
                                {this.renderMessage()}
                                <div className='log-footer-area'>
                                    <div className='log-footer'>Already have an account? <Link to="/login"><span className='link'>Login</span></Link></div>
                                </div>
                            </section>
                        </section>
                    </body>
                </div>
            ) 
        }  
    }
}


export default signup2b;