import React, {Component} from 'react';

import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";
import Loading from '../Loading';

class OtherAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vouched: false,
            redirect: '',
        }
        this.resolveVouche = this.resolveVouche.bind(this);
        this.resolveClick = this.resolveClick.bind(this);
    }

    componentWillMount() {
        if (true) {
            const voucheArray = this.props.data('users', this.props.viewId);
            var selfId = ''
            if (this.props.firebase.auth().currentUser){
                selfId = this.props.firebase.auth().currentUser.uid;
            } else {
                selfId = 'NOT LOGGED IN'
            }
            var vouched = false;
            for (var i in voucheArray['vouche']) {
                if (voucheArray['vouche'][i] === selfId) {
                    vouched = true;
                    break;
                }
            }
            this.setState({vouched: vouched});
        }
    }

    resolveVouche(vouched) {
        if (vouched) {
            //unvouche
            var voucheList = this.props.data('users', this.props.viewId)['vouche'];
            for (var i = 0; i < voucheList.length; i += 1) {
                if (voucheList[i] === this.props.firebase.auth().currentUser.uid) {
                    voucheList.splice(i, 1);
                    break;
                }
            }
            this.props.updateData('users', this.props.viewId, 'vouche', voucheList);
        } else {
            //vouche
            var voucheList = this.props.data('users', this.props.viewId)['vouche'];
            voucheList.push(this.props.firebase.auth().currentUser.uid);
            this.props.updateData('users', this.props.viewId, 'vouche', voucheList);
        }
        this.setState({vouched: !this.state.vouched});
    }

    resolveClick(type) {
        if (type === "Message") {
            //check if chat already exists
            if(!this.props.firebase.auth().currentUser){
                this.setState({
                    redirect: '/login'
                })
                return
            }
            const d = this.props.data('users', this.props.firebase.auth().currentUser.uid)['chatIds'];
            var redirectComp = ''; //not found yet
            for (var i in d) {
                if (d[i].includes(this.props.viewId)) {
                    redirectComp = d[i];
                }
            }
            if (redirectComp === '') { //create new
                const newRoom = this.props.firebase.auth().currentUser.uid + '%3Cp%3E' + this.props.viewId;
                redirectComp = newRoom;
                const newHeader = {
                    count: 0,
                    id: this.props.viewId,
                    lastMessage: '',
                    timestamp: "2019:10:19 03:37:12",//TODO: some default
                }
                const newMembers = {
                    1: this.props.firebase.auth().currentUser.uid,
                    2: this.props.viewId,
                }
                const newMessages = {

                }
                var updates = {};
                updates['/headers/' + newRoom] = newHeader;
                updates['/members/' + newRoom] = newMembers;
                updates['/messages/' + newRoom] = newMessages;
                this.props.firebase.database().ref().update(updates); 
                //add to keys
                var self = d;
                self.push(newRoom);
                this.props.updateData('users', this.props.firebase.auth().currentUser.uid, 'chatIds', self);
                const data = {
                    action: 'addChatId',
                    userId: this.props.viewId,
                    roomId: newRoom,
                }
                this.props.request('update', data);
            }
            const redirect = '/messages?id=' + redirectComp;
            this.setState({redirect: redirect});
        }
    }
    
    render() {
        if (this.state.redirect !== '') {
            return (
                <Redirect push to={this.state.redirect}/>
            )
        } else if (this.props.buttonOnly) {
            return (
                <div className='btn-two' onClick={()=>this.resolveClick("Message")}>Message</div>
            )
        }
        else if (this.state.vouched) {
            return(
                <div className='profile-btn-container'>
                    <div className='btn-one btn-spacing' onClick={() => this.resolveVouche(true)}>Vouched</div>
                    <div className='btn-two btn-spacing' onClick={()=>this.resolveClick("Message")}>Message</div>
                </div>
            )  
        } else {
            return(
                <div className='profile-btn-container'>
                    <div className='btn-five btn-spacing' onClick={() => this.resolveVouche(false)}>Vouch</div>
                    <div className='btn-two btn-spacing' onClick={()=>this.resolveClick("Message")}>Message</div>
                </div>
            )  
        }
         
    }
}

export default OtherAccount;