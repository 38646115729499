import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, MarkerImage, InfoWindow } from 'google-maps-react'
import Autocomplete from './autoComplete'
import example from '../../images/example.jpg';
import mapPinEvent from '../../images/map-pin-event.svg'
import mapPinNotViewed from '../../images/map-pin-not-viewed.svg'
import mapPinSelected from '../../images/map-pin-selected.svg'
import mapPinViewed from '../../images/map-pin-viewed.svg'
import MapModal from './MapModal'
import Geocode from 'react-geocode'
import { updateExpression } from '@babel/types';

Geocode.setApiKey("AIzaSyDhOR2M4tCUcUZLFGZt9_Wyxc9VekwolPw")
const style = [
    {
      "featureType": "administrative.locality",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
   ]



  
export class MapContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            latitude: null,
            longitude: null,
            defaultLat: null,
            defaultLng: null,
            activeMarker: null,

            currChannel: null,
            eventMarkerOpen: false,
            CLeventMarkerOpen: false,
            CL3eventMarkerOpen: false,

            clEventLat: null,
            clEventLng: null,

            dragAddress: null,
            ongoingAddress: null,
        }
        

        this.coordCallBack = this.coordCallBack.bind(this)
        this.populateMarkers = this.populateMarkers.bind(this)
        this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this)
        this.findBounds = this.findBounds.bind(this)

    }

 

    findBounds(listingCoords, eventLat, eventLng) {
        console.log("findbounds", listingCoords)
        var bounds = new this.props.google.maps.LatLngBounds()    
        bounds.extend({lat: parseFloat(eventLat), lng: parseFloat(eventLng)})
        // console.log(parseInt(eventLat), listingCoords[0].lat)
        for (var i = 0; i < listingCoords.length; i++) {
            console.log("!")
            bounds.extend({lat: listingCoords[i].lat, lng:listingCoords[i].lng})
            console.log(bounds)
        }
        // for (var i = 0; i < )
        // console.log("this is lianlanflk:", this.props.listingCoords)

        return bounds
    }

    populateMarkers() {
        console.log("populatemarkers")
        var returnMarkers = []


        // var listingCoordsArray = this.state.listingCoords
        // for (var i = 0; i < listingCoordsArray.length; i++) {
        //     console.log(listingCoordsArray[i].lat, listingCoordsArray[i].lng)
        //     // bounds.extend({lat: listingCoordsArray[i].lat, lng: listingCoordsArray[i].lng})
        //     if (listingCoordsArray[i].listingId !== this.state.activeMarker) {
        //         returnMarkers.push(
        //             <Marker
        //                 id={listingCoordsArray[i].listingId}
        //                 position = {{lat: listingCoordsArray[i].lat, lng: listingCoordsArray[i].lng}}
        //                 icon={mapPinNotViewed}
        //                 onClick={() => {
        //                     this.props.scrollToListing(listingCoordsArray[i].listingId)
        //                     this.setState({activeMarker: listingCoordsArray[i].listingId})
        //                 }}
        //             />
        //         )
        //     } else {
        //         returnMarkers.push(
        //             <Marker
        //                 id={listingCoordsArray[i].listingId}
        //                 position={{lat: listingCoordsArray[i].lat, lng: listingCoordsArray[i].lng}}
        //                 icon={mapPinSelected}
        //                 onClick={() => {
        //                     this.props.scrollToListing(listingCoordsArray[i].listingId)
        //                     this.setState({activeMarker: listingCoordsArray[i].listingId})
        //                 }}
        //             />
        //         )
        //     }
        // }

        this.state.listingCoords.map(function(data) {
            console.log("Coordinates:", data.lat, data.lng)
            if (data.listingId !== this.state.activeMarker) {
                returnMarkers.push(<Marker
                    id={data.listingId}
                    position={{lat: data.lat, lng: data.lng}}
                    icon={mapPinNotViewed}
                    onClick={() => {
                        this.props.scrollToListing(data.listingId)
                        this.setState({activeMarker: data.listingId})
                    }}
                />)
            } else {
                returnMarkers.push(<Marker
                    id={data.listingId}
                    position={{lat: data.lat, lng: data.lng}}
                    icon={mapPinSelected}
                    onClick={() => {
                        this.props.scrollToListing(data.listingId)
                        this.setState({activeMarker: data.listingId})
                    }}
                />)
            }

        }.bind(this))
        // this.setState({mapBounds: bounds})
        return returnMarkers
            
      
    }
    


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.hoveredId !== this.props.hoveredId) {
            this.setState({activeMarker: this.props.hoveredId})

        }
    }

    componentDidMount() {

        this.setState({
            defaultLat: this.props.page3Lat,
            defaultLng: this.props.page3Lng,
            


        })
        if (this.props.ongoingAddress) {
            this.setState({
                ongoingAddress: this.props.ongoingAddress
            })
        }
        if (this.props.listingCoords) {
            this.setState({listingCoords: this.props.listingCoords})

        }
        //0.003621379468
        if (this.props.currChannel) {
            if (this.props.currChannel === "coachella_indio_2020") {
                this.setState({
                    clEventLat: 33.680407,
                    clEventLng: -116.237027,
                    latitude: 33.680407,
                    longitude: -116.2334056205,
                })
            } else if (this.props.currChannel === "twitchcon_sandiego_2019") {
                this.setState({
                    clEventLat: 32.706966,
                    clEventLng: -117.162871,
                    latitude: 32.706966,
                    longitude: -117.1592496205,
                })
            } else if (this.props.currChannel === "matt_ann_wedding") {
                this.setState({
                    clEventLat: 38.284846,
                    clEventLng: -122.700102,
                    latitude: 38.284846,
                    longitude: -122.69648062053221,
                })
            }
            
        }
            
        
        
        // Change eventcoord into a list, to allow the addition of multiple event coordinates (ski and snowboard)
        if (this.props.eventCoord) {
            var coordList = this.props.eventCoord.split(",")
            var coordPairList = []
            

            var iter = 0
            while (iter < coordList.length) {
                var coordPair = [coordList[iter], coordList[iter + 1]]
                coordPairList.push(coordPair)
                iter += 2
            }
            console.log("this is the coordList:", coordPairList)
            this.setState({
                coordPairList: coordPairList
            })
        

                // var eventLat = this.props.eventCoord.split(",")[0]
                // var eventLng = this.props.eventCoord.split(",")[1]
                // this.setState({
                //     eventLat: eventLat,
                //     eventLng: eventLng
                // })
            
        }
    }

    coordCallBack(lat, lng) {

       
        this.setState({
            latitude: lat, 
            longitude: lng,
        })
        if (this.state.ongoingAddress) {
            this.state.ongoingAddress[1] = lat
            this.state.ongoingAddress[2] = lng
            this.forceUpdate()
        }
    }

    onMarkerDragEnd(coord) {
        const { latLng } = coord
        const lat = latLng.lat()
        const lng = latLng.lng()
        
        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address
                this.setState({dragAddress: address})
                this.setState({
                    latitude: lat,
                    longitude: lng,
                    ongoingAddress: [address, lat, lng]
                })
                this.props.dragAddressCallBack(address, lat, lng)
                
            },
            error => {
                console.error("Geocode error: ", error)
            }
        )
        
    }

    render() {
        if (this.props.addressSearch) {
            return (
                <div>
                    <Autocomplete
                        id="addressAutoComplete"
                        onPlaceLoaded = {this.props.onPlaceLoaded}
                        coordCallBack = {this.coordCallBack}
                        dragAddress = {this.state.dragAddress}
                        ongoingAddress = {this.props.ongoingAddress}
                    ></Autocomplete> 
                    <Map 
                        google={this.props.google}
                        zoom={14}
                        styles={style}  
                        
                        // center={{lat: this.state.clEventLat, lng: this.state.clEventLng}} **AB: Removed to fix auto center
                        center={this.state.latitude && this.state.longitude ? {lat: this.state.latitude, lng: this.state.longitude} : {lat: this.props.ongoingAddress[1], lng: this.props.ongoingAddress[2]}}

                        id="map"
                        mapTypeControl={false}
                    >

                        {(this.state.clEventLat && this.state.clEventLng &&
                            
                            <Marker 
                                onClick={() => {
                                    this.setState({CLeventMarkerOpen: !this.state.CLeventMarkerOpen})
                                }}
                                icon={mapPinEvent} 
                                position={{lat: this.state.clEventLat, lng: this.state.clEventLng}} 
                            />
                        )}
                        
                        <InfoWindow
                            visible={this.state.CLeventMarkerOpen}
                            onClose={() => {
                                this.setState({CLeventMarkerOpen: !this.state.CLeventMarkerOpen})
                            }}
                            position={{lat: this.state.clEventLat, lng: this.state.clEventLng}} 
                        >
                            <MapModal></MapModal>
                            
                        </InfoWindow>
                        
                        
                        {
                            (this.state.ongoingAddress) ? (
                                <Marker 
                                    draggable={true} 
                                    onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)} 
                                    icon={mapPinSelected} 
                                    position={{lat: this.state.ongoingAddress[1], lng: this.state.ongoingAddress[2]}} 
                                />
                            ) :
                            (this.state.latitude && this.state.longitude) ? 
                            (<Marker 
                                draggable={true} 
                                onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)} 
                                icon={mapPinSelected} 
                                position={{lat: this.state.latitude, lng: this.state.longitude}} 
                            />) :
                             
                            ""
                        }

                    </Map>
                </div>
            )
        } else if (!this.props.addressSearch && !this.props.channelView) {
            return (
            <Map
                google={this.props.google}
                zoom={14}
                styles={style}  
                
                center={{lat: this.props.page3Lat, lng: this.props.page3Lng}}
                id="map"
                mapTypeControl={false}
            >
                <Marker 
                    onClick={() => {
                        this.setState({CL3eventMarkerOpen: !this.state.CL3eventMarkerOpen})
                    }}
                    icon={mapPinEvent} 
                    position={{lat: this.state.clEventLat, lng: this.state.clEventLng}} 
                />

                 <InfoWindow
                    visible={this.state.CL3eventMarkerOpen}
                    onClose={() => {
                        this.setState({CL3eventMarkerOpen: !this.state.CL3eventMarkerOpen})
                    }}
                    position={{lat: this.state.clEventLat, lng: this.state.clEventLng}} 
                >
                    <MapModal></MapModal>
                    
                </InfoWindow>

                <Marker icon={mapPinSelected} position={{lat: this.state.defaultLat, lng: this.state.defaultLng}} />
            </Map>
            )
        } else if (this.props.channelView && this.state.listingCoords) {

            // var bounds = new this.props.google.maps.LatLngBounds();
            // for (var i = 0; i < this.state.listingCoords.length; i++) {
            //     console.log(this.state.listingCoords)
            //     bounds.extend({lat: this.state.listingCoords[i].lat, lng: this.state.listingCoords[i].lng})
            // }
            // // bounds.extend({lat: parseInt(this.state.eventLat), lng: parseInt(this.state.eventLng)})
            // console.log("asdasdad:", typeof this.state.coordPairList[0][0])
            return (
                
                <Map
                    google={this.props.google}
                    // zoom={13}
                    // zoom={this.state.mapBounds}
                    bounds={this.findBounds(this.props.listingCoords, this.state.coordPairList[0][0], this.state.coordPairList[0][1])}
                    styles={style}
                    initialCenter={{lat: this.state.coordPairList[0][0], lng: this.state.coordPairList[0][1]}}
                    
                    id="map"
                    mapTypeControl={false}
                >
                    {(this.state.coordPairList && 
                    this.state.coordPairList.map(function(pair) {
                        var returnCoordList = []
                        returnCoordList.push(
                            <Marker 
                                id="eventMarker"
                                icon={mapPinEvent} 
                                position={{lat: pair[0], lng: pair[1]}} 
                                onClick={() => {
                                    this.setState({eventMarkerOpen: !this.state.eventMarkerOpen})
                                }}
                            />
                            )
                        return returnCoordList
                    })
                    )}

                    <InfoWindow
                        visible={this.state.eventMarkerOpen}
                        onClose={() => {
                            this.setState({eventMarkerOpen: !this.state.eventMarkerOpen})
                        }}
                        position={{lat: this.state.eventLat, lng: this.state.eventLng}} 
                    >
                        <MapModal></MapModal>
                        
                    </InfoWindow>



                    {this.populateMarkers()}
                </Map>
                    


            )
        } else {
            return (
                <div>
                    loading...
                </div>
            )
        }
    }
}

export default GoogleApiWrapper ({
    apiKey: "AIzaSyDhOR2M4tCUcUZLFGZt9_Wyxc9VekwolPw"
    // apiKey: "AIzaSyDQy6fa0OaHLCENq2rdGKmPiTEB-0tAcj0"
})(MapContainer)



