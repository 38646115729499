import React, {Component} from 'react';

import profile from '../../images/profile.jpg';
import instagram from '../../images/instagram.svg';
import facebook from '../../images/facebook.svg';
import snapchat from '../../images/snapchat.svg';
import twitter from '../../images/twitter.svg';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";


class MyAccount extends Component {
    
    render() {
        return(
            <div>
                <div className='vouch-btn btn-four btn-spacing'>Vouch</div>
            </div>
        )   
    }
}

export default MyAccount;