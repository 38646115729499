import React, { Component } from 'react';
import landingBg from '../../images/landingBg.png';
import createProfile from '../../images/createProfile.svg';
import selectEvent from '../../images/selectEvent.svg';
import connectShare from '../../images/connectShare.svg';
import header from '../../images/header.svg';
import save from '../../images/save.svg';
import location from '../../images/location.svg';
import amenities from '../../images/amenities.svg';
import social from '../../images/social.svg';
import openquotes from '../../images/openquotes.svg'
import closedquotes from '../../images/closedquotes.svg'
import fire from '../../images/fire.svg';
import star from '../../images/star.svg';
import pending from '../../images/pending.gif';

import paxeast from '../../images/paxeast.jpg';
import ultra from '../../images/ultra.jpg';
import coachella from '../../images/coachella.jpg';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class Landing1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            incomplete: {
                name: false,
                email: false,
                subject: false,
                message: false,
            },
            display: 'none',
        }

        this.handleChange = this.handleChange.bind(this);
        this.pushForm = this.pushForm.bind(this);
        this.validInputs = this.validInputs.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
    }

    componentDidMount(){
        let trip1 = 'k59vnaar'
        let trip2 = 'k25cgapp'
        let trip3 = 'k4aivgxh'
        this.setState({
            tripData1: this.props.data('listings', trip1),
            tripData2: this.props.data('listings', trip2),
            tripData3: this.props.data('listings', trip3)
        })
    }

    handleChange(event, label) {
        if (label === 'name') {
            this.setState({ name: event.target.value });
        } else if (label === 'email') {
            this.setState({ email: event.target.value });
        } else if (label === 'subject') {
            this.setState({ subject: event.target.value });
        } else if (label === 'message') {
            this.setState({ message: event.target.value });
        }
    }

    pushForm() {
        if (this.validInputs()) {
            this.props.request('notification', {
                category: 'contactForm',
                name: this.state.name,
                email: this.state.email,
                subject: this.state.subject,
                message: this.state.message,
            }).then(() => {
                this.setState({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                    incomplete: {
                        name: false,
                        email: false,
                        subject: false,
                        message: false,
                    },
                    display: 'done',
                });
            }).catch((err) => {
                console.log(err);
            })
        } else {
            this.setState({ display: 'incomplete' });
            //alert("TODO: ui for incomplete fields");
        }
    }

    validInputs() {
        var incompleteDict = this.state.incomplete;
        var anyIncomplete = false;
        if (this.state.name.length === 0) {
            incompleteDict['name'] = true;
            anyIncomplete = true;
        }
        if (this.state.email.length === 0) {
            incompleteDict['email'] = true;
            anyIncomplete = true;
        }
        if (this.state.subject.length === 0) {
            incompleteDict['subject'] = true;
            anyIncomplete = true;
        }
        if (this.state.message.length === 0) {
            incompleteDict['message'] = true;
            anyIncomplete = true;
        }
        this.setState({ incomplete: incompleteDict });
        return !anyIncomplete;
    }

    renderMessage() {
        if (this.state.display === "none") {
            return (
                <div className='incorrect-field'>&#160;</div>
            )
        } else if (this.state.display === "done") {
            return (
                <div className='valid-field'>Message Sent</div>
            )
        }
    }

    render() {
        return (
            <div>
                <head>
                    <meta charSet='UTF-8' />
                    <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />

                </head>
                <body className='landing-page'>
                    <section className='join-now-section'>
                        <img src={landingBg} />
                        <div className='join-now-content'>
                            <div className='join-now-header'>Discover a place</div>
                            <div className='join-now-subheader'>Where you belong</div>
                            <div className='join-now-body'>TravelFam is more than just a place that allows travelers to meet and share lodging accommodations. We are a community of people just like you. People who are inspired by travel and the connections we make. We live for the experience.</div>
                            {/*<div className='join-now-btn'>
                                <Link to='/signup?id=1'><div className='btn-one'>Join now</div></Link>
                            </div>*/}
                        </div>
                    </section>

                    {/*<section className='how-it-works-section'>
                    <div className='hiw-section-header'>How it Works</div>
                    <div className='hiw-section-subheader'>Come join us, it's free!</div>
                    <div className='hiw-container'>
                        <div className='hiw-content'>
                            <div className='hiw-content-icon'>
                                <img src={createProfile}/>
                            </div>
                            <div className='hiw-content-header'>Create a Profile</div>
                            <div className='hiw-content-body'>Share what an amazing person you are with the community!</div>
                        </div>
                        <div className='hiw-content'>
                            <div className='hiw-content-icon'>
                                <img src={selectEvent}/>
                            </div>
                            <div className='hiw-content-header'>Select Your Event</div>
                            <div className='hiw-content-body'>Browse through upcoming events and find shared accommodations led by our community members.</div>
                        </div>
                        <div className='hiw-content'>
                            <div className='hiw-content-icon'>
                                <img src={connectShare}/>
                            </div>
                            <div className='hiw-content-header'>Connect and Share</div>
                            <div className='hiw-content-body'>Connect with new friends, save on lodging expenses, and create lasting memories.</div>
                        </div>
                    </div>
                </section>*/}

                    <section className='purpose-section'>
                        <div className='purpose-text'>We make travel more <span style={{ color: '#3d98ff' }}>accessible</span>, <br /> <span style={{ color: '#1bc938' }}>affordable</span>, and <span style={{ color: '#EBC25B' }}>social</span> for <span style={{ color: '#e14658' }}>everyone</span>.</div>
                    </section>

                    {/*<section className='featured-listings-section'>
                        <div className='fl-section-header'>Featured Listings</div>
                        <div className='fl-section-subheader'>Come join us on some of our upcoming events!</div>
                        <div className='fl-container'>
                            
                            <Link to='/myTripsDetails/k25cgapp/Xar26A9D0OcMiPoIJi2C3UfMeb32'><div className='fl-content'>
                                <div className='fl-content-img-container'><img src={paxeast} /></div>
                                <div className='fl-event-label cancel-tag'>TwitchCon 2020</div>
                                <div className='fl-details'>Sep 24 - 28, 2020</div>
                                <div className='fl-details'>${this.state.tripData2 ? this.state.tripData2.pricePerSpot : <img src={pending}/>} per spot / ${this.state.tripData2 ? Math.ceil(this.state.tripData2.pricePerSpot/((Date.parse(this.state.tripData2.toDate) - Date.parse(this.state.tripData2.fromDate))/(1000*60*60*24))) : <img src={pending}/>} per night</div>
                                <div className='fl-row'>
                                    <div className='fl-spots-left'>
                                        <div className='fl-details-spots-left'>{this.state.tripData2 ? this.state.tripData2.availableSpots : <img src={pending}/>} spots left</div>
                                        <img src={fire} />
                                    </div>
                                    <div className='fl-details-link'>View more details</div>
                                </div>
                            </div>
                            </Link>
                            <Link to='/myTripsDetails/k4aivgxh/4mTVjRsdxffNcMxSGHQ7bhh2Cyu1'><div className='fl-content'>
                                <div className='fl-content-img-container'><img src={ultra} /></div>
                                <div className='fl-event-label org-tag'>Ultra Miami 2020</div>
                                <div className='fl-details'>Mar 19 - 23, 2020</div>
                                <div className='fl-details'>${this.state.tripData3 ? this.state.tripData3.pricePerSpot : <img src={pending}/>} per spot / ${this.state.tripData3 ? Math.ceil(this.state.tripData3.pricePerSpot/((Date.parse(this.state.tripData3.toDate) - Date.parse(this.state.tripData3.fromDate))/(1000*60*60*24))) : <img src={pending}/>} per night</div>
                                <div className='fl-row'>
                                    <div className='fl-spots-left'>
                                        <div className='fl-details-spots-left'>{this.state.tripData3 ? this.state.tripData3.availableSpots : <img src={pending}/>} spots left</div>
                                        <img src={fire} />
                                    </div>
                                    <div className='fl-details-link'>View more details</div>
                                </div>
                            </div>
                            </Link>
                            <Link to='/myTripsDetails/k59vnaar/Xar26A9D0OcMiPoIJi2C3UfMeb32'><div className='fl-content'>
                                <div className='fl-content-img-container'><img src={coachella} /></div>
                                <div className='fl-event-label res-tag'>Coachella 2020</div>
                                <div className='fl-details'>Apr 9 - 13, 2020</div>
                                <div className='fl-details'>${this.state.tripData1 ? this.state.tripData1.pricePerSpot : <img src={pending}/>} per spot / ${this.state.tripData1 ? Math.ceil(this.state.tripData1.pricePerSpot/((Date.parse(this.state.tripData1.toDate) - Date.parse(this.state.tripData1.fromDate))/(1000*60*60*24))) : <img src={pending}/>} per night</div>
                                <div className='fl-row'>
                                    <div className='fl-spots-left'>
                                        <div className='fl-details-spots-left'>{this.state.tripData1 ? this.state.tripData1.availableSpots : <img src={pending}/>} spots left</div>
                                        <img src={fire} />
                                    </div>
                                    <div className='fl-details-link'>View more details</div>
                                </div>
                            </div>
                            </Link>
                        </div>
            </section>*/}

                    <section className='video-section'>
                        <div className='video-section-header'>Our Memories</div>
                        <div className='video-section-subheader'>Here are some of our favorite memories. But don't take our word for it, come see what it's like yourself.</div>
                        <div className='video-container-wrapper'>
                            <div className='video-container'>
                                <div className='video-text'>Breakfast is our favorite time of the day. It's the moment when we wake up to the smell of eggs and bacon. It's the moment when everyone slowly joins downstairs and start recapping the night before. It's the moment when everyone is in the same place to chill out for a bit before making moves the rest of the day. Here's a glimpse into what breakfast is like at the TravelFam house :) </div>
                                <div className='video-embed'>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/E_I-CLiIzEU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                                </div>
                            </div>
                            <div className='video-container'>
                                <div className='video-embed'>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/vhsXfqvHr5Y" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div className='video-text'>TravelFam is about building a community that supports each other. One of our roommates put on a heck of a performance for TwitchCon and we are thrilled to have been a small part of that.</div>
                            </div>
                        </div>
                    </section>

                    <section className='testimonial-section'>
                        <div className='testimonial-section-header'>Our Community</div>
                        <div className='testimonial-section-subheader'>See what TravelFam community members are saying about their experience</div>
                        <div className='testimonial-ratings'>
                            <div className='testimonial-ratings-graphic'>
                                <img src={star} />
                                <img src={star} />
                                <img src={star} />
                                <img src={star} />
                                <img src={star} />
                            </div>
                            <div className='testimonial-section-subheader'>5/5 rating on our <a href="https://www.facebook.com/mytravelfam">Facebook</a> page</div>
                        </div>
                        <div className='testimonial-container'>
                            <div className='testimonial-content'>
                                <div className='testimonial-text'>
                                    <img src={openquotes} />
                                    <div>“TravelFam was definitely a much affordable way of finding a place to stay with the added bonus of meeting tons of new people that I probably wouldn't have ever met. If you're on the fence of shared housing with randoms, you should definitely give it a try. It's an experience you want to have!"</div>
                                    <img src={closedquotes} />
                                </div>
                                <div className='testimonial-signature'>
                                    <div className='testimonial-signature-name'>Benjamin T.</div>
                                    <div className='testimonial-signature-body'>Photographer</div>
                                    <div className='testimonial-signature-body'>TwitchCon</div>
                                </div>
                            </div>
                            <div className='testimonial-content'>

                                <div className='testimonial-text'>
                                    <img src={openquotes} />
                                    <div>“I needed a place last minute and these guys were super helpful in making the booking simple and easy. Location was close and the house was really nice. Can’t beat the price this close to the con.”</div>
                                    <img src={closedquotes} />
                                </div>
                                <div className='testimonial-signature'>
                                    <div className='testimonial-signature-name'>Michael F.</div>
                                    <div className='testimonial-signature-body'>Piano Teacher</div>
                                    <div className='testimonial-signature-body'>BlizzCon</div>
                                </div>
                            </div>
                            <div className='testimonial-content'>
                                <div className='testimonial-text'>
                                    <img src={openquotes} />
                                    <div>“I used TravelFam recently to fill a large suite for blizzcon. It was incredibly easy to use, and I was provided support whenever necessary. This service was exactly what I needed when going to a convention so far away by myself. Will definitely use again in the future!”</div>
                                    <img src={closedquotes} />
                                </div>

                                <div className='testimonial-signature'>
                                    <div className='testimonial-signature-name'>Alex A.</div>
                                    <div className='testimonial-signature-body'>Engineer</div>
                                    <div className='testimonial-signature-body'>BlizzCon</div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='why-share-accommodations-section'>

                        <div className='why-container'>
                            <div className='why-content-header'>Why Share Accommodations</div>
                            <div className='why-content-body'>There are plenty of reasons to share accommodations through TravelFam. Here are just a few.</div>
                            <div className='why-content-item'>
                                <div className='why-content-subheader'>
                                    <img src={save} />
                                    Save on lodging bills
                            </div>
                                <div className='why-content-body'>The more we share, the more we save. Post the extra space in your hotel or rental house, or join someone else's and save hundreds in lodging bills on your next trip.</div>
                            </div>
                            <div className='why-content-item'>
                                <div className='why-content-subheader'>
                                    <img src={location} />
                                    Access to sold-out locations
                            </div>
                                <div className='why-content-body'>Members are posting the extra spaces in hotels or rental houses in places that would otherwise be fully booked. Get access to the best locations for a fraction of the cost.</div>
                            </div>
                            <div className='why-content-item'>
                                <div className='why-content-subheader'>
                                    <img src={amenities} />
                                    Maximize your amenities
                            </div>
                                <div className='why-content-body'>Want a private pool or hot tub? Or how about a large open living room to lounge around in? Get access to amenities that are only available in larger homes.</div>
                            </div>
                            <div className='why-content-item'>
                                <div className='why-content-subheader'>
                                    <img src={social} />
                                    Meet new friends
                            </div>
                                <div className='why-content-body'>We believe traveling is about the people we meet and the experiences we share. Find a community of people just like you. People who are inspired by travel and the connections we make.</div>
                            </div>

                        </div>
                    </section>

                    <section className='contact-us-section'>
                        <div className='contact-us-header'>SEND US A MESSAGE</div>
                        <div className='contact-us-subheader'>We'd love to hear from you</div>
                        <div className='contact-us-form'>
                            <div className='contact-us-row'>
                                <input type='text' placeholder='Name:' onChange={(e) => this.handleChange(e, 'name')} value={this.state.name} required={this.state.incomplete.name} />
                                <input type='text' placeholder='Email:' onChange={(e) => this.handleChange(e, 'email')} value={this.state.email} required={this.state.incomplete.email} />
                            </div>
                            <div className='contact-us-row'>
                                <input type='text' placeholder='Subject:' onChange={(e) => this.handleChange(e, 'subject')} value={this.state.subject} required={this.state.incomplete.subject} />
                            </div>
                            <div className='contact-us-row'>
                                <textarea placeholder='Write a message' onChange={(e) => this.handleChange(e, 'message')} value={this.state.message} required={this.state.incomplete.message} />
                            </div>
                            <div className='contact-us-btn'>
                                <div className='btn-one' onClick={() => this.pushForm()}>Send</div>
                            </div>
                            {this.renderMessage()}

                        </div>
                    </section>

                    <section className='landing-footer-section'>
                        <div className='landing-footer-container'>
                            <div className='landing-footer-about'>
                                <Link to="/"><img src={header} /></Link>
                                <div className='landing-footer-about-body'>TravelFam is an online place where travelers can meet to share lodging accommodations. Our goal is to make travel more accessible, affordable, and social for everyone.</div>
                            </div>

                            <div className='landing-footer-links'>PRIVACY &#38; TOS
                        <Link to="/privacy"><div>Privacy Policy</div></Link>
                                <Link to="/TOS"><div>Terms of Service</div></Link>
                            </div>

                            <div className='landing-footer-contact'>CONTACT US
                        <div className='landing-footer-email'>hello@travelfam.io</div>
                            </div>
                        </div>
                    </section>
                </body>
            </div>
        )
    }
}

export default Landing1;