import React, {Component} from 'react';

import instagram from '../../images/instagram.svg';
import facebook from '../../images/facebook.svg';
import snapchat from '../../images/snapchat.svg';
import twitter from '../../images/twitter.svg';

import Loading from '../Loading';

import NavBar from '../Navigation';
import MyProfile from './myProfile.js';
import AccountInfo from './accountInfo.js';
import Payout from './Payout.js';
import Notification from './notification.js';

const uuidv1 = require('uuid/v1');

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentView: "MyProfile",
        }
        this.renderView = this.renderView.bind(this);
    }

    renderView() {
        if (this.props.view === "profileview") {
            return (
                <MyProfile
                    firebase = {this.props.firebase}
                    data = {this.props.data}
                    updateData = {this.props.updateData}
                    writeData = {this.props.writeData}
                ></MyProfile>
            )
        } else if (this.props.view === "accountinformation") {
            return (
                <AccountInfo
                    firebase = {this.props.firebase}
                    data = {this.props.data}
                    updateData = {this.props.updateData}
                    writeData = {this.props.writeData}
                ></AccountInfo>
            )
        } else if (this.props.view === "payout") {
            return (
                <Payout
                    firebase = {this.props.firebase}
                    readSubcollection = {this.props.readSubcollection}
                    request = {this.props.request}
                    data = {this.props.data}
                    updateData = {this.props.updateData}
                    writeData = {this.props.writeData}
                ></Payout>
            )
        } else if (this.props.view === "notificationsettings") {
            return (
                <Notification
                    firebase = {this.props.firebase}
                    data = {this.props.data}
                    updateData = {this.props.updateData}
                    writeData = {this.props.writeData}
                ></Notification>
            )
        } else {
            return (
              <Loading notFound={true}></Loading>
            )
        }
    }

    
    render() {
        if ((this.props.firebase.auth().currentUser) && (this.props.data('users', this.props.firebase.auth().currentUser.uid))) {
            return(
                <div>
                    <head>
                        <meta charSet='UTF-8'/>
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                        <title>Profile</title>
                    </head>
                    <body>
                        <NavBar
                            firebase = {this.props.firebase}
                            data = {this.props.data}
                            view = ""
                            emptyCache = {this.props.emptyCache}
                        ></NavBar>
                        {this.renderView()}
                    </body>
                </div>
            )   
        } else {
            return (
                <Loading></Loading>
            )
        }
    }
}

export default Profile;
