import React, { Component } from 'react';
import ListingCreate1 from './CListingStage1.js';
import ListingCreate2 from './CListingStage2.js';
import ListingCreate3 from './CListingStage3.js';
import Loading from '../Loading';
import uniqid from 'uniqid';
import NavBar from '../Navigation';

class ListingCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listingTitle: '',
            fromDate: '',
            toDate: '',
            events: '',
            description: '',
            availableSpots: 1,
            pricePerSpot: '',
            deposit: '',
            cancellationPolicy: '',
            imageList: [],
            imageUrlList: [],
            address: '',

            myTripsIdArray: [],
            myTrips: [],
            cancelRequested: [],
            requestedGuests: [],
            reservedGuests: [],
            tripOrganizerId: '',
            active: true,
            timestamp: Math.floor(Date.now() / 1000)

        };
        this.recordValues = this.recordValues.bind(this);
        this.pushListing = this.pushListing.bind(this);
        this.editListing = this.editListing.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    recordValues(values) {
        var newDict = this.state;
        for (var val in values) {
            newDict[val] = values[val];
        }
        this.setState(newDict);
    }

    pushListing(values) {
        //remove others
        delete this.state.advancePage;
        delete this.state.calendarOut;
        delete this.state.imageList;
        var dict = this.state;
        dict['tripOrganizerId'] = this.props.firebase.auth().currentUser.uid;

        var id = uniqid();
        this.props.writeData('listings', dict, id);
        var newTrips = this.props.data('users', this.props.firebase.auth().currentUser.uid)['myTrips'];
        newTrips.push({
            requestedSpots: "Organizer",
            tripId: id
        });
        this.props.updateData('users', this.props.firebase.auth().currentUser.uid, 'myTrips', newTrips);
        this.props.updateData('channels', this.state.events, id, id);
    }

    async editListing(values) {
        //remove others
        delete this.state.advancePage;
        delete this.state.calendarOut;
        delete this.state.imageList;
        var dict = this.state;
        dict.lastEditedTimestamp = Math.floor(Date.now() / 1000)
        var id = this.props.tripId;
        let promise1 = this.props.updateDoc('listings', id, dict);
        await promise1
    }

    renderContent() {
        if (this.props.listingEdit) {
            let tripData = this.props.data('listings', this.props.tripId)
            if (tripData) {
                if (tripData.tripOrganizerId === this.props.firebase.auth().currentUser.uid) {
                    //ADD VIEWS HERE
                    if (this.props.view === '1') {
                        return (
                            <ListingCreate1
                                firebase={this.props.firebase}
                                data={this.props.data}
                                updateData={this.props.updateData}
                                writeData={this.props.writeData}
                                recordValues={this.recordValues}
                                currentDict={tripData}
                                event={undefined}
                                listingEdit={this.props.listingEdit}
                                tripId={this.props.tripId}
                            ></ListingCreate1>
                        )
                    } else if (this.props.view === '1a') {
                        return (
                            <ListingCreate1
                                firebase={this.props.firebase}
                                data={this.props.data}
                                updateData={this.props.updateData}
                                writeData={this.props.writeData}
                                recordValues={this.recordValues}
                                currentDict={this.state}
                                event={undefined}
                                listingEdit={this.props.listingEdit}
                                tripId={this.props.tripId}
                            ></ListingCreate1>
                        )
                    } else if (this.props.view === '2') {
                        return (
                            <ListingCreate2
                                localData={this.props.localData}
                                firebase={this.props.firebase}
                                data={this.props.data}
                                updateData={this.props.updateData}
                                writeData={this.props.writeData}
                                recordValues={this.recordValues}
                                currentDict={this.state}
                                listingEdit={this.props.listingEdit}
                                tripId={this.props.tripId}
                            ></ListingCreate2>
                        )
                    } else if (this.props.view === '3') {
                        return (
                            <ListingCreate3
                                firebase={this.props.firebase}
                                data={this.props.data}
                                updateData={this.props.updateData}
                                writeData={this.props.writeData}
                                recordValues={this.recordValues}
                                editListing={this.editListing}
                                currentDict={this.state}
                                formatDateRange={this.props.formatDateRange}
                                listingEdit={this.props.listingEdit}
                                tripId={this.props.tripId}
                            ></ListingCreate3>
                        )
                    } else {
                        return (
                            <Loading notFound={true}></Loading>
                        )
                    }

                } else {
                    return (<Loading notFound={true}></Loading>)
                }
            } else {
                return (<Loading />)
            }
        } else if (this.props.view === '1') {
            return (
                <ListingCreate1
                    firebase={this.props.firebase}
                    data={this.props.data}
                    updateData={this.props.updateData}
                    writeData={this.props.writeData}
                    recordValues={this.recordValues}
                    currentDict={this.state}
                    event={undefined}
                ></ListingCreate1>
            )
        } else if (this.props.view === '2') {
            return (
                <ListingCreate2
                    localData={this.props.localData}
                    firebase={this.props.firebase}
                    data={this.props.data}
                    updateData={this.props.updateData}
                    writeData={this.props.writeData}
                    recordValues={this.recordValues}
                    currentDict={this.state}
                ></ListingCreate2>
            )
        } else if (this.props.view === '3') {
            return (
                <ListingCreate3
                    firebase={this.props.firebase}
                    data={this.props.data}
                    updateData={this.props.updateData}
                    writeData={this.props.writeData}
                    pushListing={this.pushListing}
                    currentDict={this.state}
                    formatDateRange={this.props.formatDateRange}
                ></ListingCreate3>
            )
        } else {
            return (
                <Loading notFound={true}></Loading>
            )
        }
    }

    render() {
        if (this.props.firebase.auth().currentUser && this.props.data('channels', 'matt_ann_wedding')) {
            return (
                <div>
                    <NavBar
                        firebase={this.props.firebase}
                        data={this.props.data}
                        view=""
                        emptyCache={this.props.emptyCache}
                    ></NavBar>
                    {this.renderContent()}
                </div>
            )
        } else {
            return (
                <Loading></Loading>
            )
        }

    }
}

export default ListingCreate;