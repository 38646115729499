import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import PhoneInput from 'react-phone-number-input';
import Loading from '../Loading';

// import 'react-phone-number-input/react-responsive-ui';
import 'react-phone-number-input/style.css';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import Page1 from './signup1.js';
import Invitation from './invitation.js';
import Page2 from './signup2.js';
import Page3 from './signup3.js';
import Page2a from './signup2a.js';
import Page2b from './Signup2b.js';
import Page2c from './WaitlistConfirm.js';

var signUpInfo = {};

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "phoneEntry",
      phone: '',
      smsConfirmed: false,
      createdAccount: false,
      incorrectSMS: false,
      tooManyAttempts: false,
    }
    this.evaluatePhone = this.evaluatePhone.bind(this);
    this.setPhone = this.setPhone.bind(this);
    this.verifySMS = this.verifySMS.bind(this);
    this.createUser = this.createUser.bind(this);
    this.checkPhoneExists = this.checkPhoneExists.bind(this);
    this.checkEmailExists = this.checkEmailExists.bind(this);
    this.checkBetaKeys = this.checkBetaKeys.bind(this);
  }

  evaluatePhone(phone) {
    if (true) { //make sure sending to phone number sms
      signUpInfo['phone'] = phone;
      this.setState({ phone: phone });
      var appVerifier = new this.props.firebase.auth.RecaptchaVerifier("recaptchaContainer", { 'size': 'invisible' });
      this.props.firebase.auth().signInWithPhoneNumber(phone, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
        }).catch((error) => {
          // Error; SMS not sent
          // ...
          console.log(error);
          this.setState({ tooManyAttempts: true });
        });
      this.setState({ page: 'smsEntry' });
    }
  }

  setPhone(phone) {
    this.setState({ phone: phone });
  }

  verifySMS(sms) {
    if (sms) {
      window.confirmationResult.confirm(sms).then((result) => {
        //console.log("Successful sms!")
        this.setState({ smsConfirmed: true });
      }).catch((error) => {
        //console.log('Error while checking the verification code', error);
        this.setState({ smsConfirmed: false });
        this.setState({ incorrectSMS: true });
        //if user is not able to sign in correctly, need to null the credential created because it is the wrong credential
      });
    }
  }

  createUser(data, dataPrivate) {
    var credential = this.props.firebase.auth.EmailAuthProvider.credential(dataPrivate['email'], dataPrivate['password']);
    this.props.firebase.auth().currentUser.linkWithCredential(credential).then(() => {
      // this.props.firebase.auth().createUserWithEmailAndPassword(dataPrivate['email'], dataPrivate['password']).then(() => { //AB: REMOVED TO AVOID DOUBLE ACCOUNT CREATION
      delete dataPrivate['password'];
      this.props.writeData('users', data, this.props.firebase.auth().currentUser.uid);
      this.props.writeData('users', dataPrivate, this.props.firebase.auth().currentUser.uid, true);
      dataPrivate['phone'] = this.state.phone; //TODO: DELETE?
      this.props.request('notification', {
        category: 'adminMessage',
        text: 'New User Signup \n'+JSON.stringify(data)+'\n'+JSON.stringify(dataPrivate),
      })
      this.props.firebase.auth().currentUser.updateProfile({
        displayName: 'done'
      }).catch((error) => {
        console.log(error);
      })
      this.setState({ createdAccount: true });
    }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorMessage);
      // ...
    });
  }

  async checkPhoneExists(number) {
    let response = await fetch(window.$backend, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ func: 'checkPhoneEmailExists', type: 'phone', phone: number })
    });
    response = await response.json()
    console.log(response)
    return response
  }

  async checkEmailExists(email) {
    let response = await fetch(window.$backend, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ func: 'checkPhoneEmailExists', type: 'email', email: email })
    });
    response = await response.json()
    console.log(response)
    return response
  }

  async checkBetaKeys(number) {
    var returnBool = false
    let response = await fetch(window.$backend, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ func: 'checkBetaKeys', type: 'phone', phone: number })
    });
    response = response.json()
    console.log(response)
    return response
  }

  render() {
    if (this.state.createdAccount) {
      //this.setState({createdAccount: false});
      return (
        <Redirect to="/events?id=home"></Redirect>
      )
    } else if (this.state.smsConfirmed) {
      this.setState({ smsConfirmed: false });
      return (
        <Redirect to="/signup?id=3"></Redirect>
      )
    }

    if (this.props.view === "1") {
      return (
        <Page1
          firebase={this.props.firebase}
          data={this.props.data}
          updateData={this.props.updateData}
          evaluatePhone={this.evaluatePhone}
          setPhone={this.setPhone}
          tooManyAttempts={this.state.tooManyAttempts}
          setSignUpPending={this.props.setSignUpPending}
          checkPhoneExists={this.checkPhoneExists}
          checkEmailExists={this.checkEmailExists}
          checkBetaKeys={this.checkBetaKeys}
        ></Page1>
      );
    } else if (this.props.view === "Invitation") {
      return (
        <Invitation
          firebase={this.props.firebase}
          data={this.props.data}
          updateData={this.props.updateData}
          verifySMS={this.verifySMS}
          phone={this.state.phone}
          incorrectSMS={this.state.incorrectSMS}
          tooManyAttempts={this.state.tooManyAttempts}
          setSignUpPending={this.props.setSignUpPending}
        ></Invitation>
      );
    } else if (this.props.view === "2") {
      return (
        <Page2
          firebase={this.props.firebase}
          data={this.props.data}
          updateData={this.props.updateData}
          verifySMS={this.verifySMS}
          phone={this.state.phone}
          incorrectSMS={this.state.incorrectSMS}
          tooManyAttempts={this.state.tooManyAttempts}
          setSignUpPending={this.props.setSignUpPending}
        ></Page2>
      );
    } else if (this.props.view === "3") {
      return (
        <Page3
          firebase={this.props.firebase}
          data={this.props.data}
          updateData={this.props.updateData}
          createUser={this.createUser}
          phone={this.state.phone}
          checkPhoneExists={this.checkPhoneExists}
          checkEmailExists={this.checkEmailExists}
          checkBetaKeys={this.checkBetaKeys}
        ></Page3>
      );
    } else if (this.props.view === "2a") {
      return (
        <Page2a
          firebase={this.props.firebase}
          data={this.props.data}
          updateData={this.props.updateData}
          createUser={this.createUser}
          phone={this.state.phone}
        ></Page2a>
      );
    } else if (this.props.view === "2b") {
      return (
        <Page2b
          firebase={this.props.firebase}
          data={this.props.data}
          updateData={this.props.updateData}
          createUser={this.createUser}
          phone={this.state.phone}
          request={this.props.request}
        ></Page2b>
      );
    } else if (this.props.view === "2c") {
      return (
        <Page2c
          firebase={this.props.firebase}
          data={this.props.data}
          updateData={this.props.updateData}
          createUser={this.createUser}
          phone={this.state.phone}
        ></Page2c>
      );
    } else {
      return (
        <Loading notFound={true}></Loading>
      )
    }
  }
}


export default SignUp;
