import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import Delete from '../../images/delete.svg';

class DragAndDrop extends Component {
  constructor() {
    super();
    this.onDrop = (files) => {
      files.map((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      })
      if (this.state.files.length == 0) {
        this.setState({files})
      } else {

        this.setState({files: [...this.state.files, ...files]})
      }
      this.props.imgCallBack(this.state.files)
    };
    this.state = {
      files: []
    }
    
  }

  componentDidMount() {
    if (this.props.existingImgs !== undefined) {
      this.setState({files: this.props.existingImgs})

    }

    
  }

  render() {
    const files = this.state.files.map(file => (
      
      <div className="cl-imgPreviewContainer">
        <img
          src={file.preview}
          className="cl-imgPreview"
        />
        <div className='cl-imgPreview-delete'>
          <div className='svg-btn decline' onClick={()=>(this.props.removeImage(file.path))}/>
        </div>
      </div>  
      
    ));

    return (
      <Dropzone onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className="dz-container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <p className='dz-drop-area'>Drag 'n drop some files here, or click to select files</p>
            </div>
            <aside className="cl-imgPreviewWrapper">
              {files}
            </aside>
          </section>
        )}
      </Dropzone>
    );
  }
}

export default DragAndDrop