import React, { Component } from 'react'
import firebase from '../Firebase'
import '../../css/index.css'

class CancelReqView extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }

        this.findCancelledSpots = this.findCancelledSpots.bind(this)
       
    }
    componentDidMount() {
        this.setState({
            listingData: this.props.listingData,
            currentUserId: this.props.currentUserId
        })
    }

    findCancelledSpots() {
        if (this.state.listingData) {
            console.log(this.state.currentUserId)
            for (var i = 0; i < this.state.listingData.cancelRequested.length; i++) {
                if (this.state.listingData.cancelRequested[i].cancelUserId == this.state.currentUserId) {
                    return this.state.listingData.cancelRequested[i].cancelSpots
                }
            }

        }
    }

    render() {
        if (this.state.listingData) {
            return(
                <section className='dash-sidebar'>

                    <section className='trip-status'>
                        {this.props.renderDates()}
                        <div className='trip-info'>
                            <div className='trip-info-row'>
                                <div>Booking Status:</div>
                                <div>
                                    <div className='org-tag' 
                                        style={{ backgroundColor: "#FF4646" }}>
                                        Cancel Requested
                                    </div>
                                </div>
                            </div>
                            
                            <div className='trip-info-row'>
                                <div>Reserved Spots:</div>
                                <div>{this.findCancelledSpots()}</div>
                            </div>
                            <div className='trip-info-row'>
                                <div>Price per Spot:</div>
                                <div>${this.state.listingData.pricePerSpot}</div>
                            </div>
                            <div className='trip-info-row'>
                                <div>Total:</div>
                                <div>${parseInt(this.state.listingData.pricePerSpot.replace(/,/g, ''), 10) * parseInt(this.findCancelledSpots(), 10)}</div>
                            </div>
                        </div>
                    </section>

                    <section className='trip-manage'>
                        <div className='trip-manage-header'>Manage your Trip</div>
                        <div className='trip-manage-area'>

                            <div className='trip-manage-msg'>Your cancellation request has been submitted to the organizer</div>


                        </div>

                    </section>

                </section>

            )
        } else {
            return (
                <div>
                    loading...
                </div>
            )
        }
    }
}

export default CancelReqView