import React, {Component} from 'react';
import header from '../../images/header.svg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class NavBarPrelogin extends Component {

    render() {
        return(
        <div>
            <section className='nav-prelogin-page'>
                <div className='nav-prelogin-area'>
                    <div className='nav-prelogin-left'><Link to="/"><img src={header}/></Link></div>
                    <div className='nav-prelogin-right'>
                        <Link to="/login"><div className='btn-login'>Login</div></Link>
                    </div>
                </div>
            </section>
        </div>
        )   
    }
}

export default NavBarPrelogin;