import React, {Component} from 'react';
import header from '../../images/header.svg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class NavBarLanding extends Component {

    render() {
        return(
        <div>
            <section className='nav-landing-page'>
                <div className='nav-prelogin-area'>
                    <div className='nav-prelogin-left'><Link to="/"><img src={header}/></Link></div>
                    {/*<div className='nav-prelogin-right'>
                        <Link to="/login"><div className='btn-login'>Login</div></Link>
                    </div>*/}
                </div>
                <div style={{color: "red"}}>Note: To help with the global effort in preventing the spread of COVID-19, we are temproarily restricting access to the platform. We apologize for any inconvenience and appreciate your understanding.</div>
            </section>
        </div>
        )   
    }
}

export default NavBarLanding;