import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import bus from '../../images/bus.png';

class myTripsBlank extends Component {
    
    render() {
        return(
            <body>
                <section className='mt-blank-page'>
                    <div className='mt-blank-header'>   
                        You don't have any trips planned yet
                        <img src={bus}/>
                    </div>
                    <div className='mt-blank-body'>
                        <Link to="/listingcreate?id=1"><div className='btn-one mt-blank-page-btn'>Create a Listing</div></Link>
                        <Link to="/events?id=home"><div className='btn-one mt-blank-page-btn'>Browse Events</div></Link>
                    </div>
                </section>
            </body>
        )
    }
}

export default myTripsBlank;