import React, {Component} from 'react';

import exit from '../../images/exit.svg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";



class CancelConfirmation extends Component {
    
    render() {
        return(
            <body>
                <div className='od-cancel-page'>
                    <div className='od-cancel-container'>
                        <div className='od-cancel-title'>Are you sure you want to cancel this reservation?</div>
                        <div className='od-cancel-text'>If you haven't already done so, it's always best to give the person a heads up</div>
                        <div className='od-cancel-btn-row'>
                            <div className='btn-three'>Cancel</div>
                            <div className='btn-one'>Confirm</div>
                        </div>
                        <div className='exit'><img src={exit}/></div>
                    </div>
                </div>
            </body>
        )   
    }
}

export default CancelConfirmation;