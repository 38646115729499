import React, { Component } from 'react'
import firebase from '../Firebase'
import '../../css/index.css'
import CurrencyInput from 'react-currency-input'
import OtherAccount from '../Account/OtherAccount.js';
import pending from '../../images/pending.gif';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class CancelReqView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            amountToPay: 0,
            closeButton: false,
            paid: false
        }

        //cancelRequestOrganizer
        this.requestCancel = this.requestCancel.bind(this)
        this.findReservedSpots = this.findReservedSpots.bind(this)
        this.findPaidAmount = this.findPaidAmount.bind(this)
    }

    handleChange(event, label) {
        if (label === 'amountToPay') {
            this.setState({ amountToPay: event.target.value });
            this.setState({ closeButton: true });
        }
    }


    componentDidMount() {
        this.setState({
            listingData: this.props.listingData,
            currentUserId: this.props.currentUserId,
            tripId: this.props.tripId,
        })
    }

    findReservedSpots() {
        if (this.state.listingData) {
            console.log(this.state.currentUserId)

            for (var i = 0; i < this.state.listingData.reservedGuests.length; i++) {
                if (this.state.listingData.reservedGuests[i].reservedUserId == this.state.currentUserId) {
                    return this.state.listingData.reservedGuests[i].reservedSpots

                }
            }

        }
    }

    findPaidAmount() {
        if (this.props.firebase.auth().currentUser.uid) {
            let paymentData = this.props.readSubcollection('charge_confirmation', this.props.tripId, 'charges')
            let chargedAmount = 0;

            let refundData = this.props.readSubcollection('refund_confirmation', this.props.tripId, 'refunds')
            let refundAmount = 0;

            if (paymentData) {
                console.log(paymentData)
                paymentData.forEach((entry) => {
                    if ((entry.guest === this.state.currentUserId) && (entry.organizer === this.state.listingData.tripOrganizerId) && (entry.status)) {
                        chargedAmount += parseFloat(entry.amount);
                    }
                })
            }

            if (refundData) {
                console.log(refundData)
                refundData.forEach((entry) => {
                    if ((entry.guest === this.state.currentUserId) && (entry.organizer === this.state.listingData.tripOrganizerId) && (entry.status === "succeeded")) {
                        refundAmount += parseFloat(entry.amount);
                    }
                })
            }
            return (chargedAmount - refundAmount)
        } else {
            return 0
        }
    }

    requestCancel() {
        if (this.state.listingData) {
            var currentCancelRequested = this.state.listingData.cancelRequested
            currentCancelRequested.push({
                cancelSpots: this.findReservedSpots(),
                cancelUserId: this.state.currentUserId
            })
        }
        console.log(currentCancelRequested)
        this.props.updateData("listings", this.state.tripId, "cancelRequested", currentCancelRequested)

        if (this.props.data('users', this.state.listingData['tripOrganizerId'])['newMessages']) {
            this.props.request('notification', {
                category: 'update',
                type: 'CancelRequestOrganizer',
                uid: this.state.listingData['tripOrganizerId']
            })
        }
    }

    render() {
        console.log('RENDERING');
        if (this.state.listingData) {
            return (
                <section className='dash-sidebar'>

                    <section className='trip-status'>
                        {this.props.renderDates()}
                        <div className='trip-info'>
                            <div className='trip-info-row'>
                                <div>Booking Status:</div>
                                <div>
                                    <div className='org-tag'
                                        style={{ backgroundColor: "#1BC938" }}>
                                        Reserved
                                    </div>
                                </div>
                            </div>
                            <div className='trip-info-row'>
                                <div>Listing ID:</div>
                                <div>{this.props.tripId}</div>
                            </div>

                            <div className='trip-info-row'>
                                <div>Reserved Spots:</div>
                                <div>
                                    {this.findReservedSpots()}
                                </div>
                            </div>
                            <div className='trip-info-row'>
                                <div>Price per Spot:</div>
                                <div>${this.state.listingData.pricePerSpot}</div>
                            </div>
                            <div className='trip-info-row'>
                                <div>Total:</div>
                                <div>${parseInt(this.state.listingData.pricePerSpot.replace(/,/g, ''), 10) * parseInt(this.findReservedSpots(), 10)}</div>
                            </div>
                            <div className='trip-info-row'>
                                <div>Paid through TravelFam:</div>
                                <div> {(this.props.chargeFlag === 'pending' || this.props.chargeFlag === 'failed' || this.props.refundFlag === 'pending' || this.props.refundFlag === 'failed') ? <img src={pending}/> : '$'+this.findPaidAmount()} </div>
                            </div>
                            {/* <div className='trip-info-row'>
                                <div>Make Payment:</div>
                                <div className='pmt-field'>
                                    <CurrencyInput
                                        value={this.state.amountToPay}
                                        onChangeEvent={(e) => this.handleChange(e, "amountToPay")}
                                        id="amountToPay"
                                        prefix="$"
                                        precision="0"
                                    />
                                </div>
                            </div> */}
                            {/* <Link push to={"/payment/" + this.state.tripId + "/topup=" + this.state.amountToPay}>
                                <div className='request-cancel btn-one' >Pay through Travelfam</div>
                            </Link> */}
                            <div className='trip-info-footer'>Payments made through TravelFam will be securely held until the trip is over.</div>
                        </div>
                    </section>

                    <section className='trip-manage'>
                        <div className='trip-manage-header'>Manage your Trip</div>
                        <div className='trip-manage-area'>
                            <div className='ld-standard-cancel-text'>All reservations are non-refundable unless approved by the organizer.</div>
                            <div className='ld-cancel-text'>Additional note from the organizer:
                            <div className='trip-manage-msg'>{this.state.listingData.cancellationPolicy}</div>
                            </div>
                            <div className='request-cancel btn-one' onClick={ () => {this.props.setDisplayModal(true, 'guestCancel', this.requestCancel)}}>Request Cancellation</div>
                        </div>

                    </section>

                </section>

            )
        } else {
            return (
                <div>
                    loading...
                </div>
            )
        }
    }
}

export default CancelReqView