import React, {Component} from 'react';
import Home from './events.js';
import Event from './channels.js';
import Loading from '../Loading';


class Channels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
        if (this.props.id === 'home') {
            return (
                <Home
                    firebase = {this.props.firebase}
                    data = {this.props.data}
                    emptyCache = {this.props.emptyCache}
                ></Home>
            );
        } else if (!this.props.id) {
            return (
              <Loading notFound={true}></Loading>
            );
        } else {
            return (
                <Event
                    firebase = {this.props.firebase}
                    data = {this.props.data}
                    channel = {this.props.id}
                    formatDateRange = {this.props.formatDateRange}
                    emptyCache = {this.props.emptyCache}
                    isMemory = {this.props.isMemory}
                ></Event>
            );
        }
    }
}

export default Channels;