import React, { Component } from 'react';

import logo from '../../images/logo.svg';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailPhoneInput: '',
            passwordInput: '',
            rememberMeInput: '',
            message: 'none',
        }
        this.handleChange = this.handleChange.bind(this);
        this.phoneToEmail = this.phoneToEmail.bind(this);
        this.login = this.login.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
    }

    componentDidMount(){
        var input = document.getElementById('passwordField');

        // Execute a function when the user releases a key on the keyboard
        input.addEventListener("keyup", function(event) {
          // Number 13 is the "Enter" key on the keyboard
          if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            document.getElementById("loginButton").click();
          }
        });
    }
    renderMessage() {
        if (this.state.message === 'none') {
            return (
                <div className='incorrect-field'>&#160;</div>
            )
        } else if (this.state.message === 'noEmail') {
            return (
                <div className='incorrect-field'>The email / password you have entered<br /> does not match our records</div>
            )
        } else if (this.state.message === 'noPassword') {
            return (
                <div className='incorrect-field'>The email / password you have entered<br /> does not match our records</div>
            )
        }
    }

    login(input) {
        const email = this.phoneToEmail(input);
        this.props.firebase.auth().signInWithEmailAndPassword(email, this.state.passwordInput).catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            this.setState({ message: 'noPassword' })
            // ...
        });
    }

    handleChange(event, label) {
        if (label === 'emailPhone') {
            this.setState({ emailPhoneInput: event.target.value });
        } else if (label === 'password') {
            this.setState({ passwordInput: event.target.value });
        } else if (label === 'login') {
            this.login(this.state.emailPhoneInput);
        }
    }

    phoneToEmail(input) {
        return input;
    }


    render() {
        return (
            <div>
                <head>
                    <meta charSet='UTF-8' />
                    <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />
                    <title>Organizer Dashboard</title>
                </head>
                <body>
                    <section className='log-page'>
                        <section className='log-content'>
                            <div className='log-logo'><img src={logo} /></div>
                            <div className='log-header'>Please log in to continue</div>
                            <div className='log-field-container'>
                                <div className='log-input-container'>
                                    <input type='text' placeholder='Enter your email' onChange={(e) => this.handleChange(e, 'emailPhone')} />
                                </div>
                                <div className='log-input-container'>
                                    <input type='password' id='passwordField' placeholder='Enter your password' onChange={(e) => this.handleChange(e, 'password')} />
                                </div>
                            </div>
                            {this.renderMessage()}
                            <div className='log-btn btn-one' id='loginButton' onClick={(e) => this.handleChange(e, 'login')}>Login</div>
                            <div className='log-footer-area'>
                                <div className='log-footer'>
                                    <div className='log-footer-item'>Don't have an account? <Link to="/signup?id=1"><span className='link'>Join us</span></Link></div>
                                    <div className='log-footer-item'>Forgot your password? <Link to="/retrievePw"><span className='link'>Retrieve here</span></Link></div>
                                </div>
                                <div className='log-footer'>By continuing, you agree to the <br /><Link to="/TOS"><span className='link'>Terms of Service</span></Link> and <Link to="/privacy"><span className='link'>Privacy Policy</span></Link></div>
                            </div>
                        </section>
                    </section>
                </body>
            </div>
        )
    }
}
export default Login;