import React, { Component } from 'react';
import Loading from '../Loading';
import previous from '../../images/previous.svg';
import send from '../../images/send.svg';
import { throwStatement } from '@babel/types';

class TextMessages extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }
        this.doneLoading = this.doneLoading.bind(this);
        this.renderSingleMessage = this.renderSingleMessage.bind(this);
        this.renderMessages = this.renderMessages.bind(this);
    }

    doneLoading() {
        if (this.props.otherChatId) {
            if (this.props.data('users', this.props.otherChatId) && this.props.headers) {
                return true;
            }
        }
        return false;
    }

    componentDidUpdate() {
        console.log('updated');
        var scroll = document.getElementById("chat-end");
        if (scroll) {
            document.getElementById("chat-end").scrollIntoView();
        }
    }

    renderSingleMessage(m) {
        const v = this.props.messages[this.props.viewId];
        if (!v) {
            return (
                <Loading />
            );
        } else if (!v[m]) {
            return (
                <Loading />
            )
        } else if (v[m].id === this.props.firebase.auth().currentUser.uid) {
            const d = this.props.data('users', this.props.firebase.auth().currentUser.uid);
            return (
                <div className='msg-sent-chat-container'>
                    <div className='msg-sent-chat-area'>
                        {v[m].message}
                    </div>
                    {/* <div>
                        {message.timeStamp}
                        {message.author}
                    </div> */}
                    <div className='msg-chat-avatar'>
                        <img src={d['imageUrl']} />
                    </div>
                </div>
            )
        } else {
            const d = this.props.data('users', this.props.otherChatId);
            return (
                <div className='msg-received-chat-container'>
                    <div className='msg-chat-avatar'>
                        <img src={d['imageUrl']} />
                    </div>
                    <div className='msg-received-chat-area'>
                        {v[m].message}
                    </div>
                    {/* <div>
                        {message.timeStamp}
                        {message.author}
                    </div> */}
                </div>
            )
        }
    }

    renderMessages() {
        //TODO create messageKeys from header count
        const d = this.props.data('users', this.props.otherChatId);
        //TODO: only load some messages
        var messageKeys = [];
        var i = 0;
        while (i < this.props.headers[this.props.viewId]['count']) {
            messageKeys.push(i);
            i += 1;
        }
        if (this.props.mobileHeader) {
            return (<div/>)
        } else {
            return (
                <section className='msg-chat-section'>
                    <div className='msg-chat-header' onClick={() => this.props.resolveClick("MobileToHeader")}>
                        <img src={previous}/>
                        <div className='msg-header-item'>{d['firstName']} {d['lastName']}</div>
                    </div>                    
                    <div id= 'msg-chat-container' className='msg-chat-container'>                        
                        
                        {
                            messageKeys.map((message) => (
                                this.renderSingleMessage(message)
                            ))
                            
                        }
                        {/* need to fix this scroll issue. Not scrolling all the way to bottom. Problem somwehere with heights in css being VH  */}
                        <div ref={(el) => {this.messagesEnd = el}}></div>  
                        <div id="chat-end"></div> 
                        
                    </div>
                    <div className='msg-type-container'>
                        {/*<div className='msg-icon-area'>
                            <img src={photo}/>
                        </div>*/}
                        <div className='msg-type-area'>
                            <textarea
                                autoComplete="off"
                                id="msg-input" 
                                className='msg-type-textarea'
                                onKeyDown={(e) => this.props.enterKeyDown(e)}
                                placeholder = "Start a new message"
                            >
                            </textarea>
    
                            {/*<div className='msg-icon-area'>
                                <img src={emoji}/>
                            </div>*/}
                        </div>
                        <button 
                            id='btn-send'
                            className='msg-icon-area'
                            onClick={() => this.props.sendMessage()}
                        >
                            <img src={send}/>
                        </button>
                    </div>
                    
                </section>
            )
        }
    }

    render() {
        if (this.doneLoading()) {
            return (
                <div>
                    {this.renderMessages()}
                </div>
            )
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default TextMessages