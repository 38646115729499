import React, {Component} from 'react';
import NavBar from '../Navigation';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import MyTrips from './mytripsnew.js';
import EmptyTrips from './mytripsblank.js';
import Loading from '../Loading';

class Trips extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.firebase.auth().currentUser){
            return (
                <Redirect push='true' to="/login"></Redirect>
            )
        }
        if (this.props.data('users', this.props.firebase.auth().currentUser.uid) && this.props.data('users', this.props.firebase.auth().currentUser.uid)['myTrips']) {
            if (this.props.data('users', this.props.firebase.auth().currentUser.uid)['myTrips'].length === 0) {
                return(
                    <div>
                        <NavBar
                            firebase = {this.props.firebase}
                            data = {this.props.data}
                            view = ""
                            emptyCache = {this.props.emptyCache}
                        ></NavBar>
                        <EmptyTrips
                            firebase = {this.props.firebase}
                            currentUser = {this.props.currentUser}
                            data = {this.props.data}
                            updateData = {this.props.updateData}
                            formatDateRange = {this.props.formatDateRange}
                        ></EmptyTrips>
                    </div>
                )   
            } else {
                return(
                    <div>
                        <NavBar
                            firebase = {this.props.firebase}
                            data = {this.props.data}
                            view = ""
                            emptyCache = {this.props.emptyCache}
                        ></NavBar>
                        <MyTrips
                            firebase = {this.props.firebase}
                            stripeCode = {this.props.stripeCode}
                            paramName = {this.props.paramName}
                            currentUser = {this.props.currentUser}
                            data = {this.props.data}
                            updateData = {this.props.updateData}
                            writeData = {this.props.writeData}
                            formatDateRange = {this.props.formatDateRange}
                            isMemory = {this.props.isMemory}
                        ></MyTrips>
                    </div>
                )   
            }
        } else {
            return (
                <Loading></Loading>
            )
        }
        
    }
}

export default Trips;