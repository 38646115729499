import React, { Component } from 'react'
import firebase from '../Firebase'
import '../../css/index.css'
import { thisExpression } from '@babel/types';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import OtherAccount from '../Account/OtherAccount.js';

class GuestView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectOption: "1",
            redirectPath: '',
        }

        //joinRequestOrganizer
        this.selectChange = this.selectChange.bind(this)
      
    }

    componentDidMount() {
        this.setState({
            userData: this.props.userData,
            listingData: this.props.listingData,
            tripId: this.props.tripId,
            currentUserId: this.props.currentUserId
        })
    }

    selectChange(event) {
        this.setState({selectOption: event.target.value})
    }

    render() {
        if (this.state.redirectPath === "Login") {
            return (
                <Redirect push to="/login"></Redirect>
            )
        } else if (this.state.listingData) {
            var options = []
            for (var i = 1; i <= this.state.listingData.availableSpots; i++) {
                options.push(
                    <option value={i}>{i} Spots</option>
                )
            }
            return(
                <section className='ld-sidebar'>
                    {(this.state.listingData.availableSpots > 0 && 
                        <section className='ld-request-container'>
                            <div className='ld-price'><span style={{fontSize: 30}}>
                                ${this.state.listingData.pricePerSpot}</span> per spot
                            </div>
                            <div className='ld-guest-selection'>
                                <select id="guestNumOptions" onChange={this.selectChange} value={this.state.value}>
                                    {options}
                                </select>
                                <div className='ld-select-icon'>&#9662;</div>
                            </div>
                            <div className='ld-receipt'>
                                <div className='ld-receipt-row'>
                                    <div className='ld-receipt-row-item'>
                                        ${this.state.listingData.pricePerSpot} x {this.state.selectOption}
                                    </div>
                                    <div className='ld-receipt-row-item'>
                                        ${                                            
                                            Number(parseInt(this.state.listingData.pricePerSpot.replace(/,/g, ''), 10) * parseInt(this.state.selectOption, 10).toFixed(0)).toLocaleString().split(/\s/).join(',')
                                        }
                                    </div>
                                </div>   
                                <div className='ld-receipt-row'>
                                    <div className='ld-receipt-row-item'>
                                        Service Fee
                                    </div>
                                    <div className='ld-receipt-row-item'>
                                        Free
                                        {/* ${Math.round(parseInt(this.state.listingData.pricePerSpot, 10) * parseInt(this.state.selectOption, 10) * 0.05)} */}
                                    </div>
                                </div>             
                                <div className='ld-receipt-row'>
                                    <div className='ld-receipt-row-item'>
                                        Total
                                    </div>
                                    <div className='ld-receipt-row-item'>
                                        ${
                                            
                                            Number(parseInt(this.state.listingData.pricePerSpot.replace(/,/g, ''), 10) * parseInt(this.state.selectOption, 10).toFixed(0)).toLocaleString().split(/\s/).join(',')

                                        } {/*+
                                        Math.round(parseInt(this.state.listingData.pricePerSpot, 10) * parseInt(this.state.selectOption, 10) * 0.05)*/}
                                    </div>
                                </div>
                                <div className='od-edit-container'>
                                    <div className='ld-btn-container'>
                                        <Link push to={"/payment/"+ this.state.tripId+"/"+this.state.selectOption}>
                                        <button className="btn-one ld-btn">
                                            Request To Join
                                        </button>
                                        </Link>
                                        {/* <button className="btn-one ld-btn" onClick={this.joinTrip}>
                                            Request To Join
                                        </button> */}
                                    </div>
                                    <div className='ld-btn-container'>
                                        <OtherAccount
                                            firebase = {this.props.firebase}
                                            data = {this.props.data}
                                            updateData = {this.props.updateData}
                                            writeData = {this.props.writeData}
                                            viewId = {this.state.listingData['tripOrganizerId']}
                                            buttonOnly = {true}
                                            request = {this.props.request}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    {(this.state.listingData.availableSpots <= 0 && 
                        <section className='ld-request-container'>
                            <div className='ld-price'><span style={{fontSize: 30}}>
                                {this.state.listingData.pricePerSpot}</span> per spot
                            </div>

                            <div className='ld-listing-full-message'>
                                Sorry, this listing is full
                            </div>
                            
                            <div className='ld-receipt'>
                                <div className='ld-btn-container'>
                                    <button className="btn-four">
                                        Request To Join
                                    </button>
                                </div>
                            </div>
                        </section>
                        
                    
                    
                    )}


                    <section className='ld-cancel-container'>
                        <div className='ld-cancel-header'>Cancellation Policy</div>
                        <div className='ld-standard-cancel-text'>All reservations are non-refundable unless approved by the organizer.</div>
                        {( this.state.listingData.cancellationPolicy.length > 0 &&
                        <div className='ld-cancel-text'>Additional note from the organizer:
                            <div className='ld-cancel-text'>{this.state.listingData.cancellationPolicy}</div>
                        </div>
                        )}
                    </section>

                </section>
            )
        } else {
            return (
                <div>
                    loading...
                </div>
            )
        }
    }
}

export default GuestView