import React, { Component } from 'react'
import '../../css/index.css'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";


class PaymentDetails extends Component {

    render() {
        return(
            
            <div className='admin-page'>
                <div className='admin-container'>
                    <div className='admin-header'>Waitlist</div>
                    <div className='admin-row'>
                        <div className='admin-item'>First Namne</div>
                        <div className='admin-item'>Last Name</div>
                        <div className='admin-item'>Email</div>
                        <div className='admin-item'>phone</div>
                        <div className='admin-item svg-btn od-action-btn accept'></div>
                    </div>
                </div>

                <div className='admin-container'>
                    <div className='admin-header'>Approved</div>
                    <div className='admin-row'>
                        <div className='admin-item'>First Name</div>
                        <div className='admin-item'>Last Name</div>
                        <div className='admin-item'>Email</div>
                        <div className='admin-item'>phone</div>
                    </div>
                </div>

                <div className='admin-container'>
                    <div className='admin-header'>Signed Up</div>
                    <div className='admin-row'>
                        <div className='admin-item'>First Name</div>
                        <div className='admin-item'>Last Name</div>
                        <div className='admin-item'>Email</div>
                        <div className='admin-item'>phone</div>
                    </div>
                </div>
            </div>
        )   
    }
}

export default PaymentDetails