import React, { Component } from 'react';
import Loading from '../Loading';
import NavBar from '../Navigation';
import Confirmation from './confirmation.js';
import Payment from './payment.js';
import Topup from './topup.js';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class PaymentRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.renderContent = this.renderContent.bind(this);
        this.joinTrip = this.joinTrip.bind(this);
        this.topUp = this.topUp.bind(this);
    }

    componentDidMount() {
        if (this.props.firebase.auth().currentUser) { //TODO
            this.setState({
                currentUser: this.props.currentUser.uid,
                userData: this.props.data('users', this.props.currentUser.uid),
                listingData: this.props.data('listings', this.props.tripId)
            })
        }
    }

    joinTrip(payment, nonce, customerId) {
        if (this.state.userData) {
            var tripId = this.props.tripId
            var currentUserId = this.state.currentUser
            var currentMyTrips = this.state.userData["myTrips"]
            currentMyTrips.push(
                { tripId: tripId, requestedSpots: this.props.requestedSpots, nonce: nonce, customerId: customerId, payment: payment }
            )
            console.log('Updating users ' + currentUserId)
            console.log(currentMyTrips)
            this.props.updateData("users", currentUserId, "myTrips", currentMyTrips)
            var currentRequestedGuests = this.state.listingData.requestedGuests
            currentRequestedGuests.push(
                { requestedUserId: currentUserId, requestedSpots: this.props.requestedSpots }
            )
            console.log('Updating listings ' + tripId)
            console.log(currentRequestedGuests)
            this.props.updateData("listings", tripId, "requestedGuests", currentRequestedGuests)

            if (this.props.data('users', this.state.listingData['tripOrganizerId'])['newMessages']) {
                this.props.request('notification', {
                    category: 'update',
                    type: 'JoinRequestOrganizer',
                    uid: this.state.listingData['tripOrganizerId']
                })
            }
            return (true)
        } else {
            console.log('Not logged in')
        }

    }

    topUp(payment, nonce){
        if (this.state.userData && this.state.listingData) {
            //1) Write to Charge Request as a top-up
            let data = {
                organizer: this.state.listingData.tripOrganizerId,
                tripId: this.props.tripId,
                guest: this.props.firebase.auth().currentUser.uid,
                type: 'topup',
                amount: payment,
                nonce: nonce,
                timestamp: Math.floor(Date.now()/1000),
                status: 'pending'
            }
            console.log(data)
            console.log(this.props.tripId)
            this.props.request('payment', data)
            
        return (true)
        } else {
            console.log('Not logged in')
        }
    }

    renderContent() {
        if (this.props.viewId === '1') {
            return (
                <Payment
                    firebase={this.props.firebase}
                    currentUser={this.props.currentUser.uid}
                    userData={this.state.userData}
                    listingData={this.state.listingData}
                    tripId={this.props.tripId}
                    requestedSpots={this.props.requestedSpots}
                    request={this.props.request}
                    data={this.props.data}
                    updateData={this.props.updateData}
                    writeData={this.props.writeData}
                    emptyCache={this.props.emptyCache}
                    formatDateRange={this.props.formatDateRange}
                    joinTrip={this.joinTrip}
                ></Payment>
            )
        } else if (this.props.viewId === '2') {
            return (
                <Confirmation
                    firebase={this.props.firebase}
                    currentUser={this.props.currentUser.uid}
                    userData={this.state.userData}
                    listingData={this.state.listingData}
                    tripId={this.props.tripId}
                    requestedSpots={this.props.requestedSpots}
                    data={this.props.data}
                    updateData={this.props.updateData}
                    writeData={this.props.writeData}
                    emptyCache={this.props.emptyCache}
                    formatDateRange={this.props.formatDateRange}
                    joinTrip={this.joinTrip}
                ></Confirmation>
            )
        } else if (this.props.viewId === '3') {
            return (
                <Topup
                    firebase={this.props.firebase}
                    currentUser={this.props.currentUser.uid}
                    userData={this.state.userData}
                    listingData={this.state.listingData}
                    tripId={this.props.tripId}
                    amount={this.props.requestedSpots.split("=")[1].replace(/\$/g, '')}
                    request={this.request}
                    data={this.props.data}
                    updateData={this.props.updateData}
                    writeData={this.props.writeData}
                    emptyCache={this.props.emptyCache}
                    formatDateRange={this.props.formatDateRange}
                    topUp={this.topUp}
                ></Topup>
            )
        } else {
            return (
                <Loading notFound={true}></Loading>
            )
        }
    }

    render() {
        if (!this.props.firebase.auth().currentUser){
            return (
                <Redirect push='true' to="/login"></Redirect>
            )
        }
        if (this.props.firebase.auth().currentUser && this.state.userData && this.state.listingData) { //TODO
            return (
                <div>
                    <NavBar
                        firebase={this.props.firebase}
                        data={this.props.data}
                        view=""
                        emptyCache={this.props.emptyCache}
                    ></NavBar>
                    {this.renderContent()}
                </div>
            )
        } else {
            return (
                <Loading></Loading>
            )
        }

    }
}

export default PaymentRoute;
