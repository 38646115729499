import React, { Component } from 'react';

import back from '../../images/back.svg';
import logo from '../../images/logo.svg';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class retrievePw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, label) {
        if (label === 'back') {
            window.history.back()
        } else if (label === 'reset') {
            //CHECK EMAIL IS VALID
            if ((this.state.email.includes(".")) && (this.state.email.includes("@"))) {               
                this.props.firebase.auth().sendPasswordResetEmail(this.state.email).then(
                    () => {
                        this.setState({
                            resetButton: true,
                            emailInvalid: false
                        })
                    }
                ).catch(
                    (err) => {
                        this.setState({
                            emailInvalid: true
                        })    
                        console.log(err)
                    }
                )
            } else {
                this.setState({
                    emailInvalid: true
                })
            }
        } else if (label === 'email') {
            this.setState({
                email: event.target.value
            })
        }
    }

    render() {
        return (
            <body>
                <section className='log-page'>
                    <section className='log-content'>
                        <div className='log-logo'><img src={logo} /></div>
                        <div className='log-header'>Please enter the email<br />associated with your account</div>
                        <div className='log-field-container'>
                            <div className='log-input-container'>
                                <div className='log-navigation'><img src={back} onClick={(e) => this.handleChange(e, 'back')} /></div>
                                <input type='text' placeholder='Enter your email address' onChange={(e) => this.handleChange(e, 'email')} value={this.state.email} />
                                <div id="recaptchaContainer"></div>
                                <div className='log-navigation'>&#160;</div>
                            </div>
                        </div>
                        {this.state.resetButton && <div className='valid-field'>A link is on it's way!</div>}
                        {this.state.emailInvalid && <div className='incorrect-field'>Incorrect Email</div>}
                        {!this.state.resetButton && <div className='log-send-link-btn btn-one' onClick={(e) => this.handleChange(e, 'reset')}>Send Link</div>}
                        <div className='log-footer-area'>
                            <div className='log-footer'>We will send you a link to reset your password.</div>
                        </div>
                    </section>
                </section>
            </body>
        )
    }
}

export default retrievePw;