import React, {Component} from 'react';

import exit from '../../images/exit.svg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";



class DeleteConfirmation extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(label) {
        console.log(label);
        if (label === 'displayDelete') {
            this.props.setDisplayDeleteConfirmation(true);
        } else if (label === 'undisplayDelete') {
            this.props.setDisplayDeleteConfirmation(false);
        } else if (label === 'delete') {
            this.props.handleDelete();
        }
    }

    render() {
        return(
            <body>
                <div className='od-cancel-page'>
                    <div className='od-cancel-container'>
                        <div className='od-cancel-title'>Are you sure you want to cancel this trip?</div>
                        <div className='od-cancel-text'>All reservations under this listing<br/> will be canceled and refunded</div>
                        <div className='od-cancel-btn-row'>
                            <div className='btn-three' onClick={() => this.handleClick('undisplayDelete')}>Cancel</div>
                            <div className='btn-one' onClick={() => this.handleClick('delete')}>Confirm</div>
                        </div>
                        <div className='exit' onClick={() => this.handleClick('undisplayDelete')}><img src={exit}/></div>
                    </div>
                </div>
            </body>
        )   
    }
}

export default DeleteConfirmation;