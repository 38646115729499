import React, { Component } from 'react'
import '../../css/index.css'
import Geocode from 'react-geocode'
/* global google */

class autoComplete extends Component { 
    constructor(props) {
        super(props)
        this.autoCompleteInput = React.createRef()
        this.autocomplete = null
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
    }

    componentDidMount() {
        this.autocomplete = new google.maps.places.Autocomplete(this.autoCompleteInput.current,
            {"types": ["geocode"]})
        
        this.autocomplete.addListener('place_changed', this.handlePlaceChanged)

        if (this.props.ongoingAddress) {
            document.getElementById("autocomplete").value = this.props.ongoingAddress[0]
        }

        // if (this.props.dragAddress) {
        //     // document.getElementById("autocomplete").value = this.props.dragAddress
        //     alert('hello')
        //     console.log(document.getElementById("autocomplete").innerHTML)
        // }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dragAddress !== this.props.dragAddress) {
            document.getElementById("autocomplete").value = this.props.dragAddress
            

            // Geocode.fromAddress(this.props.dragAddress).then(
            //     response => {
            //         const { lat, lng } = response.results[0].geometry.location
            //         console.log(lat, lng)
            //     },
            //     error => {
            //         console.error(error)
            //     }
            // )



            // const place = this.autocomplete.getPlace()
            // if (place && place.geometry) {
            //     console.log('yeet')
            //     this.props.onPlaceLoaded(place)
            // }
        }
    }

    handlePlaceChanged() {
        
        const place = this.autocomplete.getPlace()
        if (place && place.geometry) {
            
            this.props.onPlaceLoaded(place)
            this.props.coordCallBack(place.geometry.location.lat(), place.geometry.location.lng())
        }
    }

    render () {
        return (
            <input className="cl-map-address-input" ref={this.autoCompleteInput} id="autocomplete" placeholder="Enter your address" type="text"></input>
        )
    }
}

export default autoComplete