import React, {Component} from 'react';
import Planets from '../../images/Planets.gif';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Loading from '../Loading';

class Confirmation extends Component{

    render() {
        if (this.props.tripId && this.props.listingData) {
            return(
                <div>
                    <head>
                        <meta charSet='UTF-8'/>
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                        <title>Payment Confirmation</title>
                    </head>
                    <body>
                        <section className='log-page'>
                            <section className='log-content'>
                                <div className='pmt-graphic'><img src={Planets}/></div>
                                <div className='log-header'>Your request has been sent!</div>
                                <div className='log-subheader-text' style={{textAlign:'center', marginTop:'0px'}}>The organizer will be in contact with you soon.</div>
                                <div className='ld-btn-container'>
                                    <Link push to={"/myTripsDetails/"+ this.props.tripId+"/"+this.props.listingData.tripOrganizerId}>
                                    <button className="btn-one ld-btn">
                                        My Trip Details
                                    </button>
                                    </Link>
                                </div>
                            </section>
                        </section>
                    </body>
                </div>
            ) 
        } else {
            return (
                <Loading></Loading>
            )
        }
    }  
}

export default Confirmation;