import React, {Component} from 'react';

//import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import NavBarLoggedIn from './NavBar.js';
import NavBarLanding from './NavBarLanding.js';
import NavBarLoggedOut from './NavBarPrelogin.js';

//cant go to landing page when logged in

class NavBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.view === "Landing") {
            return (
                <NavBarLanding></NavBarLanding>
            )
        } else if (this.props.view === "None") {
            return (
                <div></div>
            )
        } else {
            if ((this.props.firebase.auth().currentUser) && (this.props.data('users', this.props.firebase.auth().currentUser.uid))) {
                return (
                    <NavBarLoggedIn
                        firebase = {this.props.firebase}
                        data = {this.props.data}
                        emptyCache = {this.props.emptyCache}
                    ></NavBarLoggedIn>
                )
            } else {
                return (
                    <NavBarLoggedOut
                        firebase = {this.props.firebase}
                    ></NavBarLoggedOut>
                )
            }
        }
    }
}

export default NavBar;