import React, {Component} from 'react';
import twitchcon from '../../images/twitchcon2019.jpg';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class MapModal extends Component {
    
    render() {
        return(
            <div className='ex-modal'>
                <div className='ex-modal-details'>Event Location</div>
                
            </div>
        )   
    }
}

export default MapModal;