import React, {Component} from 'react';
import preloader from '../../images/preloader.gif';
import NotFound from './Error.js';


class Loading extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.notFound) {
            return (
                <NotFound></NotFound>
            )
        } else {
            return (
                <div className='preloader'>
                    <img src={preloader}/>
                </div>
            )
        }
    }
}

export default Loading;