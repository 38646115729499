import React, { Component } from 'react'
import firebase from '../Firebase'
import '../../css/index.css'
import profile from '../../images/profile.jpg';
import decline from '../../images/decline.svg';
import accept from '../../images/accept.svg';
import pending from '../../images/pending.gif';
import DeleteConfirmation from './DeleteConfirmation.js';
import NumberFormat from "react-number-format"
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Loading from '../Loading';


class OrganizerView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            listingData: null
        }

        this.countReservedSpots = this.countReservedSpots.bind(this)

        this.renderCancelUsers = this.renderCancelUsers.bind(this)
        this.renderRequestedUsers = this.renderRequestedUsers.bind(this)
        this.renderReservedUsers = this.renderReservedUsers.bind(this)

        //cancelAcceptGuest
        this.cancelRequestAccept = this.cancelRequestAccept.bind(this)

        //cancelDeclineGuest
        this.cancelRequestReject = this.cancelRequestReject.bind(this)

        //JoinAccepGuest
        this.joinRequestAccept = this.joinRequestAccept.bind(this)

        //joinDeclineGuest
        this.joinRequestReject = this.joinRequestReject.bind(this)

        //reservationCancelGuest
        this.reservationCancel = this.reservationCancel.bind(this)

        this.stripeRedirect = this.stripeRedirect.bind(this)

        this.findPaidAmount = this.findPaidAmount.bind(this)

        this.renderOnboardButton = this.renderOnboardButton.bind(this)

        this.renderPayoutButton = this.renderPayoutButton.bind(this)
    }

    componentDidMount() {

        this.setState({
            listingData: this.props.listingData,
            enableCancel: (Date.now() <= Date.parse(this.props.listingData.toDate)),
            payoutAmount: this.props.getPayoutAmount()
        })
        if (Date.now() > new Date(this.props.listingData.toDate).getTime() + 86400000) {  //1 Day after listing complete
            this.setState({
                payoutEnabled: true
            })
        } else {
            this.setState({
                payoutEnabled: false
            })
        }
        let userInfo = this.props.data('users', this.props.firebase.auth().currentUser.uid)
        if (userInfo) {
            if (userInfo.stripeId) {
                this.setState({
                    onboardEnable: false
                })
            } else {
                this.setState({
                    onboardEnable: true
                })
            }
        }
    }

    countReservedSpots() {
        var reservedSpots = 0
        if (this.state.listingData) {
            if (this.state.listingData.reservedGuests.length > 0) {
                this.state.listingData.reservedGuests.map((reservedData) => {
                    reservedSpots += parseInt(reservedData.reservedSpots, 10)
                })
            }

            console.log(reservedSpots)
            return reservedSpots
        }
    }

    async reservationCancel(guest) { //FIX -- WHAT HAPPENS IF PARTIAL CANCEL?
        this.props.enableLoading()
        console.log(guest)
        var cancelledGuest = ""
        var newAvailableSpots = this.state.listingData.availableSpots
        var newReservedGuests = this.state.listingData.reservedGuests.filter(function (reserveData) {
            if (reserveData.reservedUserId == guest) {
                cancelledGuest = reserveData.reservedUserId
                console.log(reserveData.reservedSpots)
                newAvailableSpots += parseInt(reserveData.reservedSpots, 10)
            } else {
                return reserveData
            }
        })

        var requestData = {
            action: 'cancelUpdateMyTrips',
            tripId: this.props.tripId,
            organizerId: this.props.firebase.auth().currentUser.uid,
            timestamp: Math.floor(Date.now() / 1000),
            cancelledGuest: cancelledGuest
        }
        console.log(requestData)
        

        //Submit refund request to server
        let data = {
            organizer: this.props.firebase.auth().currentUser.uid,
            tripId: this.props.tripId,
            guest: guest,
            type: 'organizerCancel',
            timestamp: Math.floor(Date.now() / 1000),
            status: 'pending'
        }
        console.log(data)
        console.log(this.props.tripId)
        let refundRequest = await this.props.request('refund', data)
        if (refundRequest.status === 'succeeded'){
            this.props.emptyCache('refund_confirmation');
            this.props.emptyCache('charge_confirmation');
            this.setState({payoutAmount: this.props.getPayoutAmount()})
        } else {
            alert(JSON.stringify(refundRequest.status))
            return
        }
        
        this.props.request('update', requestData)
        this.props.updateData("listings", this.props.tripId, "availableSpots", newAvailableSpots)
        this.props.updateData("listings", this.props.tripId, "reservedGuests", newReservedGuests)

        if (this.props.data('users', guest)['newMessages']) {
            this.props.request('notification', {
                category: 'update',
                type: 'ReservationCancelGuest',
                uid: guest
            })
        }
        this.props.disableLoading()
    }

    async joinRequestReject(guest) {
        this.props.enableLoading()
        console.log(guest)
        var rejectedGuestId = null
        var newJoinRequested = this.state.listingData.requestedGuests.filter(function (requestData) {
            if (requestData.requestedUserId !== guest) {
                return requestData
            } else (
                rejectedGuestId = requestData.requestedUserId
            )
        })

        var requestData = {
            action: 'cancelUpdateMyTrips',
            tripId: this.props.tripId,
            organizerId: this.props.firebase.auth().currentUser.uid,
            timestamp: Math.floor(Date.now() / 1000),
            cancelledGuest: rejectedGuestId
        }
        console.log(requestData)
        this.props.request('update', requestData)
        this.props.updateData("listings", this.props.tripId, "requestedGuests", newJoinRequested)

        if (this.props.data('users', guest)['newMessages']) {
            this.props.request('notification', {
                category: 'update',
                type: 'JoinDeclineGuest',
                uid: guest
            })
        }
        this.props.disableLoading()
    }

    async joinRequestAccept(guest) {
        this.props.enableLoading()
        console.log(guest)
        var remainingSpots = this.state.listingData.availableSpots
        var currReservedGuests = this.state.listingData.reservedGuests
        var spots = 0
        var newJoinRequested = this.state.listingData.requestedGuests.filter(function (requestData) {
            if (requestData.requestedUserId == guest) {

                var reservedSpots = parseInt(requestData.requestedSpots, 10)
                spots = reservedSpots
                console.log(reservedSpots)
                if (reservedSpots > remainingSpots) {
                    alert("Your listing has no more room available.")
                    return requestData
                } else {
                    remainingSpots -= reservedSpots
                    currReservedGuests.push({
                        reservedSpots: requestData.requestedSpots,
                        reservedUserId: requestData.requestedUserId
                    })

                }

            } else if (requestData.requestedUserId !== guest) {
                return requestData
            }
        }.bind(this))
        console.log(this.state.listingData.requestedGuests)
        console.log(this.state.listingData.reservedGuests)
        
        let data = {
            organizer: this.props.firebase.auth().currentUser.uid,
            tripId: this.props.tripId,
            guest: guest,
            type: 'join',
            spots: spots, //AMOUNT IS IN PRIVATE USER DATA AND IS FETCHED BY SERVER
            timestamp: Math.floor(Date.now() / 1000),
            status: 'pending'
        }
        console.log(data)
        console.log(this.props.tripId)
        let paymentRequest = await this.props.request('payment', data)
        if (paymentRequest.status === 'succeeded'){
            this.props.emptyCache('refund_confirmation');
            this.props.emptyCache('charge_confirmation');
            this.setState({payoutAmount: this.props.getPayoutAmount()})
        } else {
            alert(JSON.stringify(paymentRequest.status))
            return
        }

        this.state.listingData.requestedGuests = newJoinRequested
        this.state.listingData.reservedGuests = currReservedGuests

        this.props.updateData("listings", this.props.tripId, "requestedGuests", newJoinRequested)
        this.props.updateData("listings", this.props.tripId, "reservedGuests", this.state.listingData.reservedGuests)
        this.props.updateData("listings", this.props.tripId, "availableSpots", remainingSpots)

        if (this.props.data('users', guest)['newMessages']) {
            this.props.request('notification', {
                category: 'update',
                type: 'JoinAcceptGuest',
                uid: guest
            })
        }
        this.props.disableLoading()
    }



    cancelRequestReject(guest) {
        this.props.enableLoading()
        console.log(guest)
        var newCancelRequested = this.state.listingData.cancelRequested.filter(function (cancelData) {
            if (cancelData.cancelUserId !== guest) {
                return cancelData
            }
        })

        this.props.updateData("listings", this.props.tripId, "cancelRequested", newCancelRequested)

        if (this.props.data("users", guest)['newMessages']) {
            this.props.request('notification', {
                category: 'update',
                type: 'CancelDeclineGuest',
                uid: guest
            })         
        }
        this.props.disableLoading()
    }

    async cancelRequestAccept(guest) { //FIX -- WHAT HAPPENS IF PARTIAL CANCEL?
        this.props.enableLoading()
        console.log(guest)
        var cancelAcceptUser = ""
        var remainingSpots = this.state.listingData.availableSpots
        var newCancelRequested = this.state.listingData.cancelRequested.filter(function (cancelData) {
            if (cancelData.cancelUserId == guest) {
                remainingSpots += parseInt(cancelData.cancelSpots, 10)
                cancelAcceptUser = cancelData.cancelUserId
            } else {
                return cancelData
            }
        })


        var newReservedGuests = this.state.listingData.reservedGuests.filter(function (reserveData) {
            if (reserveData.reservedUserId !== guest) {
                return reserveData
            }
        })

        var requestData = {
            action: 'cancelUpdateMyTrips',
            tripId: this.props.tripId,
            organizerId: this.props.firebase.auth().currentUser.uid,
            timestamp: Math.floor(Date.now() / 1000),
            cancelledGuest: cancelAcceptUser
        }
        console.log(requestData)


        //Submit refund request to server
        let data = {
            organizer: this.props.firebase.auth().currentUser.uid,
            tripId: this.props.tripId,
            guest: guest,
            type: 'guestCancel',
            timestamp: Math.floor(Date.now() / 1000),
            status: 'pending'
        }
        console.log(data)
        console.log(this.props.tripId)
        let refundRequest = await this.props.request('refund', data)
        if (refundRequest.status === 'succeeded'){
            this.props.emptyCache('refund_confirmation');
            this.props.emptyCache('charge_confirmation');
            this.setState({payoutAmount: this.props.getPayoutAmount()})
        } else {
            alert(refundRequest.status)
            return
        }

        this.props.request('update', requestData)
        this.props.updateData("listings", this.props.tripId, "reservedGuests", newReservedGuests)
        this.props.updateData("listings", this.props.tripId, "cancelRequested", newCancelRequested)
        this.props.updateData("listings", this.props.tripId, "availableSpots", remainingSpots)

        if (this.props.data("users", cancelAcceptUser)['newMessages']) {
            this.props.request('notification', {
                category: 'update',
                type: 'CancelAcceptGuest',
                uid: guest
            })         
        }
        this.props.disableLoading()
    }

    renderReservedUsers() {
        var returnDivs = []

        if (this.state.listingData) {
            if (this.state.listingData.reservedGuests.length == 0) {
                return (
                    <div className='ld-request-none'>
                        No Reserved Guests
                    </div>
                )
            }
            this.state.listingData.reservedGuests.map((reservedData) => {
                var userData = this.props.data("users", reservedData.reservedUserId)

                if (userData) {
                    returnDivs.push(
                        <div className='od-manage-request'>
                            <div className='od-guest-top'>
                                <Link className='od-guest-avatar' push to={"/account?id=" + reservedData.reservedUserId}>
                                    <div className='od-guest-img'><img className='od-guest-img' src={userData.imageUrl} /></div>
                                    <div className='od-guest-details'>
                                        <div className='od-guest-name'>{userData.firstName + " " + userData.lastName}</div>
                                        <div className='od-guest-location'>{userData.location}</div>
                                    </div>
                                </Link>
                                <div className='od-guest-status'>
                                    <div className='join-tag' style={{ marginLeft: 20, marginRight: 10, backgroundColor: "#1BC938" }}>Reserved</div>
                                </div>
                            </div>
                            <div className='od-guest-bottom'>
                                <div className='od-guest-info'>{reservedData.reservedSpots} Spots</div>
                                <div className='od-guest-info'>${this.state.listingData.pricePerSpot.replace(/,/g, '') * reservedData.reservedSpots} Total</div>
                                <div className='od-guest-info'>${this.findPaidAmount(reservedData.reservedUserId)} Paid</div>
                                <div className='od-guest-action'>
                                    {this.state.enableCancel && <button class='btn-four od-guest-cancel-btn' onClick={() => { this.props.setDisplayModal(true, 'organizerCancel', this.reservationCancel, reservedData.reservedUserId) }}>Cancel</button>}
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        }
        return returnDivs
    }

    renderRequestedUsers() {


        var returnDivs = []
        if (this.state.listingData) {
            if (this.state.listingData.requestedGuests.length == 0) {
                return (
                    <div className='ld-request-none'>
                        No pending join requests
                    </div>
                )
            }
            this.state.listingData.requestedGuests.map((requestedData) => {
                var userData = this.props.data("users", requestedData.requestedUserId)



                if (userData) {
                    console.log(userData.firstName)
                    returnDivs.push(
                        <div className='od-manage-request'>
                            <div className='od-guest-top'>
                                <Link className='od-guest-avatar' push to={"/account?id=" + requestedData.requestedUserId}>
                                    <div className='od-guest-img'><img className='od-guest-img' src={userData.imageUrl} /></div>
                                    <div className='od-guest-details'>
                                        <div className='od-guest-name'>{userData.firstName + " " + userData.lastName}</div>
                                        <div className='od-guest-location'>{userData.location}</div>
                                    </div>
                                </Link>

                                <div className='od-guest-status'>
                                    <div className='join-tag' style={{ marginLeft: 20, marginRight: 10, backgroundColor: "#51F26C" }}>Requested</div>
                                </div>
                            </div>
                            <div className='od-guest-bottom'>
                                <div className='od-guest-info'>{requestedData.requestedSpots} Spots</div>
                                <div className='od-guest-info'>${this.state.listingData.pricePerSpot.replace(/,/g, '') * requestedData.requestedSpots} Total</div>
                                <div className='od-guest-action'>
                                    <img class='svg-btn od-action-btn accept' onClick={() => this.joinRequestAccept(requestedData.requestedUserId)} />
                                    <img class='svg-btn od-action-btn decline' onClick={() => this.joinRequestReject(requestedData.requestedUserId)} />
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        }
        return returnDivs
    }

    renderCancelUsers() {

        var returnDivs = []
        if (this.state.listingData) {
            if (this.state.listingData.cancelRequested.length == 0) {
                return (
                    <div className='ld-request-none'>
                        No pending cancel requests
                    </div>
                )
            }
            this.state.listingData.cancelRequested.map((cancelData) => {
                var userData = this.props.data("users", cancelData.cancelUserId)

                if (userData) {
                    returnDivs.push(
                        <div className='od-manage-request'>
                            <div className='od-guest-top'>
                                <Link className='od-guest-avatar' push to={"/account?id=" + cancelData.cancelUserId}>
                                    <div className='od-guest-img'><img className='od-guest-img' src={userData.imageUrl} /></div>
                                    <div className='od-guest-details'>
                                        <div className='od-guest-name'>{userData.firstName + " " + userData.lastName}</div>
                                        <div className='od-guest-location'>{userData.location}</div>
                                    </div>
                                </Link>
                                <div className='od-guest-status'>
                                    {this.state.enableCancel && <div className='join-tag' style={{ marginLeft: 20, marginRight: 10, backgroundColor: "#FF4646" }}>Cancel</div>}
                                </div>
                            </div>
                            <div className='od-guest-bottom'>
                                <div className='od-guest-info'>{cancelData.cancelSpots} Spots</div>
                                <div className='od-guest-info'>${this.state.listingData.pricePerSpot.replace(/,/g, '') * cancelData.cancelSpots} Total</div>
                                <div className='od-guest-action'>
                                    <img class='svg-btn od-action-btn accept' onClick={() => { this.props.setDisplayModal(true, 'organizerGuestCancelApprove', this.cancelRequestAccept, cancelData.cancelUserId) }} />
                                    <img class='svg-btn od-action-btn decline' onClick={() => this.cancelRequestReject(cancelData.cancelUserId)} />
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        }
        return returnDivs
    }

    stripeRedirect() {
        var client_id = 'ca_FL57dJSjzzNOCEToX0sJ541menIJUUJY'
        var redirect_uri = 'http://' + window.location.href.split("//")[1].split("/")[0] + '/myTripsNew/'
        window.location.href = "https://connect.stripe.com/express/oauth/authorize?redirect_uri=" + redirect_uri + "&client_id=" + client_id
    }


    findPaidAmount(guest) {
        if (this.props.firebase.auth().currentUser.uid) {
            let chargedAmount = 0;
            let refundAmount = 0;
            let paymentData = this.props.paymentData
            let refundData = this.props.refundData

            if (paymentData) {
                console.log(paymentData)
                paymentData.forEach((entry) => {
                    if ((entry.guest === guest) && (entry.organizer === this.state.listingData.tripOrganizerId) && (entry.status)) {
                        chargedAmount += parseFloat(entry.amount);
                    }
                })
            }

            if (refundData) {
                console.log(refundData)
                refundData.forEach((entry) => {
                    if ((entry.guest === guest) && (entry.organizer === this.state.listingData.tripOrganizerId) && (entry.status === "succeeded")) {
                        refundAmount += parseFloat(entry.amount);
                    }
                })
            }
            return (chargedAmount - refundAmount)
        } else {
            return 0
        }
    }

    renderOnboardButton() {
        if (this.state.onboardEnable) {
            if (this.props.onboardFlag === 'pending' || this.props.onboardFlag === 'failed') {
                return (
                    <div className='od-edit-btn'>
                        Processing Payout Method... <img src={pending} />
                    </div>
                )
            } else {
                return (
                    <div className='od-edit-btn'>
                        <button disabled={!this.state.onboardEnable} className='btn-one' onClick={() => { this.stripeRedirect() }}>
                            Add Payout Method</button>
                    </div>
                )
            }
        }
    }

    renderPayoutButton() {
        if (this.state.payoutEnabled && (this.props.getPayoutCompleted() === 0)) {
            console.log('here')
            if (this.props.payoutFlag === 'pending' || this.props.payoutFlag === 'failed') {
                return (
                    <div className='od-edit-btn'>
                        Processing Payout... <img src={pending} />
                    </div>
                )
            } else {
                return (
                    <div className='od-edit-btn'>
                        <button disabled={!this.state.payoutEnabled} className='btn-one' onClick={() => { this.props.requestPayout() }}>
                            Request Payout </button>
                    </div>
                )
            }

        }

    }


    render() {
        if (this.state.listingData) {
            return (
                <section className='dash-sidebar'>

                    <section className='trip-status'>

                        {this.props.renderDates()}
                        <div className='trip-info'>
                            <div className='trip-info-row'>
                                <div>Booking Status:</div>
                                <div>
                                    <div className='org-tag'
                                        style={{ backgroundColor: "#3d98ff" }}>
                                        Organizer
                                    </div>
                                </div>
                            </div>
                            <div className='trip-info-row'>
                                <div>Listing ID:</div>
                                <div>{this.props.tripId}</div>
                            </div>
                            <div className='trip-info-row'>
                                <div>Available Spots:</div>
                                <div>{this.state.listingData.availableSpots} spots</div>
                            </div>
                            {/*<div className='trip-info-row'>
                                <div>Available Spots:</div>
                                <div className='od-field'>
                                    <NumberFormat/>
                                </div>
            </div>*/}
                            <div className='trip-info-row'>
                                <div>Price per Spot:</div>
                                <div>${this.state.listingData.pricePerSpot}</div>
                            </div>
                            <div className='trip-info-row'>
                                <div>Total Amount Received:</div>
                                <div> {(this.props.totalFlag === 'pending' || this.props.totalFlag === 'failed') ? <img src={pending} /> : '$' + this.state.payoutAmount}</div>
                            </div>
                            {/* <div className='trip-info-row'>
                                <div>Total Amount Paid Out:</div>
                                <div> {(this.props.payoutFlag === 'pending' || this.props.payoutFlag === 'failed') ? <img src={pending} /> : '$'+this.props.getPayoutCompleted()} </div>
                            </div> */}
                            <div className='od-edit-container'>
                                <Link push to={"/listingedit?id=" + this.props.tripId + '&1'}>
                                {this.state.enableCancel &&<div className='od-edit-btn'>
                                        <div className='btn-five'>Edit listing</div>
                                    </div>}
                                </Link>
                                {/* <div className='od-edit-btn'>
                                    <div className='btn-one'>Save Changes</div>
</div> */}
                                {this.state.enableCancel && <div className='od-edit-btn'>
                                    <div className='btn-five' onClick={() => {
                                        this.props.setDisplayDeleteConfirmation(true)

                                    }}>
                                        Cancel Trip
                                    </div>
                                </div>}
                                {/* {this.renderOnboardButton()}
                                {this.renderPayoutButton()} */}
                            </div>
                        </div>
                    </section>

                    <section className='trip-manage'>

                        <div className='trip-manage-header'>Manage your Trip</div>
                        <div className='trip-manage-area'>

                            {this.renderReservedUsers()}

                        </div>
                    </section>

                    <section className='trip-manage'>
                        <div className='trip-manage-header'>Join Requests</div>
                        <div className='tri-manage-area'>
                            {this.renderRequestedUsers()}

                        </div>
                    </section>

                    <section className='trip-manage'>
                        <div className='trip-manage-header'>Cancel Requests</div>
                        <div className='tri-manage-area'>
                            {this.renderCancelUsers()}
                        </div>
                    </section>
                </section>

            )
        } else {
            return (
                <div>
                    <Loading></Loading>
                </div>
            )
        }
    }
}

export default OrganizerView