import React, { Component } from 'react'
import '../../css/index.css'
//import '../../css/Styles.css'
import back from '../../images/back_arrow.svg'
import DragAndDrop from './DragAndDrop'
import Map from '../Map/index'
import uniqid from 'uniqid'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Dropzone from './DragAndDrop'
import mapPinEvent from '../../images/map-pin-event.svg'



class CListingStage2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imageList: [],
            imageUrlList: [],
            imageDict: {},
            address: [],
            advancePage: false,
            eventCoords: null,
            showDisplay: false,

            filledStatus: {
                imageUrlList: false,
                address: false,
            },
        }

        this.dragAddressCallBack = this.dragAddressCallBack.bind(this)

        this.pullDict = this.pullDict.bind(this);
        this.advancePage = this.advancePage.bind(this);
        
        this.handleDrop = this.handleDrop.bind(this);
        this.uploadImageAsPromise = this.uploadImageAsPromise.bind(this);
        this.imgCallBack = this.imgCallBack.bind(this);
        this.fieldsComplete = this.fieldsComplete.bind(this);

        this.onPlaceLoaded = this.onPlaceLoaded.bind(this);
        this.removeImage = this.removeImage.bind(this);
        //this.handleChange = this.handleChange.bind(this);
        this.renderDisplay = this.renderDisplay.bind(this);
    }

    
    

    componentWillMount() {
        this.pullDict();
        // if (this.props.listingEdit){
        //     let existingImages = this.props.data('listings', this.props.tripId).imageUrlList
        //     let existingImagesEdited = []
        //     let i=0
        //     if (existingImages){
        //         existingImages.forEach((url)=>{
        //             i++
        //             existingImagesEdited[i] = {}
        //             existingImagesEdited[i].preview = url
        //         })
        //     }
        //     this.setState({
        //         imageList: existingImagesEdited
        //     })
        // }
    }

    renderDisplay() {
        if (this.state.showDisplay) {
            var display = "Please fill out: ";
            if (this.state.imageList.length === 0) {
                display += "Images, ";
            }
            if (this.state.imageUploading) {
                display += "Images are uploading please wait until complete, ";
            }
            if (this.state.address.length === 0) {
                display += "Address, ";
            }
            return (
                <div className='incorrect-field'>{display}</div>
            )
        } else {
            return (
                <div className='incorrect-field'>&#168;</div>
            )
        }
    }

    dragAddressCallBack(address, lat, lng) {
        var fullLocation = [address, lat, lng]
        this.setState({address: fullLocation})
    }

    onPlaceLoaded(place) {
        if (place && place.geometry) {

            var fullLocation = [place.formatted_address, place.geometry.location.lat(), place.geometry.location.lng()]
            this.setState({address: fullLocation})
        } else {
            return
        }
    }

    pullDict() {
        this.setState(this.props.currentDict);
    }

    advancePage(path) {
        if (path === "/listingcreate?id=3") {
            if (!this.fieldsComplete()) {
                this.setState({showDisplay: true});
                //alert('not complete fields ui');
            } else {
                this.props.recordValues(this.state);
                this.setState({advancePage: path});
            }
        } else {
            this.props.recordValues(this.state);
            this.setState({advancePage: path});
        }
    }

    fieldsComplete() {
        if ((this.state.imageUrlList.length > 0) && (this.state.address.length > 1) && !this.state.imageUploading) {
            return true;
        }
        return false;
    }

    removeImage(pathName) {
        const value = this.state.imageDict[pathName];
        var imageUrlList = this.state.imageUrlList;
        for (var i in imageUrlList) {
            if (imageUrlList[i] === value) {
                imageUrlList.splice(i, 1);
                //remove binding
                var imageDict = this.state.imageDict;
                delete imageDict[pathName];
                //remove file display
                for (var i in this.state.imageList) {
                    var imageList = this.state.imageList;
                    if (imageList[i].path === pathName) {
                        imageList.splice(i, 1);
                        this.setState({
                            imageList: this.state.imageList,
                            imageDict: this.state.imageDict,
                            imageUrlList: this.state.imageUrlList,
                        });
                        this.props.recordValues(this.state);
                        return;
                    }
                }
                return;
            }
        }
        return;
    }

    imgCallBack(imgFiles) {
        this.handleDrop(imgFiles);
        //this.props.ongoingListingData[this.props.currentUser.uid]["imageList"] = imgFiles
        this.setState({imageList: imgFiles})
    }
    

    uploadImageAsPromise(image) {
        var uploadTask = this.props.firebase.storage().ref().child('users/' + this.props.firebase.auth().currentUser.uid + "/" + uniqid()).put(image);
        this.setState({imageUploading: true})
        uploadTask.on('state_changed', (snapshot) => {
            
        }, (error) => {
            // Handle unsuccessful uploads
        }, () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                var imageUrlList = this.state.imageUrlList;
                imageUrlList.push(downloadURL);
                this.setState({imageUrlList: imageUrlList});
                var imageDict = this.state.imageDict;
                imageDict[image.path] = downloadURL;
                this.setState({imageDict: imageDict});
                this.setState({imageUploading: false})
            });
        });
    }
    

    handleDrop(files) {
        var oldFiles = this.state.imageList;
        var pathNames = [];
        for (var i in oldFiles) {
            pathNames.push(oldFiles[i].path);
        }
        var newFiles = [];
        for (var i in files) {
            if (!pathNames.includes(files[i].path)) {
                newFiles.push(files[i]);
            }
        }
        for (var i = 0; i < newFiles.length; i++) {
            this.uploadImageAsPromise(newFiles[i]);
        }
    }

    render() {
        if (this.state.listingTitle.length === 0) {
            return (
                <Redirect to='/listingcreate?id=1'></Redirect>
            )
        } else if (this.state.advancePage) {
            this.setState({advancePage: false});
            if (this.props.listingEdit){
                return (
                    <Redirect push to={"/listingedit?id="+this.props.tripId+"&3"}></Redirect>
                )
            } else {
                return (
                    <Redirect to={this.state.advancePage}></Redirect>
                )
            }

        } else {
            return (
                <div>
                    <div>
                        <meta charSet='UTF-8'/>
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                        <title>Create Listing 1</title>
                    </div>
                        
                    <div>
                        <div className='cl-step2'>
                            {this.renderDisplay()}
                            <div className="cl-step2-content">
                                {!this.props.listingEdit && <div className='cl-upload-img'>
                                    <div className='cl-label'>What does your place look like?</div>
                                    <Dropzone
                                        imgCallBack = {this.imgCallBack}
                                        existingImgs = {this.state["imageList"]}
                                        removeImage = {this.removeImage}
                                    ></Dropzone>
                                </div>}
                                <div className='cl-upload-location'>
    
                                    <div className='cl-upload-map'>
                                        <div className='cl-label'>Where is your place located?</div>
                                        <div className='cl-subLabel'>Please enter your address below or drag the red marker to the approximate location</div>
                                        
                                        <div className='cl-map-area'>
                                            <div className="cl-map-container">

                                                <Map
                                                    ongoingAddress = {this.state.address}
                                                    data = {this.props.data}
                                                    localData = {this.props.localData}
                                                    currChannel = {this.state.events}
                                                    addressSearch = {true}
                                                    onPlaceLoaded = {this.onPlaceLoaded}
                                                    dragAddressCallBack = {this.dragAddressCallBack}
                                                    // eventCoord = {this.props.data('channels', this.state.events).display.split("<p>")[4]}
                                                ></Map>
                                            </div>
                                        </div>
                                        <div className='cl-map-legend'>
                                            <img style={{width: "30px", height: "30px"}} src={mapPinEvent} />  
                                            <div className='cl-map-legend-description'>= Event Location</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='cl-footer'>
                                <div className='cl-back-btn' onClick={() => this.advancePage("/listingcreate?id=1")}><img src={back} /></div>
                                <button className='btn-one' onClick={() => {

                                    this.advancePage("/listingcreate?id=3")
                                }}>
                                    Preview
                                </button>
                            </div>
                        </div>   
                    </div>
                        
                </div>
    
            )
        }
    }
}

export default CListingStage2