import React, {Component} from 'react';

import next from '../../images/next.svg';
import back from '../../images/back.svg';
import logo from '../../images/logo.svg';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class signup2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smsInput: '',
            nextStepDirection: '/signup?id=2',
        }
        this.handleChange = this.handleChange.bind(this);
        this.validSMS = this.validSMS.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
    }

    handleChange(event, label) {
        if (label === 'sms') {
            this.setState({smsInput: event.target.value});
        } else if (label === 'button') {
            this.props.setSignUpPending(true);
            this.props.verifySMS(this.state.smsInput);
        }
    }

    validSMS(sms) {
        if (sms.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    renderMessage() {
        if (this.props.incorrectSMS) {
            return (
                <div className='incorrect-field'>The code you inputted does not match our records.</div>
            )
        } else {
            return (
                <div className='incorrect-field'>&#160;</div>
            )
        }
    }
    
    render() {
        if (this.props.phone.length === 0) {
            return (
                <Redirect to="/signup?id=1"></Redirect>
            )
        } else if (this.props.tooManyAttempts) {
            return (
                <Redirect to="/signup?id=1"></Redirect>
            )
        } else {
            return(
                <div>
                    <head>
                        <meta charSet='UTF-8'/>
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                        <title>signup2</title>
                    </head>
                    <body>
                        <section className='log-page'>
                            <section className='log-content'>
                                <div className='log-logo'><img src={logo}/></div>
                                <div className='log-header-regular'>Please enter the code<br/>we just texted to<br/><strong>{formatPhoneNumber(this.props.phone)}</strong></div>
                                <div className='log-field-container'>
                                    <div className='log-input-container'>
                                        <div className='log-navigation'><Link to="/signup?id=1"><img src={back}/></Link></div>
                                        <input type='text' placeholder='Enter Verification Code' onChange={(e) => this.handleChange(e, 'sms')}/>
                                        <div id="recaptchaContainer"></div>
                                        <div className='log-navigation'>&#160;</div>
                                    </div>
                                    {this.renderMessage()}
                                    <div className='log-btn btn-one' onClick={(e) => this.handleChange(e, 'button')}>Confirm</div>
                                </div>
                                <div className='log-footer-area'>
                                    <div className='log-footer'>Already have an account? <Link to="/login"><span className='link'>Login</span></Link></div>
                                </div>
                            </section>
                        </section>
                    </body>
                </div>
            )  
        } 
    }
}

export default signup2;