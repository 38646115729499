import React from 'react';
// import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import '../../css/index.css'

export default class Calendar extends React.Component {
  static defaultProps = {
    numberOfMonths: 1,
  };
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.state = this.getInitialState();
  }
  getInitialState() {
    return {
      from: undefined,
      to: undefined,
      touched: false,
    };
  }

  handleClearClick() {
    this.setState(this.getInitialState())
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
    this.setState({touched: true})
  }
  
  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <div className="RangeExample">
        
        {
          from && !to && (this.props.fromCallBack(from.toLocaleDateString()))
        }
        {
          from && to && (
            this.props.fromCallBack(from.toLocaleDateString()),
            this.props.toCallBack(to.toLocaleDateString())
          )
        }
        {
          !from && to && (this.props.toCallBack(to.toLocaleDateString()))
        }
        {
          (this.state.touched === true) &&
          (!from && !to && (this.props.resetCallBack()))
        }
        
        <DayPicker
          className="Selectable"
          numberOfMonths={this.props.numberOfMonths}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
          disabledDays={[
            {
              before: new Date(),
            }
          ]}
        />
        <div>
          <style>{`
            .DayPicker-Day--disabled {
              pointer-events: none;
            }
            .Selectable {
              
              margin-top: 10px;
            }

            .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
              background-color: #d7ebfc !important;
              color: #4a90e2;
            }
            .Selectable .DayPicker-Day {
              border-radius: 0 !important;
            }
            .Selectable .DayPicker-Day--start {
              border-top-left-radius: 50% !important;
              border-bottom-left-radius: 50% !important;
            }
            .Selectable .DayPicker-Day--end {
              border-top-right-radius: 50% !important;
              border-bottom-right-radius: 50% !important;
            }
        `}</style>
        </div>
      </div>
    );
  }
}