import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import example from '../../images/example.jpg';
import profile from '../../images/profile.jpg';
import NavBar from '../Navigation';

import Loading from '../Loading';

class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commentUserId: '',
            comment: '',
            editState: false,
            noGuests: false,
        }
        this.loading = this.loading.bind(this);
        
        this.renderGuests = this.renderGuests.bind(this);
        this.renderGuest = this.renderGuest.bind(this);
        this.renderMessage = this.renderMessage.bind(this);

        this.renderMyMessage = this.renderMyMessage.bind(this);
        this.renderThemMessage = this.renderThemMessage.bind(this);

        this.resolveClick = this.resolveClick.bind(this);
    }

    componentWillMount() {
        if (this.props.data('listings', this.props.viewId)) {
            const orgId = this.props.data('listings', this.props.viewId)['tripOrganizerId'];
            if (orgId === this.props.firebase.auth().currentUser.uid) {
                if (this.props.data('listings', this.props.viewId)['reservedGuests'].length === 0) {
                    this.setState({noGuests: true});
                } else {
                    const otherId = this.props.data('listings', this.props.viewId)['reservedGuests'][0]['reservedUserId'];
                    this.setState({commentUserId: otherId});
                }
            } else {
                this.setState({commentUserId: orgId});
            }
        }
    }

    loading() {
        if (this.props.data('listings', this.props.viewId)) {
            const d = this.props.data('listings', this.props.viewId);
            if (!this.props.data('users', d['tripOrganizerId'])) {
                return true;
            }
            for (var i in d['reservedGuests']) {
                if (!this.props.data('users', d['reservedGuests'][i]['reservedUserId'])) {
                    return true;
                }
            }
        } else {
            return true;
        }
        if (!this.props.data('channels', this.props.data('listings', this.props.viewId)['events'])) {
            return true;
        }
        return false;
    }

    resolveClick(type, id=false) {
        if (type === "LeaveComment") {
            this.setState({commentUserId: id});
        } else if (type === "PostComment") {
            //email notification
            this.props.request('notification', {
                category: 'update',
                type: 'OtherLeaveYouComment',
                uid: this.state.commentUserId
            })
            const date = new Date();
            const dateString = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            var pushData = {};
            pushData['fromId'] = this.props.firebase.auth().currentUser.uid;
            pushData['comment'] = this.state.comment;
            pushData['date'] = dateString;
            pushData['listingId'] = this.props.viewId;
            var reviewList = this.props.data('users', id)['reviews'];
            if (this.state.editState) {
                //remove existing comment
                for (var i in reviewList) {
                    if ((reviewList[i]['fromId'] === this.props.firebase.auth().currentUser.uid) && (reviewList[i]['listingId'] === this.props.viewId)) {
                        reviewList.splice(i, 1);
                    }
                }
            }
            reviewList.push(pushData);
            this.props.updateData('users', id, 'reviews', reviewList);
        } else if (type === "EditComment") {
            console.log(id);
            this.setState({comment: id}); //TODO: state hook
            console.log(this.state.comment);
            this.setState({editState: true});
        }
    }

    renderGuests() {
        const d = this.props.data('listings', this.props.viewId);
        var idList = [d['tripOrganizerId']];
        for (var i in d['reservedGuests']) {
            idList.push(d['reservedGuests'][i]['reservedUserId']);
        }
        for (var i in idList) {
            if (idList[i] === this.props.firebase.auth().currentUser.uid) {
                idList.splice(i, 1);
            }
        }
        if (idList.length === 0) {
            return (
                <div className='review-guest-item'>
                    <div className='review-no-guest-container'>
                        No friends had joined this trip.
                    </div>
                </div>
            )
        } else {
            return (
                <div className='review-guest-list'>
                    {idList.map((userId)=>(this.renderGuest(userId)))}
                </div>  
            )
        }
    }

    renderGuest(userId) {
        const u = this.props.data('users', userId);
        return (
            <div className='review-guest-item'>
                <Link push to={"/account?id=" + userId} className='review-avatar-area'>
                    <img src={u['imageUrl']}></img>
                    <div className='review-avatar-details'>
                        <div className='review-avatar-name'>
                            {u['firstName'] + " " + u['lastName']}
                        </div>
                        <div className='review-avatar-location'>
                            {u['city'] + ", " + u['stateCountry']}
                        </div>
                    </div>
                </Link>
                <div className='review-guest-row'>
                    <div className='btn-one comment-btn' onClick={()=>this.resolveClick('LeaveComment', userId)}>Leave a comment</div>
                </div>
            </div>
        )
    }

    renderMyMessage(my, them) {
        for (var i in them['reviews']) {
            var id = them['reviews'][i]['fromId'];
            if ((them['reviews'][i]['listingId'] === this.props.viewId) && (id === this.props.firebase.auth().currentUser.uid) && !this.state.editState) {
                return (
                    <div className='review-comment-container'>
                        <div className='review-comment-row'>
                            <Link push to={"/account?id=" + id} className='review-avatar-area'>
                                <img src={my['imageUrl']}></img>
                                <div className='review-avatar-details'>
                                    <div className='review-avatar-name'>
                                        {my['firstName'] + " " + my['lastName']}
                                    </div>
                                    <div className='review-avatar-location'>
                                        {my['city'] + ", " + my['stateCountry']}
                                    </div>
                                </div>
                            </Link>
                            <div className='profile-review-info'>
                                <div className='profile-review-timestamp'>
                                    {them['reviews'][i]['date']}
                                </div>
                                <div className='profile-review-event'>
                                    {this.props.data('channels', this.props.data('listings', this.props.viewId)['events'])['display'].split("<p>")[0]}
                                </div>
                            </div>
                        </div>
                        <div className='review-comment-area'>{" " + them['reviews'][i]['comment'] + " "}</div>
                        <div className='review-post-row'>
                            <div className='btn-four comment-btn' onClick={()=>this.resolveClick('EditComment', them['reviews'][i]['comment'])}>Edit</div>
                        </div>
                    </div>
                )
            }
        }
        return (
            <div>
                <div className='review-comment-container'>
                    <div className='review-comment-row'>
                        <Link push to={"/account?id=" + this.props.firebase.auth().currentUser.uid} className='review-avatar-area'>
                            <img src={my['imageUrl']}></img>
                            <div className='review-avatar-details'>
                                <div className='review-avatar-name'>
                                    {my['firstName'] + " " + my['lastName']}
                                </div>
                                <div className='review-avatar-location'>
                                    {my['city'] + ", " + my['stateCountry']}
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='review-comment-area'>
                        <textarea onChange={(e)=>this.setState({comment: e.target.value})} placeholder='Describe your experience or leave a comment.'/>
                    </div>
                    <div className='review-post-row'>
                        <div className='btn-one comment-btn' onClick={()=>this.resolveClick('PostComment', this.state.commentUserId)}>Post</div>
                    </div>
                </div>
            </div>
        )
    }

    renderThemMessage(my, them) {
        for (var i in my['reviews']) {
            var id = my['reviews'][i]['fromId'];
            if ((my['reviews'][i]['listingId'] === this.props.viewId) && (id === this.state.commentUserId)) {
                const u = this.props.data('users', my['reviews'][i]['fromId']);
                return (
                    <div className='review-comment-container'>
                        <div className='review-comment-row'>
                            <Link push to={"/account?id=" + my['reviews'][i]['fromId']} className='review-avatar-area'>
                                <img src={u['imageUrl']}></img>
                                <div className='review-avatar-details'>
                                    <div className='review-avatar-name'>
                                        {u['firstName'] + " " + u['lastName']}
                                    </div>
                                    <div className='review-avatar-location'>
                                        {u['city'] + ", " + u['stateCountry']}
                                    </div>
                                </div>
                            </Link>
                            <div className='profile-review-info'>
                                <div className='profile-review-timestamp'>
                                    {my['reviews'][i]['date']}
                                </div>
                                <div className='profile-review-event'>
                                {this.props.data('channels', this.props.data('listings', this.props.viewId)['events'])['display'].split("<p>")[0]}
                                </div>
                            </div>
                            
                        </div>
                        <div className='review-comment-area'>{" " + my['reviews'][i]['comment'] + " "}</div>
                    </div>
                )
            }
        }
        return (
            <div>
                <div className='review-no-comment-container'>
                    {them['firstName'] + " " + them['lastName'] + " has not left a comment for you yet."}
                </div>
            </div>
        )
    }

    renderMessage() {
        if (this.props.data('listings', this.props.viewId)['reservedGuests'].length === 0) {
            return (
                <div className='review-comments-section'>
                    <div className='review-no-comment-container'>
                        No friends had joined this trip.
                    </div>
                </div>
            )
        } else if (this.state.commentUserId === '') {
            return (
                <div>SELECT SOMEONE (this should not ever happen)</div>
            )
        } else {
            const u = this.props.data('users', this.state.commentUserId);
            const uu = this.props.data('users', this.props.firebase.auth().currentUser.uid);
            return (
                <section className='review-comments-section'>
        
                    {this.renderThemMessage(uu, u)}
                    {this.renderMyMessage(uu, u)}
                    
                    
                    
                </section>
            )
        }
    }
    
    render() {
        if (this.loading()) {
            return (
                <Loading></Loading>
            )
        } else {
            const d = this.props.data('listings', this.props.viewId);
            const from = this.props.formatDateRange(d['fromDate'], d['toDate'], 'from');
            const to = this.props.formatDateRange(d['fromDate'], d['toDate'], 'to');
            return (
                <div>
                    <NavBar
                        firebase = {this.props.firebase}
                        data = {this.props.data}
                        view = ""
                        emptyCache = {this.props.emptyCache}
                    ></NavBar>
                    <div className='review-page'>
                        <section className='review-sidebar'>
                            <div className='review-trip-details'>
                                <img src={d['imageUrlList'][0]}/>
                                <div className='review-title'>
                                    {d['listingTitle']}
                                </div>
                                <div className='review-date-section'>
                                    <div className='review-date-row'>
                                        <div>Arrived:</div>
                                        <div>{from}</div>
                                    </div>
                                    <div className='review-date-row'>
                                        <div>Departed:</div>
                                        <div>{to}</div>
                                    </div>
                                </div>
                            </div>
                            {this.renderGuests()}              
                        </section>
                        {this.renderMessage()}
                    </div>
                </div>
            )
        }
    }
}

export default Review;