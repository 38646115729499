import React, {Component} from 'react';
import error from '../../images/error.svg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


class Error extends Component {

    render() {
        return (
            <div className='error-page'>
                <div className='error-container'>
                    <img src={error}/>
                    <div className='error-header'>Oops!</div>
                    <div className='error-text'>We can't seem to find the page<br/>you're looking for!</div>
                    <Link to='/'><div className='error-btn btn-one'>Home</div></Link>
                </div>
            </div>
            
        )
    }
}

export default Error;