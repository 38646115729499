
import React, {Component} from 'react';

import coachella2020 from '../../images/coachella2020.jpg';
import twitchcon2019 from '../../images/twitchcon2019.jpg';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import NavBar from '../Navigation';
import Loading from '../Loading';
import Private from '../../images/private.svg';

class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }  
    }

    
    render() {
        if (!this.props.data('channels', 'mytravelfam') 
        || !this.props.data('channels', 'ultra_miami_2020') 
        || !this.props.data('channels', 'coachella_indio_2020') 
        || !this.props.data('channels', 'blizzcon_anaheim_2019') 
        || !this.props.data('channels', 'paxsouth_sanantonio_2020') 
        || !this.props.data('channels', 'paxeast_boston_2020') 
        || !this.props.data('channels', 'e3_losangeles_2020') 
        || !this.props.data('channels', 'twitchcon_sandiego_2020') 
        || !this.props.data('channels', 'snow_gnarnia_2020') 
        || !this.props.data('channels', 'testing') 
        || !this.props.data('users', this.props.firebase.auth().currentUser.uid)) {
            return (
                <Loading></Loading>
            )
        } else {
            return(
                <div>
                    <head>
                        <meta charSet='UTF-8'/>
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                        <title>Events</title>
                    </head>
                    <body>
                        <NavBar
                            firebase = {this.props.firebase}
                            data = {this.props.data}
                            view = ""
                            emptyCache = {this.props.emptyCache}
                        ></NavBar>
                        <section className='ev-page'>

                            {/*<section className='ev-section'>
                                <div className='ev-section-header'>
                                    My TravelFam
                                    <img src={Private}/>
                                </div>
                                <div className='ev-section-content fadein'>
                                    <Link className='ev-card-container' to={"/events?id="+"mytravelfam"} onClick={() => this.props.emptyCache('listings')}>
                                        <div className='ev-card-img'><img src={this.props.data('channels', 'mytravelfam').display.split("<p>")[3]}/></div>
                                        <div className='ev-card-info'>
                                            <div className='ev-card-title'>{this.props.data('channels', 'mytravelfam').display.split("<p>")[0]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'mytravelfam').display.split("<p>")[1]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'mytravelfam').display.split("<p>")[2]}</div>
                                        </div>
                                    </Link>
                                </div>
                            </section>*/}

                        
                            
                            <section className='ev-section fadein'>
                                <div className='ev-section-header'>Music Festivals</div>
                                <div className='ev-section-content'>
                                    <Link className='ev-card-container' to={"/events?id="+"ultra_miami_2020"}>
                                        <div className='ev-card-img'><img src={this.props.data('channels', 'ultra_miami_2020').display.split("<p>")[3]}/></div>
                                        <div className='ev-card-info'>
                                            <div className='ev-card-title'>{this.props.data('channels', 'ultra_miami_2020').display.split("<p>")[0]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'ultra_miami_2020').display.split("<p>")[1]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'ultra_miami_2020').display.split("<p>")[2]}</div>
                                        </div>
                                    </Link>

                                    <Link className='ev-card-container' to={"/events?id="+"coachella_indio_2020"}>
                                        <div className='ev-card-img'><img src={this.props.data('channels', 'coachella_indio_2020').display.split("<p>")[3]}/></div>
                                        <div className='ev-card-info'>
                                            <div className='ev-card-title'>{this.props.data('channels', 'coachella_indio_2020').display.split("<p>")[0]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'coachella_indio_2020').display.split("<p>")[1]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'coachella_indio_2020').display.split("<p>")[2]}</div>
                                        </div>
                                    </Link>
                                </div>
                            </section>
    
                            <section className='ev-section fadein'>
                                <div className='ev-section-header'>Conventions</div>
                                <div className='ev-section-content'>

                                    {/*<Link className='ev-card-container' to="/events?id=paxsouth_sanantonio_2020">
                                        <div className='ev-card-img'><img src={this.props.data('channels', 'paxsouth_sanantonio_2020').display.split("<p>")[3]}/></div>
                                        <div className='ev-card-info'>
                                            <div className='ev-card-title'>{this.props.data('channels', 'paxsouth_sanantonio_2020').display.split("<p>")[0]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'paxsouth_sanantonio_2020').display.split("<p>")[1]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'paxsouth_sanantonio_2020').display.split("<p>")[2]}</div>
                                        </div>
                                    </Link>

                                    <Link className='ev-card-container' to="/events?id=paxeast_boston_2020">
                                        <div className='ev-card-img'><img src={this.props.data('channels', 'paxeast_boston_2020').display.split("<p>")[3]}/></div>
                                        <div className='ev-card-info'>
                                            <div className='ev-card-title'>{this.props.data('channels', 'paxeast_boston_2020').display.split("<p>")[0]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'paxeast_boston_2020').display.split("<p>")[1]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'paxeast_boston_2020').display.split("<p>")[2]}</div>
                                        </div>
                                    </Link>

                                    <Link className='ev-card-container' to="/events?id=e3_losangeles_2020">
                                        <div className='ev-card-img'><img src={this.props.data('channels', 'e3_losangeles_2020').display.split("<p>")[3]}/></div>
                                        <div className='ev-card-info'>
                                            <div className='ev-card-title'>{this.props.data('channels', 'e3_losangeles_2020').display.split("<p>")[0]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'e3_losangeles_2020').display.split("<p>")[1]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'e3_losangeles_2020').display.split("<p>")[2]}</div>
                                        </div>
                                    </Link>*/}

                                    <Link className='ev-card-container' to="/events?id=twitchcon_sandiego_2020">
                                        <div className='ev-card-img'><img src={this.props.data('channels', 'twitchcon_sandiego_2020').display.split("<p>")[3]}/></div>
                                        <div className='ev-card-info'>
                                            <div className='ev-card-title'>{this.props.data('channels', 'twitchcon_sandiego_2020').display.split("<p>")[0]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'twitchcon_sandiego_2020').display.split("<p>")[1]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'twitchcon_sandiego_2020').display.split("<p>")[2]}</div>
                                        </div>
                                    </Link>

                                    {/*}Link className='ev-card-container' to="/events?id=testing">
                                        <div className='ev-card-img'><img src={this.props.data('channels', 'testing').display.split("<p>")[3]}/></div>
                                        <div className='ev-card-info'>
                                            <div className='ev-card-title'>{this.props.data('channels', 'testing').display.split("<p>")[0]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'testing').display.split("<p>")[1]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'testing').display.split("<p>")[2]}</div>
                                    </div>
                                    </Link>*/}

                                </div>
                            </section> 

                            <section className='ev-section fadein'>
                                <div className='ev-section-header'>Outdoors</div>
                                <div className='ev-section-content'>

                                    <Link className='ev-card-container' to="/events?id=snow_gnarnia_2020">
                                        <div className='ev-card-img'><img src={this.props.data('channels', 'snow_gnarnia_2020').display.split("<p>")[3]}/></div>
                                        <div className='ev-card-info'>
                                            <div className='ev-card-title'>{this.props.data('channels', 'snow_gnarnia_2020').display.split("<p>")[0]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'snow_gnarnia_2020').display.split("<p>")[1]}</div>
                                            <div className='ev-card-text'>{this.props.data('channels', 'snow_gnarnia_2020').display.split("<p>")[2]}</div>
                                        </div>
                                    </Link>

                                </div>
                            </section> 
                            
                        </section>
                    </body>
                </div>
            )   
        }
    }
}

export default Events;