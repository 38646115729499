import React, { Component } from 'react';

import next from '../../images/next.svg';
import back from '../../images/back.svg';
import logo from '../../images/logo.svg';
import pending from '../../images/pending.gif';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { isValidPhoneNumber } from 'react-phone-number-input';

import Loading from '../Loading';

const inputStyle = {
    display: "table",
    margin: "0 auto",
    marginTop: "30px",

}

class invitation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invalidPhone: false,
            nextStepDirection: '/invitation',
            pNum: '',
            message: 'none',
        }
        this.handleChange = this.handleChange.bind(this);
        this.evaluatePhone = this.evaluatePhone.bind(this);
        this.renderValidMessage = this.renderValidMessage.bind(this);
    }

    componentWillMount() {
        this.props.setPhone('');
        this.props.setSignUpPending(false);
    }

    handleChange(event, label) {
        if (label === 'button') {
            this.evaluatePhone(this.state.pNum);
        }
    }

    renderValidMessage() {
        if (this.state.invalidPhone) {
            return (
                <div className='incorrect-field'>Please enter a valid phone number</div>
            )
        } else if (this.props.tooManyAttempts) {
            return (
                <div className='incorrect-field'>Too many attempts, try again later</div>
            )
        } else {
            return (
                <div className='incorrect-field'>&#160;</div>
            )
        }
    }

    async evaluatePhone(phone) {
        if (isValidPhoneNumber(phone)) {
            document.getElementById('log-next-button').src = pending
            let phonePromise = this.props.checkPhoneExists(phone)
            let betaPromise = this.props.checkBetaKeys(phone)
            var betaKeysCheck = await betaPromise
            var phoneCheck = await phonePromise
            if (this.props.tooManyAttempts) {
                this.setState({ message: 'tooManyAttempts' });
            } else if (!betaKeysCheck) {
                this.props.setPhone(this.state.pNum);
                this.setState({ nextStepDirection: '/signup?id=2b' });
            } else if (phoneCheck) {
                this.props.setPhone(this.state.pNum);
                this.setState({ nextStepDirection: '/signup?id=2a' });
            } else {
                this.props.evaluatePhone(this.state.pNum);
                this.setState({ nextStepDirection: '/signup?id=2' });
            }
        } else {
            this.setState({ invalidPhone: true });
            this.setState({ nextStepDirection: '/invitation' });
        }
    }

    render() {
        if ((this.state.nextStepDirection === '/signup?id=2') || (this.state.nextStepDirection === '/signup?id=2a') || (this.state.nextStepDirection === '/signup?id=2b')) {
            return (
                <Redirect push to={this.state.nextStepDirection}></Redirect>
            )
        } else {
            return (
                <div>
                    <head>
                        <meta charSet='UTF-8' />
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />
                        <title>Signup1</title>
                    </head>
                    <body>
                        <section className='log-page'>
                            <section className='log-content'>
                                <div className='log-logo'><img src={logo} /></div>
                                <div className='log-header'>Congratulations! You've been invited<br/> to join the TravelFam Network</div>
                                <div className='log-subheader'>Please enter your<br/>phone number to continue</div>
                                <div className='log-field-container'>
                                    <div className='log-input-container'>
                                        <div className='log-navigation'>&#160;</div>
                                        <div className='log-phone-input'>
                                            <PhoneInput style={{ textDecoration: "none" }} country="US" flags={flags} placeholder="Enter your phone #" value={this.state.pNum} onChange={pNum => this.setState({ pNum })}></PhoneInput>
                                        </div>
                                        <div><img id='log-next-button' className='log-navigation' src={next} onClick={(e) => this.handleChange(e, 'button')} /></div>
                                        <div id="recaptchaContainer"></div>
                                    </div>
                                </div>
                                <div className='log-footer-area'>
                                    {this.renderValidMessage()}
                                    <div className='log-footer'>We will send you an SMS text message to verify your phone number.</div>
                                    <div className='log-footer'>Already have an account? <Link to="/login"><span className='link'>Login</span></Link></div>
                                </div>
                            </section>
                        </section>
                    </body>
                </div>
            )
        }
    }
}

export default invitation;