import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";


class payout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collectedAmount: 0,
            unavailableAmount: 0,
            payoutAmount: 0,
            payoutRequested: false
        }
        this.getTrips = this.getTrips.bind(this);
        this.getPayoutAmount = this.getPayoutAmount.bind(this);
        this.requestPayout = this.requestPayout.bind(this);
    }

    componentDidMount() {
        if (this.props.firebase.auth().currentUser.uid) {
            let payoutVariables
            let trips = this.getTrips(this.props.firebase.auth().currentUser.uid)
            if (trips) {
                payoutVariables = this.getPayoutAmount(trips)
            }
            if (payoutVariables) {
                this.setState({
                    collectedAmount: payoutVariables[0],
                    unavailableAmount: payoutVariables[1],
                    payoutAmount: payoutVariables[2],
                })
            }
            let userData = this.props.data('users', this.props.firebase.auth().currentUser.uid)
            if (userData){
                this.setState({payoutRequested: userData.payoutRequested})
            }
        }
    }

    getTrips(user) {
        if (this.props.firebase.auth().currentUser.uid === user) {
            let userData = this.props.data('users', user)
            if (userData) {
                return userData.myTrips
            }
        }
    }

    getPayoutAmount(trips) {
        let collectedAmount = 0
        let unavailableAmount = 0
        let payoutAmount = 0
        if (this.props.firebase.auth().currentUser.uid) {
            let tripCollected = []
            trips.forEach((trip) => {
                let paymentData = this.props.readSubcollection('charge_confirmation', trip.tripId, 'charges')
                let chargedAmount = 0;

                let refundData = this.props.readSubcollection('refund_confirmation', trip.tripId, 'refunds')
                let refundAmount = 0;

                if (paymentData) {
                    paymentData.forEach((entry) => {
                        if ((entry.organizer === this.props.firebase.auth().currentUser.uid) && (entry.status)) {
                            chargedAmount += parseFloat(entry.amount);
                        }
                    })
                }

                if (refundData) {
                    refundData.forEach((entry) => {
                        if ((entry.organizer === this.props.firebase.auth().currentUser.uid) && (entry.status === "succeeded")) {
                            refundAmount += parseFloat(entry.amount);
                        }
                    })
                }
                tripCollected.push({ tripId: trip.tripId, collectedAmount: (chargedAmount - refundAmount) })
                collectedAmount += (chargedAmount - refundAmount)
            })

            tripCollected.forEach((entry) => {
                let tripData = this.props.data('listings', entry.tripId)
                if (tripData) {
                    if (Date.now() > new Date(tripData.toDate).getTime() + 86400000 * 5) { //5 DAY DELAY
                        payoutAmount += entry.collectedAmount
                    }
                }
            })

            unavailableAmount = collectedAmount - payoutAmount

            return [collectedAmount, unavailableAmount, payoutAmount]

        } else {
            return [collectedAmount, unavailableAmount, payoutAmount]
        }
    }

    requestPayout(){
        this.props.request('notification', {
            category: 'update',
            type: 'PayoutRequest',
            uid: this.props.firebase.auth().currentUser.uid,
        })

        this.setState({payoutRequested: true})
        this.props.updateData('users', this.props.firebase.auth().currentUser.uid, 'payoutRequested', true)
    }

    render() {
        return (
            <body>
                <section className='se-page'>
                    <section className='se-navigation-section'>
                        <Link to="/myprofile?id=profileview">
                            <div className='se-navigation-row'>My Profile</div>
                        </Link>
                        <Link to="/myprofile?id=accountinformation">
                            <div className='se-navigation-row'>Account Information</div>
                        </Link>
                        <Link to="/myprofile?id=payout">
                            <div className='se-navigation-row'>
                                <div className='active-nav'>Payout</div>
                            </div>
                        </Link>
                        <Link to="/myprofile?id=notificationsettings">
                            <div className='se-navigation-row'>Notification Settings</div>
                        </Link>
                    </section>
                    <section className='se-content-section'>
                        <div className='se-content-container'>
                            <div className='se-content-header'>Payout Summary</div>
                            <div className='se-content'>
                                <div className='se-content-subheader'>This is the total amount you have collected from organizing trips through TravelFam. The amount collected from each trip will be availble for transfer 5 days after your trip is over.</div>
                                <div className='se-payout-row'>
                                    <div className='se-payout-title'></div>
                                    <div className='se-payout-content'>
                                        <div className='se-payout-details'>Amount collected</div>
                                        <div className='se-payout-details'>Unavailable Amount</div>
                                        <div className='se-payout-details'>Available for Payout</div>
                                    </div>
                                </div>
                                <div className='se-payout-row'>
                                    <div className='se-payout-title'>Trip 1 Name d;aljf;asdjfasdf;klsaflk </div>
                                    <div className='se-payout-content'>
                                        <div className='se-payout-details'>${this.state.collectedAmount}</div>
                                        <div className='se-payout-details'>${this.state.unavailableAmount}</div>
                                        <div className='se-payout-details'>${this.state.payoutAmount}</div>
                                    </div>
                                </div>
                                <div className='se-payout-row'>
                                    <div className='se-payout-title'>Trip 2 Name </div>
                                    <div className='se-payout-content'>
                                        <div className='se-payout-details'>${this.state.collectedAmount}</div>
                                        <div className='se-payout-details'>${this.state.unavailableAmount}</div>
                                        <div className='se-payout-details'>${this.state.payoutAmount}</div>
                                    </div>
                                </div>
                                <div className='se-payout-row'>
                                    <div className='se-payout-title'>Trip 3 Name </div>
                                    <div className='se-payout-content'>
                                        <div className='se-payout-details'>${this.state.collectedAmount}</div>
                                        <div className='se-payout-details'>${this.state.unavailableAmount}</div>
                                        <div className='se-payout-details'>${this.state.payoutAmount}</div>
                                    </div>
                                </div>
                                <div className='se-payout-row' style={{backgroundColor: "#fafaff"}}>
                                    <div className='se-payout-title'>Total </div>
                                    <div className='se-payout-content'>
                                        <div className='se-payout-details'>&nbsp;</div>
                                        <div className='se-payout-details'>&nbsp;</div>
                                        <div className='se-payout-details'>${this.state.payoutAmount}</div>
                                    </div>
                                </div>
                                <div className='se-payout-row'>
                                    <div className='se-payout-title'>TravelFam Fees</div>
                                    <div className='se-payout-content'>
                                        <div className='se-payout-details'>&nbsp;</div>
                                        <div className='se-payout-details'>&nbsp;</div>
                                        <div className='se-payout-details'>(${this.state.payoutAmount})</div> {/*<---- this should be 5%*/}
                                    </div>
                                </div>
                                <div className='se-payout-row' style={{backgroundColor: "#fafaff"}}>
                                    <div className='se-payout-title'>Amount available for transfer </div>
                                    <div className='se-payout-content'>
                                        <div className='se-payout-details'>&nbsp;</div>
                                        <div className='se-payout-details'>&nbsp;</div>
                                        <div className='se-payout-details'>${this.state.payoutAmount}</div>
                                    </div>
                                </div>
                                {(this.state.payoutAmount<=0 && !this.state.payoutRequested)&& <div className='se-btn-row'>
                                    <div className='btn-four'>Request Payout</div>
                                </div>}
                                {(this.state.payoutAmount>0 && !this.state.payoutRequested)&&<div className='se-btn-row'>
                                    <div className='btn-five' onClick={() => {this.requestPayout()}}>Request Payout</div>
                                </div>}
                            </div>
                            {this.state.payoutRequested && <div>
                                <div className='se-success'>Transfer initiated</div>
                         </div>}
                        </div>
                        
                         <div className='se-content-container'>
                            <div className='se-content-header'>Payout History</div>
                            <div className='se-content'>
                                
                                <div className='se-payout-row'>
                                    <div className='se-payout-title'>January 1, 2020</div>
                                    <div className='se-payout-content'>
                                        <div className='se-payout-details'>&nbsp;</div>
                                        <div className='se-payout-details'>Initated</div>
                                        <div className='se-payout-details'>${this.state.payoutAmount}</div>
                                    </div>
                                </div>
                                <div className='se-payout-row'>
                                    <div className='se-payout-title'>February 1, 2020 </div>
                                    <div className='se-payout-content'>
                                        <div className='se-payout-details'>&nbsp;</div>
                                        <div className='se-payout-details'>Complete</div>
                                        <div className='se-payout-details'>${this.state.payoutAmount}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </body>
        )
    }
}

export default payout;