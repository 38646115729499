import React, { Component } from 'react';
import NavBar from '../Navigation';
import '../../css/index.css';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Map from '../Map/index'
import OrganizerView from './OrganizerView'
import ReservedView from './ReservedView'
import JoinReqView from './JoinReqView'
import CancelReqView from './CancelReqView'
import GuestView from './GuestView'

import Loading from '../Loading';
import DeleteConfirmation from './DeleteConfirmation.js';
import CancelModal from './CancelModal.js';

class newMyTripsDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectPath: '',
            listingData: null,
            status: null,
            displayDeleteConfirmation: false,
            redirectPath: '',
        }

        this.statusRender = this.statusRender.bind(this);
        this.renderImages = this.renderImages.bind(this);
        this.renderPriceBox = this.renderPriceBox.bind(this);
        this.renderDates = this.renderDates.bind(this);
        this.renderIncompleteAddress = this.renderIncompleteAddress.bind(this);
        this.setDisplayDeleteConfirmation = this.setDisplayDeleteConfirmation.bind(this);
        this.setDisplayModal = this.setDisplayModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this)
        this.actionDelete = this.actionDelete.bind(this);
        this.renderDeleteBox = this.renderDeleteBox.bind(this);
        this.renderModal = this.renderModal.bind(this);

        this.listingUsers = this.listingUsers.bind(this);

        this.deleteNotificationHelper = this.deleteNotificationHelper.bind(this)
        this.renderReady = this.renderReady.bind(this)
        this.enableLoading = this.enableLoading.bind(this)
        this.disableLoading = this.disableLoading.bind(this)


        this.getPayoutAmount = this.getPayoutAmount.bind(this)
        this.getPayoutCompleted = this.getPayoutCompleted.bind(this)
        this.requestPayout = this.requestPayout.bind(this)
        this.getChargeRefundPayoutStatus = this.getChargeRefundPayoutStatus.bind(this)

    }

    renderReady() {
        if (this.state.listingData && this.state.status && this.props.data('users', this.state.listingData['tripOrganizerId']) && this.props.data('listings', this.props.tripId)) {
            const trip = this.props.data('listings', this.props.tripId);
            
            //*AB: DELETED THIS - NOT SURE WHY WE NEEDED THIS BUT SLOWED EVERYTHING DOWN
            // for (var i in trip.requestedGuests) {
            //     if (!this.props.data('users', trip.requestedGuests[i].requestedUserId)) {
            //         return false
            //     }
            // }
            // for (var i in trip.reservedGuests) { //**AB: CAN WE DELETE? WHY DO WE NEED TO ITERATE THROUGH EVERY RESERVED USER?
            //     if (!this.props.data('users', trip.reservedGuests[i].reservedUserId)) {
            //         return false
            //     }
            // }

            if (!this.props.data('users', trip.tripOrganizerId)) {
                return false
            }

            if (this.props.firebase.auth().currentUser) {
                if (!(this.state.paymentData && this.state.refundData && this.state.payoutData)) {
                    return false
                }
            }

            /*
            if (!this.props.data('users', this.props.viewId)) {
                return false;
            }
            const u = this.props.data('users', this.props.viewId)['reviews'];
            for (var i in u) {
                if (!this.props.data('users', u[i]['fromId'])) {
                    return false;
                }
            }*/
            return true
        }
        return false;
    }

    enableLoading(){
        console.log('Setting state to loading')
        this.setState({loading: true})
    }

    disableLoading(){
        console.log('Switching off state from loading')
        this.setState({loading: false})
    }

    deleteNotificationHelper() {
        console.log(this.props.tripId)
        var tripCancelGuestIdArray = []


        var deleteTripData = this.props.data('listings', this.props.tripId)
        console.log(deleteTripData)
        if (deleteTripData) {
            console.log(deleteTripData)
            var deleteTripReserved = deleteTripData['reservedGuests']
            var deleteTripRequested = deleteTripData['requestedGuests']


            if (deleteTripReserved.length > 0) {
                console.log("reservedGuests")
                for (var i = 0; i < deleteTripReserved.length; i++) {
                    if (this.props.data('users', deleteTripReserved[i].reservedUserId)['newMessages']) {
                        tripCancelGuestIdArray.push(deleteTripReserved[i].reservedUserId)
                    }
                }
            }
            if (deleteTripRequested.length > 0) {
                console.log("requestedGuests")

                for (var i = 0; i < deleteTripRequested.length; i++) {
                    console.log(deleteTripRequested[i])
                    console.log(deleteTripRequested[i].requestedUserId)
                    console.log(this.props.data('users', deleteTripRequested[i].requestedUserId))
                    if (this.props.data('users', deleteTripRequested[i].requestedUserId)['newMessages']) {
                        tripCancelGuestIdArray.push(deleteTripRequested[i].requestedUserId)
                    }
                }
            }

        }
        tripCancelGuestIdArray.forEach((value) => { //NEED TO TEST
            this.props.request('notification', {
                category: 'update',
                type: 'TripCancelGuest',
                uid: value,
            })
        })

    }

    componentDidMount() {

        var listingData = this.props.data("listings", this.props.tripId)
        if (this.props.firebase.auth().currentUser) {
            var paymentData = this.props.readSubcollection('charge_confirmation', this.props.tripId, 'charges')
            var refundData = this.props.readSubcollection('refund_confirmation', this.props.tripId, 'refunds')
            var payoutData = this.props.readSubcollection('payout_confirmation', this.props.tripId, 'payouts')
            // this.getChargeRefundPayoutStatus('charge', this.props.tripId, this.props.firebase.auth().currentUser.uid)
            // this.getChargeRefundPayoutStatus('refund', this.props.tripId, this.props.firebase.auth().currentUser.uid)
            // if (this.props.firebase.auth().currentUser.uid === this.props.organizerId) {
            //     this.getChargeRefundPayoutStatus('onboard', this.props.tripId, this.props.firebase.auth().currentUser.uid)
            //     this.getChargeRefundPayoutStatus('total', this.props.tripId, this.props.firebase.auth().currentUser.uid)
            //     this.getChargeRefundPayoutStatus('payout', this.props.tripId, this.props.firebase.auth().currentUser.uid)
            // }
        }


        this.setState({

            listingData: listingData,
            paymentData: paymentData,
            payoutData: payoutData,
            refundData: refundData,
            tripId: this.props.tripId,
            organizerId: this.props.organizerId,
        })


        if (this.props.firebase.auth().currentUser && listingData) {
            this.setState({ status: "Guest" })
            var currentUser = this.props.firebase.auth().currentUser
            var userData = this.props.data("users", currentUser.uid)
            this.setState({ userData: userData })
            this.setState({ currentUserId: currentUser.uid })
            if (currentUser.uid === this.props.organizerId) {
                this.setState({ status: "Organizer" })
            } else {

                listingData.requestedGuests.map((requests) => {
                    if (requests.requestedUserId === currentUser.uid) {
                        this.setState({ status: "Join Requested" })
                        console.log(requests.requestedUserId)
                    }
                })

                listingData.reservedGuests.map((reserved) => {
                    if (reserved.reservedUserId === currentUser.uid) {
                        this.setState({ status: "Reserved" })

                    }
                })

                listingData.cancelRequested.map((cancelled) => {
                    if (cancelled.cancelUserId === currentUser.uid) {
                        this.setState({ status: "Cancel Requested" })
                    }
                })
            }
        } else {
            this.setState({ status: "Guest" });
        }
    }



    renderDates() {
        return (
            <div className='dash-header'>
                <div className='dash-dates' style={{ borderRight: '1px solid #c4c4c4' }}>
                    <div className='dash-text'>Arrive</div>
                    <div>{this.props.formatDateRange(this.state.listingData.fromDate, this.state.listingData.toDate, 'from')}</div>
                </div>
                <div className='dash-dates'>
                    <div className='dash-text'>Depart</div>
                    <div>{this.props.formatDateRange(this.state.listingData.fromDate, this.state.listingData.toDate, 'to')}</div>
                </div>
            </div>
        )
    }

    renderIncompleteAddress() {
        const addressArray = this.state.listingData['address'][0].split(',');
        //TODO: FORMAT
        return (
            <div className='ld-location'>{addressArray[1]}, {addressArray[2].split(" ")[1]}</div>
        )
    }

    setDisplayDeleteConfirmation(val) {
        this.setState({
            displayDeleteConfirmation: val,
        });
    }

    setDisplayModal(val, type, func, user) {
        this.setState({
            displayModal: val,
            modalType: type,
            modalCallback: func,
            modalCallbackUser: user
        });
    }

    listingUsers() {
        const requested = this.props.data('listings', this.props.tripId)['requestedGuests'];
        const reserved = this.props.data('listings', this.props.tripId)['reservedGuests'];
        const owner = this.props.data('listings', this.props.tripId)['tripOrganizerId'];
        console.log('requested', requested);
        console.log('reserved', reserved);
        console.log('owner', owner);
        for (var i in requested) {
            if (!this.props.data('users', requested[i]['requestedUserId'])) {
                return false;
            }
        }
        for (var i in reserved) {
            if (!this.props.data('users', reserved[i]['reservedUserId'])) {
                return false;
            }
        }
        if (!this.props.data('users', owner)) {
            return false;
        }
        return true;
    }

    handleDelete() {

        //tripCancelGuest Notifcation

        this.deleteNotificationHelper()

        this.setState({ redirectPath: 'delete/events?id=home' });

    }

    async actionDelete() {
        let status = 'pending'
        if ((this.props.data('listings', this.props.tripId))
            && (this.props.data('channels', this.props.data('listings', this.props.tripId)['events']))
            && (this.listingUsers())) {
            console.log('to delete, ', this.props.tripId);
            const event = this.props.data('listings', this.props.tripId)['events'];
            const requested = this.props.data('listings', this.props.tripId)['requestedGuests'];
            const reserved = this.props.data('listings', this.props.tripId)['reservedGuests'];
            var requestData = {
                action: 'deleteListing',
                requested: requested,
                reserved: reserved,
                channel: event,
                organizerId: this.props.firebase.auth().currentUser.uid, //FIX TOKEN CHECK
                timestamp: Math.floor(Date.now() / 1000),
                tripId: this.props.tripId
            }
            console.log(requestData);
            this.props.request('update', requestData);

            //Submit refund request to server
            let data = {
                organizer: this.props.firebase.auth().currentUser.uid,
                tripId: this.props.tripId,
                guest: reserved,
                type: 'organizerTripCancel',
                timestamp: Math.floor(Date.now() / 1000),
                status: 'pending'
            }
            console.log(data)
            console.log(this.props.tripId)
            this.props.request('refund', data)


            this.props.emptyCache('listings');
            this.props.emptyCache('users');
            this.props.emptyCache('channels');

            status = 'complete'
            return status
        }
    }

    renderDeleteBox() {
        if (this.state.displayDeleteConfirmation) {
            return (
                <DeleteConfirmation
                    setDisplayDeleteConfirmation={this.setDisplayDeleteConfirmation}
                    handleDelete={this.handleDelete}
                    enableLoading = {this.enableLoading}
                    disableLoading = {this.disableLoading}
                ></DeleteConfirmation>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    renderModal() {
        if (this.state.displayModal && this.state.modalType && this.state.modalCallback && this.state.tripId) {
            return (
                <CancelModal
                    setDisplayModal={this.setDisplayModal}
                    type={this.state.modalType}
                    func={this.state.modalCallback}
                    user={this.state.modalCallbackUser}
                    readSubcollection={this.props.readSubcollection}
                    tripId={this.state.tripId}
                    currentUser={this.props.firebase.auth().currentUser.uid}
                    enableLoading = {this.enableLoading}
                    disableLoading = {this.disableLoading}

                ></CancelModal>
            )
        } else {
            return (
                <div></div>
            )
        }
    }


    requestPayout() {
        if (Date.now() > new Date(this.state.listingData.toDate).getTime() + 86400000) {  //1 Day after listing complete
            let payoutAmount = this.getPayoutAmount()
            console.log('Payout Amount of ' + payoutAmount)
            if (payoutAmount > 0) {
                this.props.writeData('payout_requests', {
                    payoutAmount: payoutAmount,
                    organizerId: this.props.firebase.auth().currentUser.uid,
                    timestamp: Math.floor(Date.now() / 1000),
                    tripId: this.state.tripId,
                    status: 'pending'
                }, this.state.tripId)
            }
        }
    }


    getPayoutAmount() {
        if (this.props.firebase.auth().currentUser.uid) {
            let paymentData = this.props.readSubcollection('charge_confirmation', this.state.tripId, 'charges')
            let chargedAmount = 0;

            let refundData = this.props.readSubcollection('refund_confirmation', this.state.tripId, 'refunds')
            let refundAmount = 0;

            if (paymentData) {
                paymentData.forEach((entry) => {
                    if ((entry.organizer === this.props.firebase.auth().currentUser.uid) && (entry.status)) {
                        chargedAmount += parseFloat(entry.amount);
                    }
                })
            }

            if (refundData) {
                refundData.forEach((entry) => {
                    if ((entry.organizer === this.props.firebase.auth().currentUser.uid) && (entry.status === "succeeded")) {
                        refundAmount += parseFloat(entry.amount);
                    }
                })
            }
            return (chargedAmount - refundAmount)
        } else {
            return 0
        }
    }

    getPayoutCompleted() {
        if (this.props.firebase.auth().currentUser.uid) {
            let paymentData = this.props.readSubcollection('payout_confirmation', this.state.tripId, 'payouts')
            let payoutAmount = 0;
            let reversedAmount = 0;

            if (paymentData) {
                paymentData.forEach((entry) => {
                    if (this.props.data('users', this.props.firebase.auth().currentUser.uid).stripeId === entry.destination) {
                        payoutAmount += parseFloat(entry.amount) / 100;
                        reversedAmount += parseFloat(entry.amount_reversed) / 100;
                    }
                })
            }
            return (payoutAmount - reversedAmount)
        } else {
            return 0
        }
    }

    async getChargeRefundPayoutStatus(type, tripId, user) {
        console.log('In charge request')
        console.log('type: ' + type + ' tripId ' + tripId + ' user ' + user)

        if (type === 'charge') {
            //TODO - FILTER IN FIREBASE FOR SCALABILITY
            let chargePromise = await this.props.firebase.firestore().collection('charge_requests').get()
            let chargeData = chargePromise.docs.map(d => d.data());
            if (chargeData) {
                let userCharges = chargeData.filter((data) => {
                    if (data.guest === user && data.tripId === tripId) {
                        return data
                    }
                })
                userCharges.forEach((data) => {
                    if (data.status === 'pending' || data.status === 'failed') {
                        this.setState({ chargeFlag: data.status })
                    }
                })
            }
        }
        if (type === 'onboard') {
            //TODO - FILTER IN FIREBASE FOR SCALABILITY
            let onboardPromise = await this.props.firebase.firestore().collection('submerchant_onboard').get()
            let onboardData = onboardPromise.docs.map(d => d.data());
            if (onboardData) {
                let userOnboards = onboardData.filter((data) => {
                    if (data.organizerId === user) {
                        return data
                    }
                })
                userOnboards.forEach((data) => {
                    if (data.status === 'pending' || data.status === 'failed') {
                        this.setState({ onboardFlag: data.status })
                    }
                })
            }
        }
        if (type === 'refund') {
            let refundPromise = await this.props.firebase.firestore().collection('refund_requests').get()
            let refundData = refundPromise.docs.map(d => d.data());
            if (refundData) {
                let userRefunds = refundData.filter((data) => {
                    if (data.guest === user && data.tripId === tripId) {
                        return data
                    }
                })
                userRefunds.forEach((data) => {
                    if (data.status === 'pending' || data.status === 'failed') {
                        this.setState({ refundFlag: data.status })
                    }
                })
            }
        }
        if (type === 'total') {
            let chargePromise = await this.props.firebase.firestore().collection('charge_requests').get()
            let chargeData = chargePromise.docs.map(d => d.data());
            let refundPromise = await this.props.firebase.firestore().collection('refund_requests').get()
            let refundData = refundPromise.docs.map(d => d.data());
            if (refundData) {
                let tripRefunds = refundData.filter((data) => {
                    if (data.tripId === tripId) {
                        return data
                    }
                })
                tripRefunds.forEach((data) => {
                    if (data.status === 'pending' || data.status === 'failed') {
                        this.setState({ totalFlag: data.status })
                    }
                })
            }
            if (chargeData) {
                let tripCharges = chargeData.filter((data) => {
                    if (data.tripId === tripId) {
                        return data
                    }
                })
                tripCharges.forEach((data) => {
                    if (data.status === 'pending' || data.status === 'failed') {
                        this.setState({ totalFlag: data.status })
                    }
                })
            }
        }
        if (type === 'payout') {
            let payoutPromise = await this.props.firebase.firestore().collection('payout_requests').get()
            let payoutData = payoutPromise.docs.map(d => d.data());
            if (payoutData) {
                let userPayouts = payoutData.filter((data) => {
                    if (data.organizerId === user && data.tripId === tripId) {
                        return data
                    }
                })
                userPayouts.forEach((data) => {
                    if (data.status === 'pending' || data.status === 'failed') {
                        this.setState({ payoutFlag: data.status })
                    }
                })
            }
        }
    }

    statusRender() {

        if (this.state.status === "Organizer") {
            return <OrganizerView
                firebase={this.props.firebase}     //ADDED BECAUSE WE NEED TO PROVE ID FOR BACKEND REQUEST
                request={this.props.request}
                emptyCache={this.props.emptyCache}
                listingData={this.state.listingData}
                payoutData={this.state.payoutData}
                paymentData={this.state.paymentData}
                refundData={this.state.refundData}
                data={this.props.data}
                readSubcollection={this.props.readSubcollection}
                updateData={this.props.updateData}
                tripId={this.state.tripId}
                renderDates={this.renderDates}
                getPayoutAmount={this.getPayoutAmount}
                getPayoutCompleted={this.getPayoutCompleted}
                requestPayout={this.requestPayout}
                setDisplayDeleteConfirmation={this.setDisplayDeleteConfirmation}
                setDisplayModal={this.setDisplayModal}
                payoutFlag={this.state.payoutFlag}
                onboardFlag={this.state.onboardFlag}
                totalFlag={this.state.totalFlag}
                writeData={this.props.writeData}
                getTripId={this.getTripId}
                enableLoading = {this.enableLoading}
                disableLoading = {this.disableLoading}
            />
        } else if (this.state.status === "Reserved") {
            return <ReservedView
                listingData={this.state.listingData}
                request={this.props.request}
                firebase={this.props.firebase}     //ADDED BECAUSE WE NEED TO PROVE ID FOR BACKEND REQUEST
                readSubcollection={this.props.readSubcollection}
                updateData={this.props.updateData}
                paymentData={this.state.paymentData}
                refundData={this.state.refundData}
                tripId={this.state.tripId}
                currentUserId={this.state.currentUserId}
                setDisplayModal={this.setDisplayModal}
                renderDates={this.renderDates}
                writeData={this.props.writeData}
                chargeFlag={this.state.chargeFlag}
                refundFlag={this.state.refundFlag}
                totalFlag={this.state.totalFlag}
                data={this.props.data}
                enableLoading = {this.enableLoading}
                disableLoading = {this.disableLoading}
            />
        } else if (this.state.status === "Join Requested") {
            return <JoinReqView
                listingData={this.state.listingData}
                request={this.props.request}
                firebase={this.props.firebase}     //ADDED BECAUSE WE NEED TO PROVE ID FOR BACKEND REQUEST
                updateData={this.props.updateData}
                userData={this.state.userData}
                currentUserId={this.state.currentUserId}
                tripId={this.state.tripId}
                renderDates={this.renderDates}
                data={this.props.data}
                enableLoading = {this.enableLoading}
                disableLoading = {this.disableLoading}
            />
        } else if (this.state.status === "Cancel Requested") {
            return <CancelReqView
                listingData={this.state.listingData}
                request={this.props.request}
                currentUserId={this.state.currentUserId}
                renderDates={this.renderDates}
                enableLoading = {this.enableLoading}
                disableLoading = {this.disableLoading}
            />
        } else if (this.state.status === "Guest") {
            return <GuestView
                updateData={this.props.updateData}
                request={this.props.request}
                firebase={this.props.firebase}     //ADDED BECAUSE WE NEED TO PROVE ID FOR BACKEND REQUEST
                listingData={this.state.listingData}
                currentUserId={this.state.currentUserId}
                tripId={this.state.tripId}
                userData={this.state.userData}
                renderDates={this.renderDates}
                writeData={this.props.writeData}
                enableLoading = {this.enableLoading}
                disableLoading = {this.disableLoading}
                data={this.props.data}
            />
        }
    }

    renderImages() {
        const imageUrlList = this.state.listingData['imageUrlList'];
        if (imageUrlList) {
            return (
                imageUrlList.map((img) => (
                    <div className='ld-img'>
                        <img className='ld-img-item' src={img} />
                    </div>
                ))
            )
        } else {
            return (
                <Loading></Loading>
            )
        }

    }

    renderPriceBox() {
        //TODO
    }

    render() {
        // console.log(this.renderReady());
        if (this.state.loading){
            console.log('State is loading')
            return(
                <Loading></Loading>
            )
        }
        if (this.state.redirectPath === "/events?id=home") {
            return (
                <Redirect to={this.state.redirectPath}></Redirect>
            )
        } else if (this.state.redirectPath === "delete/events?id=home") {
            if (this.actionDelete() === 'pending') {
                return (
                    <Loading></Loading>
                )
            } else {
                return (
                    <Redirect push to='/events?id=home'></Redirect>
                )
            }
        } else if (this.renderReady()) {
            return (
                <div>
                    <head>
                        <meta charSet='UTF-8' />
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />
                        <title>Manage Trips</title>
                    </head>
                    <body>
                        <NavBar
                            firebase={this.props.firebase}
                            data={this.props.data}
                            view=""
                            emptyCache={this.props.emptyCache}
                        ></NavBar>
                        {this.renderDeleteBox()}
                        {this.renderModal()}
                        <section className='od-page'>

                            <section className='od-container'>
                                <section className='od-details'>
                                    <div className='ld-title'>{this.state.listingData['listingTitle']}</div>
                                    <div className='ld-subtitle'>
                                        {/*TODO: horizontal layout left-right*/}
                                        <div className='ld-date'>{this.props.formatDateRange(this.state.listingData['fromDate'], this.state.listingData['toDate'])}</div>
                                        {this.renderIncompleteAddress()}
                                    </div>
                                    {/*TODO: <div><br/>Listing updated on 9/9/19</div>*/}
                                    <Link push to={"/account?id=" + this.state.listingData['tripOrganizerId']}>
                                        <div className='ld-avatar-area'>
                                            <div className='ld-avatar-img'><img className='ld-avatar-img' src={this.props.data('users', this.state.listingData['tripOrganizerId'])['imageUrl']} /></div>
                                            <div className='ld-avatar-details'>
                                                <div className='ld-avatar-name'>{this.props.data('users', this.state.listingData['tripOrganizerId'])['firstName']} {this.props.data('users', this.state.listingData['tripOrganizerId'])['lastName']}</div>
                                                <div className='ld-avatar-location'>{this.props.data('users', this.state.listingData['tripOrganizerId'])['city']}, {this.props.data('users', this.state.listingData['tripOrganizerId'])['stateCountry']}</div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className='ld-description'>
                                        {this.state.listingData.description}
                                    </div>
                                    {/*<div className='ld-description'>
                                    <textarea/>
            </div>*/}
                                </section>

                                <section className='ld-map'>
                                    <div className="ld-map-container">
                                        <Map
                                            addressSearch={false}
                                            page3Lat={this.state.listingData.address[1]}
                                            page3Lng={this.state.listingData.address[2]}
                                            currChannel={this.state.listingData.events}

                                        ></Map>
                                    </div>
                                </section>

                                <section className='ld-img-section'>
                                    {this.renderImages()}
                                </section>


                            </section>



                            {this.statusRender()}






                        </section>

                    </body>
                </div>
            )
        } else {
            return (
                <Loading></Loading>
            )
        }

    }
}

export default newMyTripsDetails;