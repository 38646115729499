import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import logo from '../../images/logo.svg';
import menu from '../../images/menu.svg';

class NavBar extends Component {
    
    constructor(props){
        super(props)
        this.state={
            settingsToggle: false, 
            navToggle: false,
            imageUrl: '',
        }

        this.settingsMenu = this.settingsMenu.bind(this);
        this.navMenu = this.navMenu.bind(this);
        this.logout = this.logout.bind(this);
        this.emptyCache = this.emptyCache.bind(this);
    }

    emptyCache(name) {
        this.props.emptyCache('users', this.props.firebase.auth().currentUser.uid);
        this.props.emptyCache(name);
    }

    settingsMenu(){
        this.setState({settingsToggle:!this.state.settingsToggle})
    }

    navMenu(){
        this.setState({navToggle:!this.state.navToggle})
    }

    logout() {
      if (this.props.firebase.auth()) {
        this.props.firebase.auth().signOut().then(() => {
          // Sign-out successful.
          //handleMenuClose();
        }).catch((error) => {
          // An error happened.
        });
      }
    }

    render() {
        return(
        <div>
            <body>
                <section className='nav-page'>
                    <div className='nav-area'>
                        <Link to='/events?id=home'>
                            <div className='nav-left'><img src={logo}/></div>
                        </Link>
                        <div className='nav-right'>
                            <div className='nav-mobile'>
                                <div onClick={this.navMenu}>
                                    <img src={menu}/>
                                </div>
                                {(this.state.navToggle&&
                                    <div>
                                    <div className='nav-background'></div>
                                    <div className='nav-mobile-menu'>
                                        <Link to='/listingcreate?id=1'><div className='nav-mobile-links'>Create Listing</div></Link>
                                        <Link to='/events?id=home'><div className='nav-mobile-links'>Events</div></Link>
                                        <Link to='/myTripsNew'><div className='nav-mobile-links'>My Trips</div></Link>
                                        <Link to='/messages?id=TODO-PREV'><div className='nav-mobile-links'>Messages</div></Link>
                                        <Link to='/myprofile?id=profileview'><div className='nav-mobile-links'>Profile</div></Link>
                                        <Link to='/myprofile?id=accountinformation'><div className='nav-mobile-links'>Account</div></Link>
                                        <Link to='/myprofile?id=payout'><div className='nav-mobile-links'>Payout</div></Link>
                                        <Link to='/myprofile?id=notificationsettings'><div className='nav-mobile-links'>Notifications</div></Link>
                                        <Link to="/"><div onClick={this.logout} className='nav-mobile-links'>Logout</div></Link>
                                    </div>                                    
                                    </div>                                    
                                )}
                            </div>
                            <div className='nav-full'>
                                <div className='nav-links-container'>
                                    <Link to='/listingcreate?id=1'><div className='nav-links'>Create Listing</div></Link>
                                    <Link to='/events?id=home' onClick={() => this.emptyCache('channels')}><div className='nav-links'>Events</div></Link>
                                    <Link to='/myTripsNew' onClick={() => this.emptyCache('listings')}><div className='nav-links'>My Trips</div></Link>
                                    <Link to='/messages?id=TODO-PREV'><div className='nav-links'>Messages</div></Link>
                                </div>
                                <div className='nav-profile'>
                                    <div onClick={this.settingsMenu}>
                                      <img src={this.props.data('users', this.props.firebase.auth().currentUser.uid)['imageUrl']}/>
                                    </div>
                                    {(this.state.settingsToggle&&

                                    <ul className='nav-settings'>
                                        <Link to='/myprofile?id=profileview'><li>My Profile</li></Link>
                                        <Link to='/myprofile?id=accountinformation'><li>Account</li></Link>
                                        <Link to='/myprofile?id=payout'><li>Payout</li></Link>
                                        <Link to='/myprofile?id=notificationsettings'><li>Notifications</li></Link>
                                        <Link to="/"><li onClick={this.logout}>Logout</li></Link>
                                    </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </body>
        </div>
        )   
    }
}

export default NavBar;