import React, { Component } from 'react'
import '../../css/index.css'
import Map from '../Map/index'
import profile from '../../images/profileIconHolder.png'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import firebase from '../Firebase'
import uniqid from 'uniqid'
import Loading from '../Loading';
import ListingModal from './ListingModal'

var database = firebase.firestore()
class CListingStage3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageList: [],
            advancePage: false,
            selectOption: "1"

        }
        this.publish = this.publish.bind(this);
        this.pullDict = this.pullDict.bind(this);
        this.advancePage = this.advancePage.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.renderIncompleteAddress = this.renderIncompleteAddress.bind(this);
        this.setDisplayModal = this.setDisplayModal.bind(this);
        this.renderModal = this.renderModal.bind(this);

    }

    componentWillMount() {
        this.pullDict();
    }

    selectChange(event) {
        this.setState({ selectOption: event.target.value })
    }

    setDisplayModal(val, type, func, redirect) {
        this.setState({
            displayModal: val,
            modalType: type,
            modalCallback: func,
            modalRedirect: redirect
        });
    }

    renderModal() {
        if (this.state.displayModal && this.state.modalType && this.state.modalCallback) {
            return (
                <ListingModal
                    setDisplayModal={this.setDisplayModal}
                    type={this.state.modalType}
                    func={this.state.modalCallback}
                    redirect={this.state.modalRedirect}
                ></ListingModal>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    publish() {
        this.setState({
            cancelRequested: [],
            requestedGuests: [],
            reservedGuests: [],
            tripOrganizer: this.props.data('users', this.props.firebase.auth().currentUser.uid)['firstName'],
            tripOrganizerId: this.props.firebase.auth().currentUser.uid,
        });
        this.props.pushListing(this.state);
    }

    advancePage(path) {
        if (path === "/listingcreate?id=4") {
            if (this.props.listingEdit) {
                this.props.editListing(this.state);
            } else {
                this.props.pushListing(this.state);
            }

            this.setState({ advancePage: "/myTripsNew" });
        } else if (path === "listingedit") {
            this.setState({ advancePage: "/listingedit?id="+this.props.tripId+"&1a" });
        } else {
            this.setState({ advancePage: path });
        }
    }

    pullDict() {
        this.setState(this.props.currentDict);
    }

    renderIncompleteAddress(address) {
        const addressArray = address[0].split(',');
        //TODO: FORMAT
        return (
            <div className='ld-location'>{addressArray[1]}, {addressArray[2].split(" ")[1]}</div>
        )
    }

    render() {
        if (this.state.listingTitle.length === 0) {
            return (
                <Redirect to='/listingcreate?id=1'></Redirect>
            )
        } else if (this.props.data('users', this.props.firebase.auth().currentUser.uid)) {
            if (this.state.advancePage) {
                return (
                    <Redirect push to={this.state.advancePage}></Redirect>
                )
            } else {
                var options = []
                for (var i = 1; i <= this.state.availableSpots; i++) {
                    options.push(
                        <option value={i}>{i} Guests</option>
                    )
                }
                return (
                    <div>
                        <div>
                            <meta charSet='UTF-8' />
                            <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />
                            <title>Create Listing 1</title>
                        </div>
                        <div>

                            <section className='od-page'>
                                <section className='od-container'>
                                    <section className='ld-details'>
                                        <div className='ld-title'>{this.state.listingTitle}</div>
                                        <div className='ld-subtitle'>
                                            <div className='ld-date'>{this.props.formatDateRange(this.state.fromDate, this.state.toDate)}</div>
                                            {this.renderIncompleteAddress(this.state.address)}
                                        </div>
                                        <div className='ld-avatar-area'>
                                            <div className='ld-avatar-img'><img className='ld-avatar-img' src={this.props.data('users', this.props.firebase.auth().currentUser.uid)['imageUrl']} /></div>
                                            <div className='ld-avatar-details'>
                                                <div className='ld-avatar-name'>{this.props.data('users', this.props.firebase.auth().currentUser.uid)['firstName'] + " " + this.props.data('users', this.props.firebase.auth().currentUser.uid)['lastName']}</div>
                                                <div className='ld-avatar-location'>{this.props.data('users', this.props.firebase.auth().currentUser.uid)['city'] + ", " + this.props.data('users', this.props.firebase.auth().currentUser.uid)['stateCountry']}</div>
                                            </div>
                                        </div>
                                        <div className='ld-description'>
                                            {this.state.description}
                                        </div>
                                    </section>

                                    <section className='ld-img-section'>

                                        {this.state.imageUrlList.map((img) => (
                                            <div className="ld-img">
                                                <img src={img} />
                                            </div>
                                        ))}

                                    </section>

                                    <section className='ld-map'>
                                        <div className="cl-map-container" style={{ marginBottom: "100px" }}>
                                            <Map
                                                page3Lat={this.state.address[1]}
                                                page3Lng={this.state.address[2]}
                                                addressSearch={false}
                                                currChannel={this.state.events}

                                            >

                                            </Map>
                                        </div>
                                    </section>
                                </section>

                                <section className='ld-sidebar'>
                                    <div className='ld-request-container'>
                                        <div className='ld-price'><span style={{ fontSize: 30 }}>${this.state.pricePerSpot}</span> per spot</div>
                                        <div className='ld-guest-selection'>
                                            <select id="guestNumOptions" onChange={this.selectChange} value={this.state.value}>
                                                {options}
                                            </select>
                                            <div className='ld-select-icon'>&#9662;</div>
                                        </div>
                                        <div className='ld-receipt'>
                                            <div className='ld-receipt-row'>
                                                <div className='ld-receipt-row-item'>${this.state.pricePerSpot} x {parseInt(this.state.selectOption, 10)}</div>
                                                <div className='ld-receipt-row-item'>${
                                                    Number(this.state.pricePerSpot.replace(/,/g, '') * parseInt(this.state.selectOption, 10).toFixed(0)).toLocaleString().split(/\s/).join(',')

                                                }</div>
                                            </div>
                                            <div className='ld-receipt-row'>
                                                <div className='ld-receipt-row-item'>Service Fee</div>
                                                <div className='ld-receipt-row-item'>Free</div>
                                            </div>
                                            <div className='ld-receipt-row'>
                                                <div className='ld-receipt-row-item'>Total</div>
                                                <div className='ld-receipt-row-item'>${
                                                    Number(this.state.pricePerSpot.replace(/,/g, '') * parseInt(this.state.selectOption, 10).toFixed(0)).toLocaleString().split(/\s/).join(',')
                                                }</div>
                                            </div>
                                            <div className='ld-btn-container'>
                                                <div className='btn-four'>Request to Join</div>
                                            </div>
                                            <div className='ld-btn-container'>
                                                <div className='btn-four'>Message</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='ld-cancel-container'>
                                        <div className='ld-cancel-header'>Cancellation Policy</div>
                                        <div className='ld-standard-cancel-text'>All reservations are non-refundable unless approved by the organizer.</div>
                                        {(this.state.cancellationPolicy &&
                                            <div className='ld-cancel-text'>Additional note from the organizer:
                                        <div className='ld-cancel-text'>{this.state.cancellationPolicy}</div>
                                            </div>)}
                                    </div>
                                </section>
                            </section>
                            <section className='preview-bar'>
                                <div className='preview-text'>Preview mode: this is how guests will see your listing.</div>
                                <div className='preview-actions'>
                                    <button className='preview-edit-btn btn-three' onClick={() => this.props.listingEdit ? this.advancePage("listingedit") : this.advancePage("/listingcreate?id=1")}>Edit Listing</button>
                                    <button className='preview-publish-btn btn-one' onClick={() => { this.setDisplayModal(true, 'listingPublish', this.advancePage, "/listingcreate?id=4") }}>Publish</button>
                                </div>
                            </section>
                            {this.renderModal()}
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <Loading></Loading>
            )
        }
    }
}

export default CListingStage3