import React, { Component } from 'react';
import pending from '../../images/pending.gif';
import exit from '../../images/exit.svg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";



class ListingModal extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
    }

    handleClick(label) {
        console.log(label);
        if (label === 'displayModal') {
            this.props.setDisplayModal(true);
        } else if (label === 'undisplayModal') {
            this.props.setDisplayModal(false);
        } else if (label === 'confirm') {
            this.props.func(this.props.redirect);
        }
    }

    renderMessage(type) {
        if (type === 'listingPublish') {
            return (
                <body>
                    <div className='od-cancel-page'>
                        <div className='od-cancel-container' style={{"height":'350px'}}>
                            <div className='od-cancel-title'>Publish Your Listing</div>
                            <div className='od-cancel-text'>By clicking the Confirm button below, you agree to the <a href='https://travelfam.io/TOS'>Terms of Service</a> and agree that you and your guests are responsible for identifying, understanding, and complying with all laws, rules and regulations set by governmental authorities, landlords, hotels, OTAs, exchanges, or any other entities, that apply to your listing and any associated services you provide.</div>
                            <div className='od-cancel-btn-row'>
                                <div className='btn-three' onClick={() => this.handleClick('undisplayModal')}>Cancel</div>
                                <div className='btn-one' onClick={() => this.handleClick('confirm')}>Publish</div>
                            </div>
                            <div className='exit' onClick={() => this.handleClick('undisplayModal')}><img src={exit} /></div>
                        </div>
                    </div>
                </body>
            )

        }
    }



    render() {
        return (
            this.renderMessage(this.props.type)
        )
    }
}

export default ListingModal;