import React, {Component} from 'react';

import next from '../../images/next.svg';
import back from '../../images/back.svg';
import logo from '../../images/logo.svg';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class signup2a extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smsInput: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, label) {
        if (label === 'sms') {
            this.setState({smsInput: event.target.value});
        } else if (label === 'button') {
            this.props.verifySMS(this.state.smsInput);
        }
    }
    
    render() {
        if (this.props.phone.length === 0) {
            return (
                <Redirect to="/signup?id=1"></Redirect>
            )
        } else {
            return(
                <div>
                    <head>
                        <meta charSet='UTF-8'/>
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                        <title>signup2</title>
                    </head>
                    <body>
                        <section className='log-page'>
                            <section className='log-content'>
                                <div className='log-logo'><img src={logo}/></div>
                                <div className='log-header-regular'>An account already exists<br/>with this phone number<br/><strong>{formatPhoneNumber(this.props.phone)}</strong></div>
                                <div className='log-field-container'>
                                    <Link to="/login"><div className='btn-one'>Login</div></Link>
                                </div>
                                <div className='log-footer-area'>
                                    <div className='log-footer'>Forgot your password? <Link push to="/retrievePw"><span className='link'>Retrieve</span></Link></div>
                                </div>
                            </section>
                        </section>
                    </body>
                </div>
            ) 
        }  
    }
}

export default signup2a;