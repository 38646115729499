import React, {Component} from 'react';

import profile from '../../images/profile.jpg';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

const uuidv1 = require('uuid/v1');


var instagramF = '';
var facebookF = '';
var twitterF = '';

class myProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: '',
            day: '',
            description: ' ',
            firstName: '',
            gender: '',
            imageUrl: '',
            lastName: '',
            month: '',
            stateCountry: '',
            year: '', 
            changes: {
                imageUrl: false,
                firstName: false,
                lastName: false,
                city: false,
                stateCountry: false,
                description: false,
                button1: false,
                gender: false,
                month: false,
                day: false,
                year: false,
                button2: false,

                instagram: false,
                facebook: false,
                twitter: false,
            },
            instagram: '',
            facebook: '',
            twitter: '',
            socialMedia: {},
            privateData: ['day','month','year'],
        }

        this.handleChange = this.handleChange.bind(this);
        this.pushChanges = this.pushChanges.bind(this);
        this.onFileUpload = this.onFileUpload.bind(this);
        this.renderButton = this.renderButton.bind(this);
    }

    componentWillMount() {
        const p = this.props.data('users', this.props.firebase.auth().currentUser.uid);
        this.setState({day: p['day']});
        this.setState({description: p['description']});
        this.setState({firstName: p['firstName']});
        this.setState({gender: p['gender']});
        this.setState({imageUrl: p['imageUrl']});
        this.setState({lastName: p['lastName']});
        this.setState({city: p['city']});
        this.setState({stateCountry: p['stateCountry']});
        this.setState({month: p['month']});
        this.setState({year: p['year']});
        this.setState({socialMedia: p['socialMedia']});
        this.setState({instagram: p['socialMedia']['instagram']});
        this.setState({facebook: p['socialMedia']['facebook']});
        this.setState({twitter: p['socialMedia']['twitter']});
        //TODO: style
        if (!p['socialMedia']['instagram']) {
            this.setState({instagram: ''});
        }
        if (!p['socialMedia']['facebook']) {
            this.setState({facebook: ''});
        }
        if (!p['socialMedia']['twitter']) {
            this.setState({twitter: ''});
        }
        instagramF = p['socialMedia']['instagram'];
        facebookF = p['socialMedia']['facebook'];
        twitterF = p['socialMedia']['twitter'];
    }

    handleChange(event, label, imageUrl=false) {
        const d = this.props.data('users', this.props.firebase.auth().currentUser.uid);
        var changes = this.state.changes;
        if (label === 'imageUrl') {
            this.setState({imageUrl: imageUrl});
            changes['imageUrl'] = (d['imageUrl'] !== imageUrl);
        } else if (label === 'firstName') {
            this.setState({firstName: event.target.value});
            changes['firstName'] = (d['firstName'] !== event.target.value);
        } else if (label === 'lastName') {
            this.setState({lastName: event.target.value});
            changes['lastName'] = (d['lastName'] !== event.target.value);
        } else if (label === 'city') {
            this.setState({city: event.target.value});
            changes['city'] = (d['city'] !== event.target.value);
        } else if (label === 'stateCountry') {
            this.setState({stateCountry: event.target.value});
            changes['stateCountry'] = (d['stateCountry'] !== event.target.value);
        } else if (label === 'description') {
            this.setState({description: event.target.value});
            changes['description'] = (d['description'] !== event.target.value);
        } else if (label === 'instagram') {
            var socialMediaMap = this.state.socialMedia;
            socialMediaMap['instagram'] = event.target.value;
            this.setState({socialMedia: socialMediaMap});
            this.setState({instagram: event.target.value});
            changes['instagram'] = (instagramF !== event.target.value);
        } else if (label === 'facebook') {
            var socialMediaMap = this.state.socialMedia;
            socialMediaMap['facebook'] = event.target.value;
            this.setState({socialMedia: socialMediaMap});
            this.setState({facebook: event.target.value});
            changes['facebook'] = (facebookF !== event.target.value);
        } else if (label === 'twitter') {
            var socialMediaMap = this.state.socialMedia;
            socialMediaMap['twitter'] = event.target.value;
            this.setState({socialMedia: socialMediaMap});
            this.setState({twitter: event.target.value});
            changes['twitter'] = (twitterF !== event.target.value);
        } else if (label === 'button1') {
            console.log(this.state.socialMedia);
            const contents = [
                'imageUrl',
                'firstName', 
                'lastName', 
                'city',
                'stateCountry',
                'description',
                'socialMedia',
            ];
            this.pushChanges(this.props.firebase.auth().currentUser.uid, contents);
        } else if (label === 'gender') {
            this.setState({gender: event.target.value});
            changes['gender'] = (d['gender'] !== event.target.value);
        } else if (label === 'year') {
            this.setState({year: event.target.value});
            changes['year'] = (d['year'] !== event.target.value);
        } else if (label === 'month') {
            this.setState({month: event.target.value});
            changes['month'] = (d['month'] !== event.target.value);
        } else if (label === 'day') {
            this.setState({day: event.target.value});
            changes['day'] = (d['day'] !== event.target.value);
        } else if (label === 'button2') {
            //UPDATE
            //this.setState({lastName: event.target.value});
            const contents = [
                'gender',
                'year',
                'month',
                'day',
            ];
            this.pushChanges(this.props.firebase.auth().currentUser.uid, contents);
        } 
        
        //update aggregate

        const contents1 = [
            'imageUrl',
            'firstName', 
            'lastName', 
            'city',
            'stateCountry',
            'description',
            'instagram',
            'facebook',
            'twitter',
        ];
        const contents2 = [
            'gender',
            'year',
            'month',
            'day',
        ];
        var count1 = 0;
        for (var i in contents1) {
            if (changes[contents1[i]]) {
                changes['button1'] = true;
                count1 += 1;
            }
        }
        if (count1 === 0) {
            changes['button1'] = false;
        }
        var count2 = 0;
        for (var i in contents2) {
            if (changes[contents2[i]]) {
                changes['button2'] = true;
                count2 += 1;
            }
        }
        if (count2 === 0) {
            changes['button2'] = false;
        }
        this.setState({changes: changes});
    }

    pushChanges(id, contentlist) {
        var incompletelist = this.resolveIncomplete(contentlist);
        if ((!incompletelist) || ((incompletelist.length === 1) && (incompletelist[0] === 'description'))) {
            //everything filled out
            for (var val in contentlist) {
                if (this.state.privateData.includes(contentlist[val])){
                    this.props.updateData('users', this.props.firebase.auth().currentUser.uid, contentlist[val], this.state[contentlist[val]]);
                } else {
                    this.props.updateData('users', this.props.firebase.auth().currentUser.uid, contentlist[val], this.state[contentlist[val]]);
                }
            }
        } else {
            alert('TODO: ui for incorrect fields');
        }
    }

    resolveIncomplete(contentlist) {
        var incompletelist = [];
        for (var val in contentlist) {
            if (this.state[contentlist[val]] === '') {
                incompletelist.push(contentlist[val]);
            }
        }
        if (incompletelist.length === 0) {
            return false;
        } else {
            return incompletelist;
        }
    }

    onFileUpload(e) {
        let files = e.target.files;
        var file = files[0]; // use the Blob or File API
        var id = uuidv1();
        var uploadTask = this.props.firebase.storage().ref().child('users/' + this.props.firebase.auth().currentUser.uid + "/" + id).put(file);
        uploadTask.on('state_changed', (snapshot) => {
            
          }, (error) => {
            // Handle unsuccessful uploads
          }, () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                this.handleChange(null, 'imageUrl', downloadURL);
                //this.setState({imageUrl: downloadURL});
            });
          });
    }

    renderButton(button, active) {
        if (button === 'button1') {
            if (active) {
                return (
                    <div className='btn-one' onClick={(e) => this.handleChange(e, 'button1')}>Update</div>
                )
            } else {
                return (
                    <div className='btn-inactive' onClick={(e) => this.handleChange(e, 'button1')} disabled >Update</div>
                )
            }
        } else if (button === 'button2') {
            if (active) {
                return (
                    <div className='btn-one' onClick={(e) => this.handleChange(e, 'button2')}>Update</div>
                )
            } else {
                return (
                    <div className='btn-inactive' onClick={(e) => this.handleChange(e, 'button2')} disabled >Update</div>
                )
            }
        }
    }
    
    render() {
        console.log(this.state);
        if (this.props.data('users', this.props.firebase.auth().currentUser.uid)) {
            return(
                <div>
                    <head>
                        <meta charSet='UTF-8'/>
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                        <title>Profile</title>
                    </head>
                    <body>
                        <section className='se-page'>
                            <section className='se-navigation-section'>
                                <Link to="/myprofile?id=profileview">
                                    <div className='se-navigation-row'>
                                        <div className='active-nav'>My Profile</div>
                                    </div>
                                </Link>
                                <Link to="/myprofile?id=accountinformation">
                                    <div className='se-navigation-row'>Account Information</div>
                                </Link>
                                <Link to="/myprofile?id=payout">
                                    <div className='se-navigation-row'>Payout</div>
                                </Link>
                                <Link to="/myprofile?id=notificationsettings">
                                    <div className='se-navigation-row'>Notification Settings</div>
                                </Link>
                            </section>
                            <section className='se-content-section'>
                                <div className='se-content-container'>
                                    <div className='se-content-header'>Profile Information</div>
                                    <div className='se-content'>
                                        <div className='se-content-subheader'>Share what an amazing person you are! This is what the community will learn about you.</div>
                                        <div className='se-profile-img'>
                                            <img src={this.state.imageUrl}/>
                                            <label for='profile-upload' className='se-edit-profile-img btn-two'>Change</label>
                                            <input id='profile-upload' type="file" name="file" onChange={(e)=>this.onFileUpload(e)} />
                                        </div>

                                        <div className='se-content-row'>
                                            <label>First name</label>
                                            <input type='text' value={this.state.firstName} onChange={(e) => this.handleChange(e, 'firstName')} required />
                                        </div>
                                        <div className='se-content-row'>
                                            <label>Last name</label>
                                            <input type='text' value={this.state.lastName} onChange={(e) => this.handleChange(e, 'lastName')} required/>
                                        </div>
                                        <div className='se-content-row'>
                                            <label>City</label>
                                            <input type='text' value={this.state.city} onChange={(e) => this.handleChange(e, 'city')} required/>
                                        </div>
                                        <div className='se-content-row'>
                                            <label>State / Country</label>
                                            <select value={this.state.stateCountry} onChange={(e) => this.handleChange(e, 'stateCountry')} required> 
                                                <option disabled selected>State or Country</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District Of Columbia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>
                                                <option value="Afghanistan">Afghanistan</option>
                                                <option value="Åland Islands">Åland Islands</option>
                                                <option value="Albania">Albania</option>
                                                <option value="Algeria">Algeria</option>
                                                <option value="American Samoa">American Samoa</option>
                                                <option value="Andorra">Andorra</option>
                                                <option value="Angola">Angola</option>
                                                <option value="Anguilla">Anguilla</option>
                                                <option value="Antarctica">Antarctica</option>
                                                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                <option value="Argentina">Argentina</option>
                                                <option value="Armenia">Armenia</option>
                                                <option value="Aruba">Aruba</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Azerbaijan">Azerbaijan</option>
                                                <option value="Bahamas">Bahamas</option>
                                                <option value="Bahrain">Bahrain</option>
                                                <option value="Bangladesh">Bangladesh</option>
                                                <option value="Barbados">Barbados</option>
                                                <option value="Belarus">Belarus</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Belize">Belize</option>
                                                <option value="Benin">Benin</option>
                                                <option value="Bermuda">Bermuda</option>
                                                <option value="Bhutan">Bhutan</option>
                                                <option value="Bolivia">Bolivia</option>
                                                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                <option value="Botswana">Botswana</option>
                                                <option value="Bouvet Island">Bouvet Island</option>
                                                <option value="Brazil">Brazil</option>
                                                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                <option value="Bulgaria">Bulgaria</option>
                                                <option value="Burkina Faso">Burkina Faso</option>
                                                <option value="Burundi">Burundi</option>
                                                <option value="Cambodia">Cambodia</option>
                                                <option value="Cameroon">Cameroon</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Cape Verde">Cape Verde</option>
                                                <option value="Cayman Islands">Cayman Islands</option>
                                                <option value="Central African Republic">Central African Republic</option>
                                                <option value="Chad">Chad</option>
                                                <option value="Chile">Chile</option>
                                                <option value="China">China</option>
                                                <option value="Christmas Island">Christmas Island</option>
                                                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                <option value="Colombia">Colombia</option>
                                                <option value="Comoros">Comoros</option>
                                                <option value="Congo">Congo</option>
                                                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                                <option value="Cook Islands">Cook Islands</option>
                                                <option value="Costa Rica">Costa Rica</option>
                                                <option value="Cote D'ivoire">Cote D'ivoire</option>
                                                <option value="Croatia">Croatia</option>
                                                <option value="Cuba">Cuba</option>
                                                <option value="Cyprus">Cyprus</option>
                                                <option value="Czech Republic">Czech Republic</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Djibouti">Djibouti</option>
                                                <option value="Dominica">Dominica</option>
                                                <option value="Dominican Republic">Dominican Republic</option>
                                                <option value="Ecuador">Ecuador</option>
                                                <option value="Egypt">Egypt</option>
                                                <option value="El Salvador">El Salvador</option>
                                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                <option value="Eritrea">Eritrea</option>
                                                <option value="Estonia">Estonia</option>
                                                <option value="Ethiopia">Ethiopia</option>
                                                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                <option value="Faroe Islands">Faroe Islands</option>
                                                <option value="Fiji">Fiji</option>
                                                <option value="Finland">Finland</option>
                                                <option value="France">France</option>
                                                <option value="French Guiana">French Guiana</option>
                                                <option value="French Polynesia">French Polynesia</option>
                                                <option value="French Southern Territories">French Southern Territories</option>
                                                <option value="Gabon">Gabon</option>
                                                <option value="Gambia">Gambia</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Germany">Germany</option>
                                                <option value="Ghana">Ghana</option>
                                                <option value="Gibraltar">Gibraltar</option>
                                                <option value="Greece">Greece</option>
                                                <option value="Greenland">Greenland</option>
                                                <option value="Grenada">Grenada</option>
                                                <option value="Guadeloupe">Guadeloupe</option>
                                                <option value="Guam">Guam</option>
                                                <option value="Guatemala">Guatemala</option>
                                                <option value="Guernsey">Guernsey</option>
                                                <option value="Guinea">Guinea</option>
                                                <option value="Guinea-bissau">Guinea-bissau</option>
                                                <option value="Guyana">Guyana</option>
                                                <option value="Haiti">Haiti</option>
                                                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                <option value="Honduras">Honduras</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Iceland">Iceland</option>
                                                <option value="India">India</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                                <option value="Iraq">Iraq</option>
                                                <option value="Ireland">Ireland</option>
                                                <option value="Isle of Man">Isle of Man</option>
                                                <option value="Israel">Israel</option>
                                                <option value="Italy">Italy</option>
                                                <option value="Jamaica">Jamaica</option>
                                                <option value="Japan">Japan</option>
                                                <option value="Jersey">Jersey</option>
                                                <option value="Jordan">Jordan</option>
                                                <option value="Kazakhstan">Kazakhstan</option>
                                                <option value="Kenya">Kenya</option>
                                                <option value="Kiribati">Kiribati</option>
                                                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                                <option value="Korea, Republic of">Korea, Republic of</option>
                                                <option value="Kuwait">Kuwait</option>
                                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                <option value="Latvia">Latvia</option>
                                                <option value="Lebanon">Lebanon</option>
                                                <option value="Lesotho">Lesotho</option>
                                                <option value="Liberia">Liberia</option>
                                                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                <option value="Liechtenstein">Liechtenstein</option>
                                                <option value="Lithuania">Lithuania</option>
                                                <option value="Luxembourg">Luxembourg</option>
                                                <option value="Macao">Macao</option>
                                                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                                <option value="Madagascar">Madagascar</option>
                                                <option value="Malawi">Malawi</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Maldives">Maldives</option>
                                                <option value="Mali">Mali</option>
                                                <option value="Malta">Malta</option>
                                                <option value="Marshall Islands">Marshall Islands</option>
                                                <option value="Martinique">Martinique</option>
                                                <option value="Mauritania">Mauritania</option>
                                                <option value="Mauritius">Mauritius</option>
                                                <option value="Mayotte">Mayotte</option>
                                                <option value="Mexico">Mexico</option>
                                                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                <option value="Moldova, Republic of">Moldova, Republic of</option>
                                                <option value="Monaco">Monaco</option>
                                                <option value="Mongolia">Mongolia</option>
                                                <option value="Montenegro">Montenegro</option>
                                                <option value="Montserrat">Montserrat</option>
                                                <option value="Morocco">Morocco</option>
                                                <option value="Mozambique">Mozambique</option>
                                                <option value="Myanmar">Myanmar</option>
                                                <option value="Namibia">Namibia</option>
                                                <option value="Nauru">Nauru</option>
                                                <option value="Nepal">Nepal</option>
                                                <option value="Netherlands">Netherlands</option>
                                                <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                <option value="New Caledonia">New Caledonia</option>
                                                <option value="New Zealand">New Zealand</option>
                                                <option value="Nicaragua">Nicaragua</option>
                                                <option value="Niger">Niger</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="Niue">Niue</option>
                                                <option value="Norfolk Island">Norfolk Island</option>
                                                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                <option value="Norway">Norway</option>
                                                <option value="Oman">Oman</option>
                                                <option value="Pakistan">Pakistan</option>
                                                <option value="Palau">Palau</option>
                                                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                <option value="Panama">Panama</option>
                                                <option value="Papua New Guinea">Papua New Guinea</option>
                                                <option value="Paraguay">Paraguay</option>
                                                <option value="Peru">Peru</option>
                                                <option value="Philippines">Philippines</option>
                                                <option value="Pitcairn">Pitcairn</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Portugal">Portugal</option>
                                                <option value="Puerto Rico">Puerto Rico</option>
                                                <option value="Qatar">Qatar</option>
                                                <option value="Reunion">Reunion</option>
                                                <option value="Romania">Romania</option>
                                                <option value="Russian Federation">Russian Federation</option>
                                                <option value="Rwanda">Rwanda</option>
                                                <option value="Saint Helena">Saint Helena</option>
                                                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                <option value="Saint Lucia">Saint Lucia</option>
                                                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                                <option value="Samoa">Samoa</option>
                                                <option value="San Marino">San Marino</option>
                                                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                <option value="Saudi Arabia">Saudi Arabia</option>
                                                <option value="Senegal">Senegal</option>
                                                <option value="Serbia">Serbia</option>
                                                <option value="Seychelles">Seychelles</option>
                                                <option value="Sierra Leone">Sierra Leone</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Slovakia">Slovakia</option>
                                                <option value="Slovenia">Slovenia</option>
                                                <option value="Solomon Islands">Solomon Islands</option>
                                                <option value="Somalia">Somalia</option>
                                                <option value="South Africa">South Africa</option>
                                                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                                <option value="Spain">Spain</option>
                                                <option value="Sri Lanka">Sri Lanka</option>
                                                <option value="Sudan">Sudan</option>
                                                <option value="Suriname">Suriname</option>
                                                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                <option value="Swaziland">Swaziland</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                                <option value="Tajikistan">Tajikistan</option>
                                                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                <option value="Thailand">Thailand</option>
                                                <option value="Timor-leste">Timor-leste</option>
                                                <option value="Togo">Togo</option>
                                                <option value="Tokelau">Tokelau</option>
                                                <option value="Tonga">Tonga</option>
                                                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                <option value="Tunisia">Tunisia</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="Turkmenistan">Turkmenistan</option>
                                                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                <option value="Tuvalu">Tuvalu</option>
                                                <option value="Uganda">Uganda</option>
                                                <option value="Ukraine">Ukraine</option>
                                                <option value="United Arab Emirates">United Arab Emirates</option>
                                                <option value="United Kingdom">United Kingdom</option>
                                                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                <option value="Uruguay">Uruguay</option>
                                                <option value="Uzbekistan">Uzbekistan</option>
                                                <option value="Vanuatu">Vanuatu</option>
                                                <option value="Venezuela">Venezuela</option>
                                                <option value="Viet Nam">Viet Nam</option>
                                                <option value="Virgin Islands, British">Virgin Islands, British</option>
                                                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                                <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                <option value="Western Sahara">Western Sahara</option>
                                                <option value="Yemen">Yemen</option>
                                                <option value="Zambia">Zambia</option>
                                                <option value="Zimbabwe">Zimbabwe</option>
                                            </select>
                                            <div className='se-select-icon'>&#9662;</div>
                                        </div>

                                        <div className='se-content-row'>
                                            <label>Introduce yourself</label>
                                            <textarea type='text' placeholder='Saved data from last input' value={this.state.description} onChange={(e) => this.handleChange(e, 'description')} />
                                        </div>
                                        
                                        <div className='se-content-row'>
                                            <label>Instagram</label>
                                            <input type='text' placeholder='@instagram-handle' value={this.state.socialMedia['instagram']} onChange={(e) => this.handleChange(e, 'instagram')}/>
                                        </div>

                                        <div className='se-content-row'>
                                            <label>Facebook</label>
                                            <input type='text' placeholder='facebook.com/profile' value={this.state.socialMedia['facebook']} onChange={(e) => this.handleChange(e, 'facebook')}/>
                                        </div>

                                        <div className='se-content-row'>
                                            <label>Twitter</label>
                                            <input type='text' placeholder='@twitter-handle' value={this.state.socialMedia['twitter']} onChange={(e) => this.handleChange(e, 'twitter')}/>
                                        </div>

                                        <div className='se-btn-row'>
                                            {this.renderButton('button1', this.state.changes.button1)}
                                        </div>
                                    </div>
                                </div>
                                <div className='se-content-container'>
                                    <div className='se-content-header'>Other Information</div>
                                    <div className='se-content'>
                                        <div className='se-content-subheader'>This information won't be shared publically. We only use this for analysis.</div>
                                        <div className='se-content-row'>
                                            <label>I am</label>
                                            <select value={this.state.gender} onChange={(e) => this.handleChange(e, 'gender')}> 
                                                <option disabled selected>Gender</option>
                                                <option>Male</option>
                                                <option>Female</option>
                                                <option>Other</option>
                                            </select>
                                            <div className='se-select-icon'>&#9662;</div>
                                        </div>
                                        <div className='se-content-row'>
                                            <label>My birthday is</label>
                                            <div className='se-birthday-row'>
                                                <div className='se-birthday-month'>
                                                    <select value={this.state.month} onChange={(e) => this.handleChange(e, 'month')}> 
                                                        <option disabled selected>Saved data</option>
                                                        <option value="January">January</option>
                                                        <option value="Febuary">Febuary</option>
                                                        <option value="March">March</option>
                                                        <option value="April">April</option>
                                                        <option value="May">May</option>
                                                        <option value="June">June</option>
                                                        <option value="July">July</option>
                                                        <option value="August">August</option>
                                                        <option value="September">September</option>
                                                        <option value="October">October</option>
                                                        <option value="November">November</option>
                                                        <option value="December">December</option>
                                                    </select>
                                                    <div className='se-select-icon'>&#9662;</div>
                                                </div>
                                                <div className='se-birthday-day'>
                                                    <select value={this.state.day} onChange={(e) => this.handleChange(e, 'day')}> 
                                                        <option disabled selected>Saved data</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                        <option value="21">21</option>
                                                        <option value="22">22</option>
                                                        <option value="23">23</option>
                                                        <option value="24">24</option>
                                                        <option value="25">25</option>
                                                        <option value="26">26</option>
                                                        <option value="27">27</option>
                                                        <option value="28">28</option>
                                                        <option value="29">29</option>
                                                        <option value="30">30</option>
                                                        <option value="31">31</option>
                                                    </select>
                                                     <div className='se-select-icon'>&#9662;</div>
                                                </div>
                                                <div className='se-birthday-year'>
                                                    <select value={this.state.year} onChange={(e) => this.handleChange(e, 'year')}> 
                                                        <option disabled selected>Saved data</option>
                                                        <option value="2001">2001</option>
                                                        <option value="2000">2000</option>
                                                        <option value="1999">1999</option>
                                                        <option value="1998">1998</option>
                                                        <option value="1997">1997</option>
                                                        <option value="1996">1996</option>
                                                        <option value="1995">1995</option>
                                                        <option value="1994">1994</option>
                                                        <option value="1993">1993</option>
                                                        <option value="1992">1992</option>
                                                        <option value="1991">1991</option>
                                                        <option value="1990">1990</option>
                                                        <option value="1989">1989</option>
                                                        <option value="1988">1988</option>
                                                        <option value="1987">1987</option>
                                                        <option value="1986">1986</option>
                                                        <option value="1985">1985</option>
                                                        <option value="1984">1984</option>
                                                        <option value="1983">1983</option>
                                                        <option value="1982">1982</option>
                                                        <option value="1981">1981</option>
                                                        <option value="1980">1980</option>
                                                        <option value="1979">1979</option>
                                                        <option value="1978">1978</option>
                                                        <option value="1977">1977</option>
                                                        <option value="1976">1976</option>
                                                        <option value="1975">1975</option>
                                                        <option value="1974">1974</option>
                                                        <option value="1973">1973</option>
                                                        <option value="1972">1972</option>
                                                        <option value="1971">1971</option>
                                                        <option value="1970">1970</option>
                                                        <option value="1969">1969</option>
                                                        <option value="1968">1968</option>
                                                        <option value="1967">1967</option>
                                                        <option value="1966">1966</option>
                                                        <option value="1965">1965</option>
                                                        <option value="1964">1964</option>
                                                        <option value="1963">1963</option>
                                                        <option value="1962">1962</option>
                                                        <option value="1961">1961</option>
                                                        <option value="1960">1960</option>
                                                        <option value="1959">1959</option>
                                                        <option value="1958">1958</option>
                                                        <option value="1957">1957</option>
                                                        <option value="1956">1956</option>
                                                        <option value="1955">1955</option>
                                                        <option value="1954">1954</option>
                                                        <option value="1953">1953</option>
                                                        <option value="1952">1952</option>
                                                        <option value="1951">1951</option>
                                                        <option value="1950">1950</option>
                                                        <option value="1949">1949</option>
                                                        <option value="1948">1948</option>
                                                        <option value="1947">1947</option>
                                                        <option value="1946">1946</option>
                                                        <option value="1945">1945</option>
                                                        <option value="1944">1944</option>
                                                        <option value="1943">1943</option>
                                                        <option value="1942">1942</option>
                                                        <option value="1941">1941</option>
                                                        <option value="1940">1940</option>
                                                        <option value="1939">1939</option>
                                                        <option value="1938">1938</option>
                                                        <option value="1937">1937</option>
                                                        <option value="1936">1936</option>
                                                        <option value="1935">1935</option>
                                                        <option value="1934">1934</option>
                                                        <option value="1933">1933</option>
                                                        <option value="1932">1932</option>
                                                        <option value="1931">1931</option>
                                                        <option value="1930">1930</option>
                                                    </select>
                                                    <div className='se-select-icon'>&#9662;</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='se-btn-row'>
                                            {this.renderButton('button2', this.state.changes.button2)}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                        
                    </body>
                </div>
            ) 
        }  
    }
}

export default myProfile;