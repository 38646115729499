import React, { Component } from 'react';

import Loading from '../Loading';
import MyAccount from './MyAccount.js';
import OtherAccount from './OtherAccount.js';
import Reviews from './Reviews.js';

import instagram from '../../images/instagram.svg';
import facebook from '../../images/facebook.svg';
import twitter from '../../images/twitter.svg';

import NavBar from '../Navigation';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";


class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentView: "none",
        }
        this.renderButtons = this.renderButtons.bind(this);
        this.renderSocialMedia = this.renderSocialMedia.bind(this);
        this.renderEditButton = this.renderEditButton.bind(this);

        this.renderInstagram = this.renderInstagram.bind(this);
        this.renderFacebook = this.renderFacebook.bind(this);
        this.renderTwitter = this.renderTwitter.bind(this);

        this.loading = this.loading.bind(this);
    }

    componentWillMount() {
        if (this.props.firebase && this.props.data('users', this.props.viewId)) {
            if (this.props.firebase.auth().currentUser.uid === this.props.viewId) {
                this.setState({currentView: 'myAccount'});
            } else {
                this.setState({currentView: 'otherAccount'});
            }
        }
    }

    renderButtons() {
        if (this.state.currentView === "myAccount") {
            return (
                <MyAccount
                    firebase={this.props.firebase}
                    data={this.props.data}
                    updateData={this.props.updateData}
                    writeData={this.props.writeData}
                    viewId={this.props.viewId}
                ></MyAccount>
            )
        } else if (this.state.currentView === "otherAccount") {
            return (
                <OtherAccount
                    firebase={this.props.firebase}
                    data={this.props.data}
                    updateData={this.props.updateData}
                    writeData={this.props.writeData}
                    viewId={this.props.viewId}
                    request={this.props.request}
                ></OtherAccount>
            )
        } else {
            return (
                <Loading notFound={true}></Loading>
            )
        }
    }

    renderEditButton() {
        if (this.state.currentView === "myAccount") {
            return (
                <Link push to={"/myprofile?id=profileview"}>
                    <div className='edit-profile-btn'>Edit Profile</div>
                </Link>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    renderSocialMedia() {

        var s = this.props.data('users', this.props.viewId)['socialMedia'];
        var instagramLink = "instagram.com";
        var facebookLink = "facebook.com";
        var twitterLink = "twitter.com";
        if (s['instagram']) {
            if (s['instagram'].includes("@")) {
                instagramLink += '/' + s['instagram'].split("@")[1];
            } else {
                instagramLink += '/' + s['instagram'];
            }
        }
        if (s['facebook']) {
            if (s['facebook'].includes("https://www.")) {
                facebookLink = s['facebook'];
            } else if (s['facebook'].includes("www.")) {
                facebookLink = "https://" + s['facebook'];
            } else {
                facebookLink = "https://www." + s['facebook'];
            }
        }
        if (s['twitter']) {
            if (s['twitter'].includes("@")) {
                twitterLink += '/' + s['twitter'].split("@")[1];
            } else {
                twitterLink += '/' + s['twitter'];
            }
        }
        return (
            <div>
                <div className='social-row'> {/*Clicking on any of the following links should open up the person's profile in their native app.*/}
                    {this.renderInstagram(instagramLink)} {/*if user has registered an instagram link, then display insta icon. If not, then hide icon from profile.*/}
                    {this.renderFacebook(facebookLink)} {/*if user has registered a facebook link, then display facebook icon. If not, then hide icon from profile.*/}
                    {this.renderTwitter(twitterLink)}  {/*if user has registered a twitter link, then display twitter icon. If not, then hide icon from profile.*/}
                </div>
            </div>
        )
    }

    renderInstagram(link) {
        if (link === "instagram.com") {
            return (
                <div></div>
            )
        } else {
            return (
                <a href={"http://www." + link}><img src={instagram} /></a>
            )
        }
    }

    renderFacebook(link) {
        if (link === "facebook.com") {
            return (
                <div></div>
            )
        } else {
            return (
                <a href={link}><img src={facebook} /></a>
            )
        }
    }

    renderTwitter(link) {
        if (link === "twitter.com") {
            return (
                <div></div>
            )
        } else {
            return (
                <a href={"http://www." + link}><img src={twitter} /></a>
            )
        }
    }

    loading() {
        if (!this.props.firebase.auth().currentUser) {
            return true;
        }
        if (!this.props.data('users', this.props.viewId)) {
            return true;
        }
        const u = this.props.data('users', this.props.viewId)['reviews'];
        for (var i in u) {
            if (!this.props.data('users', u[i]['fromId'])) {
                return true;
            }
        }
        return false;
    }

    render() {
        if (!this.loading()) {
            const d = this.props.data('users', this.props.viewId);
            return (
                <div>
                    <NavBar
                        firebase={this.props.firebase}
                        data={this.props.data}
                        view=""
                        emptyCache={this.props.emptyCache}
                    ></NavBar>
                    <div className='profile-page'>
                        <section className='profile-container'>
                            <div className='profile-img-container'>
                                <img className='profile-img' src={d['imageUrl']} />
                            </div>
                            <div className='profile-name'>{d['firstName'] + " " + d['lastName']}</div>
                            <div className='profile-location'>{d['city'] + ", " + d['stateCountry']}</div>
                            <div className='profile-description'>{d['description']}</div>
                            <div className='profile-vouch'><strong>{d['vouche'].length + " people"}</strong> have vouched for <strong>{d['firstName']}</strong>.</div>
                            {this.renderButtons()}
                            <div className='social'>
                                {this.renderSocialMedia()}
                            </div>
                            {this.renderEditButton()}
                        </section>
                        <Reviews
                            firebase={this.props.firebase}
                            data={this.props.data}
                            updateData={this.props.updateData}
                            viewId={this.props.viewId}
                        ></Reviews>
                    </div>
                </div>
            )
        } else {
            return (
                <Loading></Loading>
            )
        }
    }
}

export default Account;