import React from 'react';
import NavBar from '../Navigation';
import Landing1 from './landing1.js';

class Landing extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <body>
                <NavBar
                    firebase = {this.props.firebase}
                    data = {this.props.data}
                    view = "Landing"
                    emptyCache = {this.props.emptyCache}
                ></NavBar>
                <Landing1
                    firebase = {this.props.firebase}
                    request={this.props.request}
                    data = {this.props.data}
                    updateData = {this.props.updateData}
                ></Landing1>
            </body>
        )
    }
}

export default Landing;