import React, { Component } from 'react';
import pending from '../../images/pending.gif';
import exit from '../../images/exit.svg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";



class CancelModal extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
        this.findPaidAmount = this.findPaidAmount.bind(this);
    }

    handleClick(label) {
        console.log(label);
        if (label === 'displayModal') {
            this.props.setDisplayModal(true);
        } else if (label === 'undisplayModal') {
            this.props.setDisplayModal(false);
        } else if (label === 'confirm') {
            if (this.props.user){
                this.props.func(this.props.user);
            } else {
                this.props.func();
            }
        }
    }

    findPaidAmount(guest, tripId) { //TODO: REMOVE DUPLICATE FUNCTION IN RESERVEDVIEW
        if (guest && tripId) {
            let paymentData = this.props.readSubcollection('charge_confirmation', this.props.tripId, 'charges')
            let chargedAmount = 0;

            let refundData = this.props.readSubcollection('refund_confirmation', this.props.tripId, 'refunds')
            let refundAmount = 0;

            if (paymentData) {
                console.log(paymentData)
                paymentData.forEach((entry) => {
                    if ((entry.guest === guest) && (entry.status)) {
                        chargedAmount += parseFloat(entry.amount);
                    }
                })
            }

            if (refundData) {
                console.log(refundData)
                refundData.forEach((entry) => {
                    if ((entry.guest === guest) && (entry.status === "succeeded")) {
                        refundAmount += parseFloat(entry.amount);
                    }
                })
            }
            return (chargedAmount - refundAmount)
        } else {
            return 0
        }
    }

    renderMessage(type) {
        if (type === 'guestCancel') {
            return (
                <body>
                    <div className='od-cancel-page'>
                        <div className='od-cancel-container'>
                            <div className='od-cancel-title'>Are you sure you want to cancel?</div>
                            <div className='od-cancel-text'>You will be refunded ${parseInt(this.findPaidAmount(this.props.currentUser, this.props.tripId), 10)} <br/> if the organizer approves.</div>
                            <div className='od-cancel-btn-row'>
                                <div className='btn-three' onClick={() => this.handleClick('undisplayModal')}>Cancel</div>
                                <div className='btn-one' onClick={() => this.handleClick('confirm')}>Confirm</div>
                            </div>
                            <div className='exit' onClick={() => this.handleClick('undisplayModal')}><img src={exit} /></div>
                        </div>
                    </div>
                </body>
            )

        }

        if (type === 'organizerCancel') {
            return (
                <body>
                    <div className='od-cancel-page'>
                        <div className='od-cancel-container'>
                            <div className='od-cancel-title'>Are you sure you want to cancel on this guest?</div>
                            <div className='od-cancel-text'>The guest will be refunded ${parseInt(this.findPaidAmount(this.props.user, this.props.tripId), 10)}</div>
                            <div className='od-cancel-btn-row'>
                                <div className='btn-three' onClick={() => this.handleClick('undisplayModal')}>Cancel</div>
                                <div className='btn-one' onClick={() => this.handleClick('confirm')}>Confirm</div>
                            </div>
                            <div className='exit' onClick={() => this.handleClick('undisplayModal')}><img src={exit} /></div>
                        </div>
                    </div>
                </body>
            )
        }

        if (type === 'organizerGuestCancelApprove') {
            return (
                <body>
                    <div className='od-cancel-page'>
                        <div className='od-cancel-container'>
                            <div className='od-cancel-title'>Please confirm you approve of this cancellation request</div>
                            <div className='od-cancel-text'>The guest will be refunded ${parseInt(this.findPaidAmount(this.props.user, this.props.tripId), 10)}</div>
                            <div className='od-cancel-btn-row'>
                                <div className='btn-three' onClick={() => this.handleClick('undisplayModal')}>Cancel</div>
                                <div className='btn-one' onClick={() => this.handleClick('confirm')}>Confirm</div>
                            </div>
                            <div className='exit' onClick={() => this.handleClick('undisplayModal')}><img src={exit} /></div>
                        </div>
                    </div>
                </body>
            )
        }

    }



    render() {
        return (
            this.renderMessage(this.props.type)
        )
    }
}

export default CancelModal;