import React, { Component } from 'react'
import '../../css/index.css'
import Loading from '../Loading';
import 'react-day-picker/lib/style.css';
import CheckoutForm from './CheckoutForm'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class Topup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dueNow: 0,
            checkedState: '',
            redirectPath: '',
            advancePage: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.renderDates = this.renderDates.bind(this);        
        this.pay = this.pay.bind(this);
    }

    componentDidMount() {
        let listingData = this.props.listingData
        let userData = this.props.userData
        let currentUserId = this.props.currentUser
        let organizerData
        if (listingData){
            organizerData = this.props.data('users',listingData['tripOrganizerId'])
        }
        this.setState({
            listingData: listingData,
            userData: userData,
            currentUserId: currentUserId,
            organizerData: organizerData,
            duplicate: false
        }) 
    }

    renderMessage() {
        if (this.state.advancePage) {
            var displayString = "Please select: ";
            // if (this.state.duplicate) {
            //     displayString = 'ALREADY REQUESTED SPOTS - PLEASE CANCEL REQUEST FIRST THEN TRY AGAIN';
            // }
            return (
                <div className='incorrect-field'>{displayString}</div>
            )
        } else {
            return (
                <div className='incorrect-field'>&#168;</div>
            )
        }
    }

    pay(nonce) {
        console.log('In Pay')
        //1) request to join the trip TODO: REMOVE JOIN FROM GUESTVIEW
        if (this.state.userData && this.state.listingData && nonce){
            this.setState({advancePage: true})
            
            //TODO: FIGURE OUT HOW TO PROTECT AGAINST ACCIDENTAL DOUBLE PAY

        //2) Pay
        console.log(nonce)
            if (this.props.topUp(this.props.amount, nonce)){
                this.setState({redirectPath: 'confirmation'})
            }
       }
    }   

    renderDates() {
            return (
                <div className='dash-header'>
                    <div className='dash-dates' style={{borderRight: '1px solid #c4c4c4'}}>
                        <div className='dash-text'>Arrive</div>
                        <div>{this.props.formatDateRange(this.state.listingData.fromDate, this.state.listingData.toDate, 'from')}</div>
                    </div>
                    <div className='dash-dates'>
                        <div className='dash-text'>Depart</div>
                        <div>{this.props.formatDateRange(this.state.listingData.fromDate, this.state.listingData.toDate, 'to')}</div>
                    </div>
                </div>
            )
    }

    handleChange(event) {
        if(event.target.value){
            console.log(event.target);
            this.setState({
                dueNow: event.target.value,
                checkedState: event.target.id
            })
        }
    }

    render() {
        if (this.state.redirectPath === 'Login'){
            return (
                <Redirect push to="/login"></Redirect>
            )
        } else if (this.state.redirectPath === 'myTrips'){
            return (
                <Redirect push to="/myTripsNew"></Redirect>
            )
        } else if (this.state.redirectPath === 'confirmation'){
            return (
                <Redirect push to={"/payment/"+this.props.tripId+"/confirmation"}></Redirect>
            )
        }
        if (this.props.firebase.auth().currentUser && this.state.listingData && this.state.userData) {
            return(
                <div>
                    <div>
                        <meta charSet='UTF-8' />
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />
                        <title>Payment</title>
                    </div>
                    <section className='od-page'>
                        <section class='od-container'>
                            <section className='od-details'>
                                {this.renderMessage()}
                                <div className='ld-title'>Payment Details</div>
                                <div className='ld-subtitle'>
                                    Rest easy. Your payment will be securely held until your trip is over.
                                </div>                                  

                                <div>
                                <div onClick={this.handleChange.bind(this)}>
                                </div>
                                </div>
                                <div style={{'max-width':"400px"}}>
                                <CheckoutForm
                                    pay = {this.pay}
                                />
                                </div>
                            </section>
                        </section>
                        <section className='dash-sidebar'>

                            <section className='trip-status'>
                                {this.renderDates()}
                                <div className='trip-info'>
                                    <div className='trip-info-row'>
                                        <div>Total to Pay:</div>
                                        <div>${parseInt(this.props.amount, 10)}</div>
                                    </div>
                                    <div className='ld-cancel-text'>Your funds will not be released to the host until the trip is over</div>
                                    <button id="submit-button" className='request-cancel-2 btn-one'>Pay</button>
                                    <div id='stripe-button'/>
                                </div>
                                <div className='trip-info'>
                                    <div className='ld-cancel-header'>Cancellation Policy</div>
                                    <div className='ld-standard-cancel-text'>All reservations are non-refundable unless approved by the organizer.</div>
                                    {(this.state.listingData.cancellationPolicy.length > 0 &&
                                        <div className='ld-cancel-text'>Additional note from the organizer:
                                    <div className='ld-cancel-text'>{this.state.listingData.cancellationPolicy}</div>
                                    </div>)}
                                </div>
                            </section>

                        </section>
                    </section>
                </div>
            )
        } else {
            return (
                <Loading></Loading>
            )
        }
        
    }
}
export default Topup
