import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Loading from '../Loading';
import { isInteger } from 'formik';


class Headers extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.renderSingleUser = this.renderSingleUser.bind(this);
    }

    renderSingleUser(room) {
        if (!this.props.memberLookup[room]) {
            return (
                <Loading />
            )
        }
        var otherId = '';
        const ids = this.props.memberLookup[room];
        if (ids[0] === this.props.firebase.auth().currentUser.uid) {
            otherId = ids[1];
        } else {
            otherId = ids[0];
        }
        var d;
        if (otherId) {
            d = this.props.data('users', otherId);
        }
        if (d) {
            const lastMessage = this.props.headers[room].lastMessage;
            const id = this.props.headers[room].id;
            var person = '';
            if (id === this.props.firebase.auth().currentUser.uid) {
                person = "You: ";
            } else {
                person = d['firstName'] + " " + d['lastName'] + ": ";
            }
            const timestamp = this.props.headers[room].timestamp;
            //TODO: format
            const aData = timestamp.split(" ");
            const aDate = aData[0].split(":");
            const aTime = aData[1].split(":");

            var read = true;
            const rd = this.props.headers[room]['read'];
            if (rd) {
                if (!rd[this.props.firebase.auth().currentUser.uid]) {
                    read = false
                }
            }
            if (read) {
                return (
                    <div className='msg-contacts-row' onClick={()=>(this.props.resolveClick("SelectUser", room))}>
                        <div className="msg-contacts">
                            <img src={d['imageUrl']} />
                            <div className="msg-contacts-details">                           
                                <div className="msg-contacts-details-name">
                                    {d['firstName'] + " " + d['lastName']}
                                </div>
                                <div className="msg-contacts-details-preview">
                                {person}
                                    {lastMessage}
                                </div>
                            </div>
                        </div>
                        <div className="msg-contacts-details-timestamp">
                            <div>{parseInt(aDate[1]) + 1}/{aDate[2]}/{aDate[0]}</div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className='msg-contacts-row' onClick={()=>(this.props.resolveClick("SelectUser", room))}>
                        <div className="msg-contacts bold">
                            <img src={d['imageUrl']} />
                            <div className="msg-contacts-details">                           
                                <div className="msg-contacts-details-name">
                                    {d['firstName'] + " " + d['lastName']}
                                </div>
                                <div className="msg-contacts-details-preview">
                                {person}
                                    {lastMessage}
                                </div>
                            </div>
                        </div>
                        <div className="msg-contacts-details-timestamp bold">
                            <div>{parseInt(aDate[1]) + 1}/{aDate[2]}/{aDate[0]}</div>
                        </div>
                    </div>
                )
            }
            
        }
    }

    render() {
        if (this.props.headers) {
            return (
                <div className="msg-user-dropdown">
                    {Object.keys(this.props.headers).map(room => (
                        this.renderSingleUser(room)
                    ))}
                </div>
            )
        } else {
            return (
                <div className='msg-empty'>You don't have any active chats</div>
            )
        }
        
        /*
        return (
            
            <div className="msg-user-dropdown">
                                        
                                    
            <div className='msg-user-dropdown-btn' onClick={()=>(this.props.resolveClick("SelectUser", user))}>
                <div className="msg-user-dropdown-element">
                    
                    <img className='msg-user-dropdown-element-img' src={user.userData.imageUrl} />
                    <div className="msg-user-dropdown-element-name">
                        {user.userData.firstName + " " + user.userData.lastName}
                    </div>
                    
                </div>

            </div>
            </div>
        )*/
    }
}

export default Headers;