import React, { Component } from 'react'
import '../../css/index.css'
import next from '../../images/next_arrow.svg'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import Loading from '../Loading';
import NumberFormat from 'react-number-format'
import CurrencyInput from 'react-currency-input'
import Calendar from './Calendar.js'
import 'react-day-picker/lib/style.css';

var fromDate = ""
var toDate = ""

class CListingStage1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listingTitle: '',
            fromDate: '',
            toDate: '',
            events: '',
            description: '',
            availableSpots: 1,
            pricePerSpot: '',
            deposit: '',
            cancellationPolicy: '',
            advanced: false,

            calendarOut: true,
            advancePage: false,
            filledStatus: {
                listingTitle: false,
                date: false,
                events: false,
                description: false,
                pricePerSpot: false,
                deposit: false,
                cancellationPolicy: false,
            },
        }
        this.calendarElement = React.createRef()

        this.pullDict = this.pullDict.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.advancePage = this.advancePage.bind(this);
        this.fieldsIncomplete = this.fieldsIncomplete.bind(this);

        // this.inputFields = this.inputFields.bind(this)
        //this.handleChange = this.handleChange.bind(this)
        
        this.fromCallBack = this.fromCallBack.bind(this)
        this.toCallBack = this.toCallBack.bind(this)
        this.resetCallBack = this.resetCallBack.bind(this)
        this.calendarToggle = this.calendarToggle.bind(this)
        this.saveDateToState = this.saveDateToState.bind(this)
        this.resetDateToState = this.resetDateToState.bind(this)

        this.renderMessage = this.renderMessage.bind(this)
        
    }

    componentWillMount() {
        this.pullDict();
        if (this.props.inputChannel) {
            this.setState({})
        }
    }

    componentDidMount() {
        if (this.props.event) {
            var status = this.state.filledStatus;
            status['events'] = true;
            this.setState({
                events: this.props.event,
                filledStatus: status,
            });
        }
        if (this.props.firebase.auth().currentUser) {
            if (this.state.fromDate !== "") {
                document.getElementById("arrivalDateText").innerHTML = this.state.fromDate
            }
            if (this.state.toDate !== "") {
                document.getElementById("departureDateText").innerHTML = this.state.toDate
            }
        }
        
    }

    renderMessage() {
        if (this.state.advanced) {
            var displayString = "Please fill out: ";
            if (this.state.listingTitle.length === 0) {
                displayString += "Listing Title, ";
            }
            if ((this.state.toDate.length === 0) || (this.state.fromDate.length === 0) ) {
                displayString += "Date, ";
            }
            if (this.state.events.length === 0) {
                displayString += "Events, ";
            }
            if (this.state.description.length === 0) {
                displayString += "Description, ";
            }
            if (this.state.pricePerSpot.length === 0) {
                displayString += "PricePerSpot, ";
            }
            // if (this.state.deposit.length === 0) {
            //     displayString += "Deposit, ";
            // }
            return (
                <div className='incorrect-field'>{displayString}</div>
            )
        } else {
            return (
                <div className='incorrect-field'>&#168;</div>
            )
        }
        
    }

    pullDict() {
        this.setState(this.props.currentDict);
        this.setState({
            calendarOut: true,
            advancePage: false,});
    }

    handleChange(event, label, spotDirection=false) {
        if (label === 'listingTitle') {
            this.setState({listingTitle: event.target.value});
        } else if (label === 'fromDate') {
            this.setState({fromDate: event.target.value});
        } else if (label === 'toDate') {
            this.setState({toDate: event.target.value});
        } else if (label === 'events') {
            this.setState({events: event.target.value});
        } else if (label === 'description') {
            this.setState({description: event.target.value});
        } else if (label === 'availableSpots') {
            if (spotDirection === '+') {
                this.setState({availableSpots: this.state.availableSpots + 1});
            } else if ((spotDirection === '-') && (this.state.availableSpots > 1)) {
                this.setState({availableSpots: this.state.availableSpots - 1});
            }
        } else if (label === 'pricePerSpot') {
            this.setState({pricePerSpot: event.value});
        // } else if (label === 'deposit') {
        //     this.setState({deposit: event.value});
        } else if (label === 'cancellationPolicy') {
            this.setState({cancellationPolicy: event.target.value});
        }
    }

    saveDateToState() {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        })
        document.getElementById("cl-datePicker").style.display = "none"
    }

    resetDateToState() {
        fromDate = ""
        toDate = ""
        this.setState({
            fromDate: "",
            toDate: ""
        })
        this.calendarElement.current.handleClearClick()
        this.resetCallBack()
    }

    resetCallBack() {

        document.getElementById("arrivalDateText").innerHTML = "Arrival"
        document.getElementById("departureDateText").innerHTML = "Departure"
        
    }

    fromCallBack(from) {
        fromDate = from
        document.getElementById("arrivalDateText").innerHTML = fromDate
        
    }

    toCallBack(to) {
        toDate = to
        document.getElementById("departureDateText").innerHTML = toDate
        
    }

    calendarToggle() {
        this.setState({calendarOut: !this.state.calendarOut})
        var calendarToggleBtn = document.getElementById("calendarToggle")
        if (this.state.calendarOut === true) {
            document.getElementById("cl-datePicker").style.display = "flex";
            calendarToggleBtn.style.backgroundColor = "#f5f2f2"
            calendarToggleBtn.style.boxShadow = "0 1px 5px rgba(0, 0, 0, 0.46)"
        } else {
            document.getElementById("cl-datePicker").style.display = "none";
            calendarToggleBtn.style.backgroundColor = "#fff"
            calendarToggleBtn.style.boxShadow = "none"
        }
     
    }

    advancePage() {
        this.setState({advanced: true});
        if (!this.fieldsIncomplete()) {
            document.getElementById("cl-datePicker").style.display = "flex";  
            this.props.recordValues(this.state);
            this.setState({advancePage: true});
        } else {

        }
        
    }

    fieldsIncomplete() {
        var filledStatusDict = this.state.filledStatus;
        var anyIncomplete = false;
        if (this.state.listingTitle.length === 0) {
            filledStatusDict['listingTitle'] = true;
            anyIncomplete = true;
        }
        if ((this.state.fromDate.length === 0) || (this.state.toDate.length === 0)) {
            filledStatusDict['date'] = true;
            anyIncomplete = true;
        }
        if (this.state.description.length === 0) {
            filledStatusDict['description'] = true;
            anyIncomplete = true;
        }
        if (this.state.pricePerSpot.length === 0) {
            filledStatusDict['pricePerSpot'] = true;
            anyIncomplete = true;
        }
        // if (this.state.deposit.length === 0) {
        //     filledStatusDict['deposit'] = true;
        //     anyIncomplete = true;
        // }
        if (this.state.cancellationPolicy.length === 0) {
            //filledStatusDict['cancellationPolicy'] = true;
            //anyIncomplete = true;
        } 
        if (this.state.events.length === 0) {
            filledStatusDict['events'] = true;
            anyIncomplete = true;
        } 
        this.setState({filledStatus: filledStatusDict});
        return anyIncomplete;
    }

    render() {
        if (this.props.firebase.auth().currentUser) {
            if (this.state.advancePage) {
                this.setState({advancePage: false});
                if (this.props.listingEdit){
                    return (
                        <Redirect push to={"/listingedit?id="+this.props.tripId+"&2"}></Redirect>
                    )
                } else {
                    return (
                        <Redirect push to="/listingcreate?id=2"></Redirect>
                    )
                }

            } else {
                return(
                    <div>
                        <div>
                            <meta charSet='UTF-8'/>
                            <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                            <title>Create Listing 1</title>
                        </div>
                            <div>
                                <div className='cl-step1'>
                                    {this.renderMessage()}
                                    <div className='cl-header'>
                                        
                                        <div className='cl-title-field'>
                                            <label>Listing title</label>
                                            <input 
                                                id="listingTitle"
                                                type='text'
                                                value={this.state.listingTitle}
                                                onChange={(e) => this.handleChange(e, "listingTitle")}
                                            />
                                        </div>
                                        
                                        <div className='cl-field'>
                                            <label>Date</label>
                                            <button 
                                                onClick={this.calendarToggle}
                                                className="calendarToggle"
                                                id = "calendarToggle"
                                            >
                                                <div id="btnDateText" className="btnDateText">
                                                    <div id="arrivalDateText"> Arrival </div>
                                                    <div> &#8594; </div>  
                                                    <div id="departureDateText"> Departure </div>
                                                </div>
                                            </button>
                                            <div id="cl-datePicker" className="cl-datePicker">
                                                <div className="cl-datePicker-wrapper">
                                                    <Calendar
                                                        ref = {this.calendarElement}
                                                        id="calendar" 
                                                        className="calendar" 
                                                        fromCallBack={this.fromCallBack}
                                                        toCallBack={this.toCallBack}
                                                        resetCallBack={this.resetCallBack}
                                                        invalid={false}
                                                    ></Calendar>
                                                    <div className="cl-datePicker-footer">
                                                        <button className="cl-datePicker-footer-btn" onClick={this.resetDateToState}>
                                                            Clear
                                                        </button>
                                                        <button className="cl-datePicker-footer-btn" onClick={this.saveDateToState}>
                                                            Save
                                                        </button> 
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
        
                                        
                                        <div className='cl-field'>
                                            <label style={this.props.listingEdit ? {color:'#D3D3D3'} : {color:'#40464B'}}>Event</label>
                                            <select onChange={(e) => this.handleChange(e, "events")} value={this.state.events} disabled={this.props.listingEdit}  style={this.props.listingEdit ? {color:'#D3D3D3'} : {color:'#888888'}}>
                                                <option value="" disabled selected>Select Event</option>
                                                <option value="mytravelfam">Private Event</option>
                                                <option disabled>------------------------</option>
                                                <option value="ultra_miami_2020">Ultra Miami 2020</option>
                                                <option value="coachella_indio_2020">Coachella 2020</option>
                                                <option disabled>------------------------</option>
                                                {/*<option value="paxsouth_sanantonio_2020">Pax South 2020</option>
                                                <option value="paxeast_boston_2020">Pax East 2020</option>
                                                <option value="e3_losangeles_2020">E3 Expo 2020</option>*/}
                                                <option value="twitchcon_sandiego_2020">TwitchCon 2020</option>
                                                <option disabled>------------------------</option>
                                                <option value="snow_gnarnia_2020">Skiing &#38; Snowboarding</option>
                                                {/*<option value="testing">Testing</option>*/}
                                            </select>
                                            <div className='cl-select-icon'>&#9662;</div>
                                        </div>
                                    </div>
                        
                                    
                                    <div className='cl-description'>
                                        <label>Description</label>
                                        <textarea
                                            id="description"
                                            value={this.state.description}
                                            onChange={(e) => this.handleChange(e, "description")}
                                            // autoComplete='on'
                                        />
                                    </div>
                                    
                                    <div className='cl-field'>
                                        <label>How many spots are you looking to fill?</label>
                                        <div className="cl-guest-counter">
                                            <div style={{marginRight: "38px"}}>Available spots: </div>
                                            <button className="svg-btn minus" onClick={(e) => this.handleChange(e, "availableSpots", "-")} />
                                            <div className="cl-guest-num">
                                                {this.state.availableSpots}
                                            </div>
                                            <button className="svg-btn add" onClick={(e) => this.handleChange(e, "availableSpots", "+")} />
                                        </div>
                                    </div>
        
                                    <div className='cl-field'>
                                        <label>Price per Spot</label>
                                        <div>
                                            <NumberFormat thousandSeparator={true} decimalSeparator={false} value={this.state.pricePerSpot} allowNegative={false} prefix={'$'} onValueChange={(e) => this.handleChange(e,"pricePerSpot")}/>
                                        </div>
                                    </div>
                                {/* <div className='cl-field'>
                                    <label>Deposit per Spot</label>
                                    <div>
                                        <NumberFormat thousandSeparator={true} decimalSeparator={false} value={this.state.deposit} allowNegative={false} prefix={'$'} onValueChange={(e) => this.handleChange(e, "deposit")} />
                                    </div>
                                </div> */}

                                    <div className='cl-cancelpol'>
                                        <div className="cl-cancelpol-label">
                                            <label>What's your cancellation policy?</label>
                                            <div className='cl-cancelpol-text'>Our standard cancellation policy is "non-refundable unless approved by the organizer". Would you like to add an additional note below?</div>
                                        </div>
                                        <textarea
                                            id="cancellationPolicy"
                                            value={this.state.cancellationPolicy}
                                            onChange={(e) => this.handleChange(e, "cancellationPolicy")}
                                            Placeholder="(optional message)"
                                        />
                                    </div>
                                
                                
        
                                <div className='cl-footer'>
                                    <div></div>
                                    <div className='cl-next-btn'>
                                        <img src={next} onClick={() => this.advancePage()}/>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <Loading></Loading>
            )
        }
        
    }
}
export default CListingStage1