import React, { Component } from 'react';
import Loading from '../Loading';
import Chat from './messages.js';
import NavBar from '../Navigation';

class MessageView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }
        this.doneLoading = this.doneLoading.bind(this);
    }

    doneLoading() {
        if (this.props.firebase.auth().currentUser) {
            if (this.props.data('users', this.props.firebase.auth().currentUser.uid)) {
                return true;
            }
        }
        return false;
    }

    render() {
        if (this.doneLoading()) {
            return (
                <div>
                    <NavBar
                        firebase = {this.props.firebase}
                        data = {this.props.data}
                        emptyCache = {this.props.emptyCache}
                    ></NavBar>
                    <Chat firebase={this.props.firebase}
                        data={this.props.data}
                        viewId={this.props.viewId}
                        request={this.props.request}
                        />
                </div>
            )
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default MessageView