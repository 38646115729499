
import visa from '../../images/visa.svg'
import mastercard from '../../images/mastercard.svg'
import amex from '../../images/amex.svg'
import discover from '../../images/discover.svg'


import React from 'react';
import ReactDOM from 'react-dom';
import firebase from '../Firebase'

var stripe = window.Stripe('pk_live_I1VUrAJTq6Tqk5e1JEE59npt00zucbhbru')

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount() {

    var elements = stripe.elements();

    var cardNumber = elements.create('cardNumber', {
      classes: {
        base: "pmt-info",
        focus: "pmt-info-focus",
        invalid: "pmt-info-error"
      },
      style: {
        base: {
          color: "#888888",
          fontFamily: "Roboto Light, sans-serif"
        }
      }
    });
    cardNumber.mount('#card-number');

    var cardExpiry = elements.create('cardExpiry', {
      classes: {
        base: "pmt-info",
        focus: "pmt-info-focus",
        invalid: "pmt-info-error"
      },
      style: {
        base: {
          color: "#888888",
          fontFamily: "Roboto Light, sans-serif"
        }
      }
    });
    cardExpiry.mount('#card-expiry');

    var cardCvc = elements.create('cardCvc', {
      classes: {
        base: "pmt-info",
        focus: "pmt-info-focus",
        invalid: "pmt-info-error"
      },
      style: {
        base: {
          color: "#888888",
          fontFamily: "Roboto Light, sans-serif"
        }
      }
    });
    cardCvc.mount('#card-cvc');

    var submitButton = document.querySelector('#submit-button');
    submitButton.addEventListener('click', this.handleSubmit)

    this.setState({ cardElement: cardNumber })

  }

  handleSubmit(evt) {
    console.log('handling submit')
    var submitButton = document.querySelector('#submit-button');
    submitButton.disabled = true
    
    stripe.createToken(this.state.cardElement).then(async (result) => {
      this.props.enableLoading()
      console.log(result);
      if (result.token) {
        console.log(result.token.id);
        let customerId = await this.props.request('payment', {
          type: 'createCustomer',
          source: result.token.id,
          email: this.props.firebase.auth().currentUser.email,
          name: this.props.firebase.auth().currentUser.displayName
        })
        if (customerId.status.error) {
          this.props.setErrorText(customerId.status.error.raw.message)
        } else if (customerId.status) {
          this.props.pay(result.token.id, customerId.status)
        }
      } else {
        console.log(result.error);
        this.props.setErrorText(result.error.message)
      }
    submitButton.disabled = false
    this.props.disableLoading()
    });
  }

  render() {
    return (
      <div>
          <div className="pmt-container">
            <label for="card-number" style={{ color: 'red' }}>{this.props.errorText}</label>
          </div>
        <div className="pmt-container">
          <label for="card-number">Credit or Debit Card</label>
          <div id="card-number"></div>
        </div>
        <div className='pmt-card-graphics-container'>
          <img src={visa} />
          <img src={mastercard} />
          <img src={amex} />
          <img src={discover} />
        </div>
        <div className='pmt-cvc-exp-wrapper'>
          <div className='pmt-cvc-exp-container'>
            <label for="card-expiry">Exp</label>
            <div id="card-expiry"></div>
          </div>
          <div className='pmt-cvc-exp-container'>
            <label for="card-cvc">CVC</label>
            <div id="card-cvc"></div>
          </div>
        </div>
      </div>
    );
  }

}

export default CheckoutForm;
