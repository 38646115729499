import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import profile from '../../images/profile.jpg';
import previous from '../../images/previous.svg';
import photo from '../../images/photo.svg';
import emoji from '../../images/emoji.svg';
import send from '../../images/send.svg';
import firebase from '../Firebase'
import Loading from '../Loading';
import TextMessages from './TextMessages.js';
import Headers from './Headers.js';
import NoChats from './NoChats.js';

class messages extends Component {

    constructor(props) {
        super(props)
        this.state = {
            contacts: [
                {user: "Nathan", profilePic: photo},
                {user: "Yan", profilePic: photo}
            ],
            activeButton: "",
            //messages: [],
            currentUser: "",
            currentUserId: "",
            currentChatId: "",
            createChatButton: false,
            userList: null,
            usersDropDown: false,
            
            headers: [],
            messages: {},
            memberLookup: {},

            existingRoom: false,
            
            redirect: '',
            pullingChatData: true,
            canScroll: false,

            mobileHeader: true,

            noChats: false,
        }
        this.enterKeyDown = this.enterKeyDown.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
        this.getTimeStamp = this.getTimeStamp.bind(this)
        this.isEmpty = this.isEmpty.bind(this)

        this.usersDropdownToggle = this.usersDropdownToggle.bind(this)
        this.populateUserDropdown = this.populateUserDropdown.bind(this)

        this.resolveClick = this.resolveClick.bind(this);
        this.writeMessage = this.writeMessage.bind(this);

        this.loading = this.loading.bind(this);

        this.hookData = this.hookData.bind(this);
        this.memberHook = this.memberHook.bind(this);

        this.moreRecent = this.moreRecent.bind(this);
    }

    populateUserDropdown() {
        if (this.state.userList) {
            var dropDownUserList = []
            this.state.userList.map((user) => {
                dropDownUserList.push(
                    <div className='msg-contacts-row' onClick={()=>(this.resolveClick("SelectUser", user))}>
                        <div className="msg-contacts">
                            
                            <img src={user.userData.imageUrl} />
                            <div className="msg-contacts-details-name">
                                {user.userData.firstName + " " + user.userData.lastName}
                            </div>
                            
                        </div>

                    </div>
                )
            })
            return dropDownUserList
        }
        
        
    }

    usersDropdownToggle() {
        this.setState({usersDropDown: !this.state.usersDropDown})
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false
            }
        }
        return true
    }

    resolveClick(type, var1=false) {
        if (type === "SelectUser") {
            this.resolveClick('MobileToHeader', true);
            var updates = {};
            updates['/headers/' + this.props.viewId + '/read/' + this.props.firebase.auth().currentUser.uid] = true;
            this.props.firebase.database().ref().update(updates);
            const redirect = "/messages?id=" + var1;
            this.setState({redirect: redirect});
            //this.hookData();
        } else if (type === "HookData") {
            this.hookData()
        } else if (type === "ViewAccount") {
            const redirect = "/account?id=" + this.props.firebase.auth().currentUser.uid;
            this.setState({redirect: redirect});
        } else if (type === "MobileToHeader") {
            console.log('largepog');
            if (var1) {
                this.setState({mobileHeader: false});
            } else {
                this.setState({mobileHeader: true});
            }
        }
    }

    memberHook(id) {
        
    }

    moreRecent(a, b) {
        //TODO: format
        const aData = a.split(" ");
        const aDate = aData[0].split(":");
        const aTime = aData[1].split(":");
        const bData = b.split(" ");
        const bDate = bData[0].split(":");
        const bTime = bData[1].split(":");
        if (aDate[0] === bDate[0]) {//year
            if (aDate[1] === bDate[1]) {//month
                if (aDate[2] === bDate[2]) {//day
                    if (aTime[0] === bTime[0]) {//hour
                        if (aTime[1] === bTime[1]) {//min
                            if (aTime[2] === bTime[2]) {//sec
                                return true;
                            } else {
                                return aTime[2] > bTime[2];
                            }
                        } else {
                            return aTime[1] > bTime[1];
                        }
                    } else {
                        return aTime[0] > bTime[0];
                    }
                } else {
                    return aDate[2] > bDate[2];
                }
            } else {
                return aDate[1] > bDate[1];
            }
        } else {
            return aDate[0] > bDate[0];
        }
        
    }

    hookData() {
        const chats = this.props.data('users', this.props.firebase.auth().currentUser.uid)['chatIds'];
        var headers = {};
        
        for (var i in chats) {
            var headersRef = firebase.database().ref('headers/' + chats[i]);
            headersRef.on('value', (snapshot) => { //TODO: REALTIME
                var d = snapshot.val();
                headers[snapshot.key] = d;
                this.setState({headers: headers});
            });
            //ALL MAP TO LAST ELEMENT FOR LOCAL MESSAGES
            //TODO: fix
            //passing into snapshot
            var messagesRef = firebase.database().ref('messages/' + chats[i]);
            messagesRef.on('value', (snapshot) => { //TODO: NOT RENDER ALL
                var d = snapshot.val();
                var messages = this.state.messages;
                messages[snapshot.key] = d;
                this.setState({messages: messages});
                var scroll = document.getElementById("chat-end");
                if (scroll) {
                    document.getElementById("chat-end").scrollIntoView();
                }
                
                
            });
        }
        var membersRef = firebase.database().ref('members/' + this.props.viewId);
        
        membersRef.on('value', (snapshot) => { //TODO: REALTIME
            var d = snapshot.val();
            if (d) {
                /*
                if (d[1] === this.props.firebase.auth().currentUser.uid) {
                    this.setState({otherChatId: d[2]});
                } else {
                    this.setState({otherChatId: d[1]});
                }*/
                this.setState({existingRoom: true});
            }
        });
        for (var i in chats) {
            firebase.database().ref('members/' + chats[i]).on("value", (snapshot) => {
                var d = snapshot.val();
                var memberLookup = this.state.memberLookup;
                memberLookup[snapshot.key] = [];
                memberLookup[snapshot.key].push(d[1]);
                memberLookup[snapshot.key].push(d[2]);
                this.setState({memberLookup: memberLookup});
                this.setState({pullingChatData: false});
            });
        }
        if (chats) {
            if ((chats.length === 0)) {
                this.setState({pullingChatData: false});
                this.setState({noChats: true});
            } else {
                this.setState({noChats: false});
            }
        }
        
        //TODO: pull previously talked to person
        //this.pullMessageData(this.props.viewId);
    }

    componentWillMount() {
        this.hookData();
    }
 
    getTimeStamp() {
        var today = new Date();
        var date = (today.getFullYear() < 10 ? "0" + today.getFullYear() : today.getFullYear()) + ":" +
                    (today.getMonth() < 10 ? "0" + today.getMonth() : today.getMonth()) + ":" +
                    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate())
        var time = (today.getHours() < 10 ? "0" + today.getHours() : today.getHours())+ ":" + 
                    (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes()) + ":" + 
                    (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())

        var dateTime = date+' '+time
        return dateTime
    }

    writeMessage(messageData) {
        var otherId = this.props.viewId.split("%3Cp%3E");
        if (otherId[0] === this.props.firebase.auth().currentUser.uid) {
            otherId = otherId[1];
        } else {
            otherId = otherId[0];
        }
        const index = this.state.headers[this.props.viewId]['count'];
        var updates = {};
        updates['/headers/' + this.props.viewId + '/count'] = index + 1;
        updates['/headers/' + this.props.viewId + '/id'] = messageData.id;
        updates['/headers/' + this.props.viewId + '/timestamp'] = messageData.timeStamp;
        updates['/headers/' + this.props.viewId + '/lastMessage'] = messageData.message;
        updates['/headers/' + this.props.viewId + '/read/' + this.props.firebase.auth().currentUser.uid] = true;
        updates['/headers/' + this.props.viewId + '/read/' + otherId] = false;


        updates['/messages/' + this.props.viewId + '/' + index] = messageData;
        this.props.firebase.database().ref().update(updates);
        const dayOld = this.state.headers[this.props.viewId]['timestamp'];
        const dayNew = messageData.timeStamp;
        if (dayOld && dayNew) {
            const dayDiff = parseInt(dayOld.split(" ")[0].split(":")[2]) - parseInt(dayNew.split(" ")[0].split(":")[2])
            if (true) { // dayDiff != 0
                console.log("Sending email to: " + otherId);
                this.props.request('notification', {
                    category: 'newMessage',
                    type: 'NewMessage',
                    uid: otherId
                })
            }
            //send email notification to email of otherId
        }
        //this.hookData(); 
    } 

    sendMessage() {
        var dateTime = this.getTimeStamp()
        var newMessage = document.getElementById("msg-input").value
        if (newMessage !== "") {
            const messageData = {
                id: this.props.firebase.auth().currentUser.uid,
                message: newMessage,
                timeStamp: dateTime,
            }
            this.writeMessage(messageData);
            //this.setState({canScroll: true});
            //this.setState({messages: [...this.state.messages, messageData]})

            document.getElementById("chat-end").scrollIntoView();
        }
        document.getElementById("msg-input").value = ""
    }

    enterKeyDown(e) {
        if (e.keyCode === 13) {
            document.getElementById('btn-send').click()
        }
    }

    loading(type) {
        if (this.state.pullingChatData) {
            return true;
        }
        if (type === "page") {
            return !this.state.memberLookup;
        } else if (type === "content") {
            return !this.state.existingRoom;
        } else if (type === "headers") {
            if ((this.state.headers.length === 0) && (this.state.noChats)) {
                return true;
            } else {
                return false;
            }
        }
    }
    
    render() {
        if (this.state.redirect) {
            const v = this.state.redirect;
            this.setState({redirect: ''});
            return (
                <Redirect push to={v} />
            )
        } else if (this.props.viewId === 'TODO-PREV') {
            if (this.state.pullingChatData) {
                return (
                    <Loading />
                )
            } else {
                
                const h = this.state.headers;
                var maxTime = "2000:01:01 01:01:12";
                var maxId = '';
                for (var v in h) {
                    const c = h[v];
                    if (c.timestamp) {
                        if (c.timestamp.split(" ")) {
                            if (this.moreRecent(c.timestamp, maxTime)) {
                                maxTime = c.timestamp;
                                maxId = v;
                            }
                        }
                    }
                }
                return (
                    <Redirect to={"/messages?id=" + maxId}/>
                )
            } 
        } else if (this.loading("headers")) {
            if (this.state.noChats) {
                return (
                    <div>
                        <NoChats />
                    </div>
                )
            } else {
                return (
                    <div>
                        <Loading />
                    </div>
                )
            }
        } else if (this.loading("page")) {
            return(
                <section className='msg-page'>
                    <section className='msg-contacts-section'>
                        <div className='msg-contacts-header-container'>
                            <div className='msg-contacts-header'>
                                <img src={this.props.data('users', this.props.firebase.auth().currentUser.uid)['imageUrl']} onClick={()=>this.resolveClick("ViewAccount")}/>
                                <div className='msg-header-item'>Messages</div>  
                                <div className='msg-header-placeholder'></div>                                        
                            </div>
                            {/*<div className='msg-contacts-search-container'>
                                <input
                                    className='msg-contacts-search-input'
                                    placeholder='Search messages'
                                    onClick={this.usersDropdownToggle}
                                >
                                </input>
                            </div>*/}
                        </div>
                        <Headers 
                            firebase={firebase}
                            headers={this.state.headers}
                            memberLookup={this.state.memberLookup}
                            resolveClick={this.resolveClick}
                            data={this.props.data}
                        />
                    </section>
                    <Loading />
                </section>
    
            )
        } else {
            if (this.props.viewId) {
                var otherId = this.props.viewId.split("%3Cp%3E");
                if (otherId[0] === this.props.firebase.auth().currentUser.uid) {
                    otherId = otherId[1];
                } else {
                    otherId = otherId[0];
                }
            }
            return(

                <section className='msg-page'>
                    <section className='msg-contacts-section'>
                        <div className='msg-contacts-header-container'>
                            <div className='msg-contacts-header'>
                                <img src={this.props.data('users', this.props.firebase.auth().currentUser.uid)['imageUrl']} onClick={()=>this.resolveClick("ViewAccount")}/>
                                <div className='msg-header-item'>Messages</div>
                                <div className='msg-header-placeholder'></div>
                            </div>
                            {/*<div className='msg-contacts-search-container'>
                                <input
                                    className='msg-contacts-search-input'
                                    placeholder='Search messages'
                                    onClick={this.usersDropdownToggle}
                                >
                                </input>
                            </div>*/}
                        </div>
                        <Headers 
                            firebase={firebase}
                            headers={this.state.headers}
                            memberLookup={this.state.memberLookup}
                            resolveClick={this.resolveClick}
                            data={this.props.data}
                        />
                    </section>
                
                    <TextMessages
                        firebase={this.props.firebase}
                        otherChatId={otherId}
                        headers={this.state.headers}
                        messages={this.state.messages}
                        data={this.props.data}
                        viewId={this.props.viewId}
                        enterKeyDown={this.enterKeyDown}
                        sendMessage={this.sendMessage}
                        resolveClick={this.resolveClick}
                        mobileHeader={this.state.mobileHeader}
                        />
                </section>
    
            )   
        }
    }
}

export default messages;