import secure from '../../images/secure.svg';

import React, { Component } from 'react'
import '../../css/index.css'
import Loading from '../Loading';
import 'react-day-picker/lib/style.css';
import CheckoutForm from './CheckoutForm'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class Payment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dueNow: 0,
            checkedState: '',
            redirectPath: '',
            advancePage: false,
            errorText: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.renderDates = this.renderDates.bind(this);
        this.pay = this.pay.bind(this);
        this.enableLoading = this.enableLoading.bind(this);
        this.disableLoading = this.disableLoading.bind(this);
        this.setErrorText = this.setErrorText.bind(this);

    }

    componentDidMount() {

        window.scrollTo(0, 0);

        let listingData = this.props.listingData
        let userData = this.props.userData
        let currentUserId = this.props.currentUser
        let organizerData
        
        if (listingData) {
            organizerData = this.props.data('users', listingData['tripOrganizerId'])
        }
        this.setState({
            listingData: listingData,
            userData: userData,
            currentUserId: currentUserId,
            organizerData: organizerData,
            duplicate: false
        })
        if (this.props.listingData && this.props.requestedSpots){
            this.setState({
                dueNow: parseInt(this.props.listingData.pricePerSpot.replace(/,/g, ''), 10) * parseInt(this.props.requestedSpots, 10)
            })
        }
    }
    
    enableLoading(){
        console.log('Setting state to loading')
        this.setState({loading: true})
    }

    setErrorText(text){
        console.log('Setting error text '+text)
        this.setState({errorText: text})
    }

    disableLoading(){
        console.log('Switching off state from loading')
        this.setState({loading: false})
    }

    renderMessage() {
        if (this.state.advancePage) {
            var displayString = "Please select: ";
            if (this.state.dueNow < this.state.listingData.deposit) {
                displayString += "payment amount";
            }
            if (this.state.duplicate) {
                displayString = 'ALREADY REQUESTED SPOTS - PLEASE CANCEL REQUEST FIRST THEN TRY AGAIN';
            }
            return (
                <div className='incorrect-field'>{displayString}</div>
            )
        } else {
            return (
                <div className='incorrect-field'>&#168;</div>
            )
        }
    }

    pay(nonce, customerId) {
        //1) request to join the trip
        if (this.state.userData && this.state.listingData && nonce && customerId) {
            this.setState({ advancePage: true })
            //CHECK NUMBER OF SPOTS ARE AVAILABLE
            if (this.props.requestedSpots > this.state.listingData.availableSpots) {
                return this.setErrorText('Not enough spots available. Only ' + this.state.listingData.availableSpots + ' spots left')
            }

            //CHECK SELECTION WAS MADE
            if (parseFloat(this.state.dueNow) < parseFloat(this.state.listingData.deposit)) {
                return this.setErrorText('SELECT PAYMENT AMOUNT')
            }

            //Check if already requested spots

            Object.values(this.state.listingData.requestedGuests).forEach((value) => {
                if (value.requestedUserId === this.state.currentUserId) {
                    this.setState({ duplicate: true })
                }
            })

            Object.values(this.state.listingData.reservedGuests).forEach((value) => {
                if (value.reservedUserId === this.state.currentUserId) {
                    this.setState({ duplicate: true })
                }
            })

            if (this.state.duplicate) {
                return this.setErrorText('ALREADY REQUESTED SPOTS - PLEASE CANCEL REQUEST FIRST THEN TRY AGAIN')
            }

            //2) Join
            if (this.props.joinTrip(this.state.dueNow, nonce, customerId)) {
                this.setState({ redirectPath: 'confirmation' })
            }
        }
    }

    renderDates() {
        return (
            <div className='dash-header'>
                <div className='dash-dates' style={{ borderRight: '1px solid #c4c4c4' }}>
                    <div className='dash-text'>Arrive</div>
                    <div>{this.props.formatDateRange(this.state.listingData.fromDate, this.state.listingData.toDate, 'from')}</div>
                </div>
                <div className='dash-dates'>
                    <div className='dash-text'>Depart</div>
                    <div>{this.props.formatDateRange(this.state.listingData.fromDate, this.state.listingData.toDate, 'to')}</div>
                </div>
            </div>
        )
    }

    handleChange(event) {
        if (event.target.value) {
            console.log(event.target);
            this.setState({
                dueNow: event.target.value,
                checkedState: event.target.id
            })
        }
    }

    render() {
        if (this.state.loading){
            console.log('State is loading')
            return(
                <Loading></Loading>
            )
        }
        if (this.state.redirectPath === 'Login') {
            return (
                <Redirect push to="/login"></Redirect>
            )
        } else if (this.state.redirectPath === 'myTrips') {
            return (
                <Redirect push to="/myTripsNew"></Redirect>
            )
        } else if (this.state.redirectPath === 'confirmation') {
            return (
                <Redirect push to={"/payment/" + this.props.tripId + "/confirmation"}></Redirect>
            )
        }
        if (this.props.firebase.auth().currentUser && this.state.listingData) {
            return (
                <div>
                    <div>
                        <meta charSet='UTF-8' />
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />
                        <title>Payment</title>
                    </div>
                    <section className='od-page'>
                        <section class='od-container'>
                            <section className='od-details'>
                                {this.renderMessage()}
                                <div className='ld-title'>
                                    Payment Details
                                    <img src={secure}/>
                                </div>
                                <div className='ld-subtitle'>
                                    Your payment will be securely processed.
                                </div>

                                {/* <div>
                                    <div onClick={this.handleChange.bind(this)}>
                                        <div style={{ margin: "20px" }}>
                                            <input type="radio" id="pay-full" name="radio-group" checked={this.state.checkedState === 'pay-full'} value={parseInt(this.state.listingData.pricePerSpot.replace(/,/g, ''), 10) * parseInt(this.props.requestedSpots, 10)} />
                                            <label for="pay-full">Pay in full: ${parseInt(this.state.listingData.pricePerSpot.replace(/,/g, ''), 10) * parseInt(this.props.requestedSpots, 10)}</label>
                                        </div>
                                        <div style={{ margin: "20px" }}>
                                            <input type="radio" id="pay-deposit" name="radio-group" checked={this.state.checkedState === 'pay-deposit'} value={parseInt(this.state.listingData.deposit, 10) * parseInt(this.props.requestedSpots, 10)} />
                                            <label for="pay-deposit">Pay deposit: ${parseInt(this.state.listingData.deposit, 10) * parseInt(this.props.requestedSpots, 10)}</label>
                                        </div>
                                    </div>
                                </div> */}
                                <div style={{ 'max-width': "400px", 'padding': '15px' }}>
                                    <CheckoutForm
                                        pay={this.pay}
                                        firebase={this.props.firebase}
                                        request={this.props.request}
                                        enableLoading={this.enableLoading}
                                        disableLoading={this.disableLoading}
                                        setErrorText={this.setErrorText}
                                        errorText={this.state.errorText}
                                    />
                                </div>
                                <div className='ld-subtitle'>You will not be charged until the host accepts your request</div>
                                <div className='pmt-btn'>
                                    <button id="submit-button" className='btn-one'>Request to Join</button>
                                </div>
                            </section>
                        </section>
                        <section className='dash-sidebar'>

                            <section className='trip-status'>
                                {this.renderDates()}
                                <div className='trip-info'>
                                    <div className='trip-info-row'>
                                        <div>Requested Spots:</div>
                                        <div>
                                            {this.props.requestedSpots}
                                        </div>
                                    </div>
                                    <div className='trip-info-row'>
                                        <div>Price per Spot:</div>
                                        <div>${this.state.listingData.pricePerSpot}</div>
                                    </div>

                                    <div className='trip-info-row'>
                                        <div>Total:</div>
                                        <div>${parseInt(this.state.listingData.pricePerSpot.replace(/,/g, ''), 10) * parseInt(this.props.requestedSpots, 10)}</div>
                                    </div>

                                    <div className='trip-info-row'>
                                        <div>Due Now:</div>
                                        <div>${parseInt(this.state.dueNow, 10)}</div>
                                    </div>

                                    {/* <div className='trip-info-row'>
                                        <div>Due by [{this.props.formatDateRange(this.state.listingData.fromDate, this.state.listingData.toDate, 'from')}]:</div>
                                        <div>${parseInt(this.state.listingData.pricePerSpot.replace(/,/g, ''), 10) * parseInt(this.props.requestedSpots, 10) - this.state.dueNow}</div>
                                    </div> */}
                                    <div id='stripe-button' />
                                </div>
                            </section>

                            <section className='ld-cancel-container'>
                                <div className='ld-cancel-header'>Cancellation Policy</div>
                                <div className='ld-standard-cancel-text'>All reservations are non-refundable unless approved by the organizer.</div>
                                {(this.state.listingData.cancellationPolicy.length > 0 &&
                                    <div className='ld-cancel-text'>Additional note from the organizer:
                                <div className='ld-cancel-text'>{this.state.listingData.cancellationPolicy}</div>
                                    </div>)}
                            </section>

                        </section>
                    </section>
                </div>
            )
        } else {
            return (
                <Loading></Loading>
            )
        }

    }
}
export default Payment
