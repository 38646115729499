import React, {Component} from 'react';
import logo from '../../images/logo.svg';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class WaitlistConfirm extends Component {

    render() {
            if (this.props.phone.length === 0) {
                return (
                    <Redirect to="/signup?id=1"></Redirect>
                )
            } else {
                return(
                    <div>
                        <head>
                            <meta charSet='UTF-8'/>
                            <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                            <title>signup2</title>
                        </head>
                        <body>
                            <section className='log-page'>
                                <section className='log-content'>
                                    <div className='log-logo'><img src={logo}/></div>
                                    <div className='log-header'>We've got you<br/>on the waitlist!</div>
                                    <div className='log-subheader-text' style={{textAlign:'center', marginTop:'0px'}}>Someone from TravelFam<br/>will be in touch when we have<br/>an account ready for you!</div>
                                </section>
                            </section>
                        </body>
                    </div>
                ) 
            }
        }  
    }


export default WaitlistConfirm;