import React, { Component } from 'react';
import profile from '../../images/profile.jpg';
import example from '../../images/example.jpg';
import map from '../../images/map.png';
import accept from '../../images/accept.svg';
import decline from '../../images/decline.svg';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import firebase from '../Firebase';
import Loading from '../Loading';


import '../../css/index.css'
import { getDefaultWatermarks } from 'istanbul-lib-report';

var database = firebase.firestore()
class myTripsNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            myTripsIdArray: [],
            myTripsData: [],
            currentUserId: ""
        }
        this.pullUserData = this.pullUserData.bind(this);
        this.pullTripData = this.pullTripData.bind(this);
        this.includesGuests = this.includesGuests.bind(this);
        this.eventData = this.eventData.bind(this);
        this.renderImage = this.renderImage.bind(this);
        this.renderChannelDisplay = this.renderChannelDisplay.bind(this);
        this.ownerData = this.ownerData.bind(this);
        this.renderMemories = this.renderMemories.bind(this);
        this.renderMemory = this.renderMemory.bind(this);
        this.renderTrip = this.renderTrip.bind(this);
    }

    componentDidMount() {
        this.pullUserData()
        if (this.props.stripeCode && this.props.paramName === "code") {
            this.props.writeData('submerchant_onboard', {
                stripeCode: this.props.stripeCode,
                organizerId: this.props.firebase.auth().currentUser.uid,
                timestamp: Math.floor(Date.now() / 1000),
                status: 'pending'
            })
            this.setState({ redirectAddress: '/myTripsNew' })
        }
    }

    //pulls myTripIds from user data
    pullUserData() {

        if (this.props.firebase.auth().currentUser) {
            var tripIds = []
            var currentUserId = this.props.firebase.auth().currentUser.uid
            this.setState({ currentUserId: currentUserId })
            var currentUserData = this.props.data("users", currentUserId)
            if (currentUserData) {
                var currentUserMyTrips = currentUserData["myTrips"]
                currentUserMyTrips.map((tripData) => {
                    tripIds.push(tripData.tripId)
                })
                this.setState({
                    myTripsIdArray: tripIds
                })
                this.pullTripData(tripIds)
            }
        }
    }

    pullTripData(myTripsIdArray) {
        var tripDataArray = []
        myTripsIdArray.map((tripId) => {
            var tripData = this.props.data("listings", tripId)

            if (tripData) {
                tripData["tripId"] = tripId
                tripDataArray.push(tripData)
            }
        })

        this.setState({
            myTripsData: tripDataArray
        })


    }

    renderImage(tripOrganizerId) {
        if (this.props.data('users', tripOrganizerId)) {
            return (
                <div className="mt-avatar-img"><img src={this.props.data('users', tripOrganizerId)['imageUrl']}></img></div>
            )
        } else {
            return (
                <Loading></Loading>
            )
        }
    }

    renderChannelDisplay(events) {
        if (this.props.data('channels', events)) {
            if (this.props.data('channels', events)['display']) {
                return (
                    <div className="mt-event-label">{this.props.data('channels', events)['display'].split('<p>')[0]}</div>
                )
            }
        } else {
            return (
                <Loading></Loading>
            )
        }
    }

    includesGuests(arrayOfMaps, userId) {
        for (var i = 0; i < arrayOfMaps.length; i++) {
            if (arrayOfMaps[i].requestedUserId == userId) {
                return true
            } else if (arrayOfMaps[i].reservedUserId == userId) {
                return true
            } else if (arrayOfMaps[i].cancelUserId == userId) {
                return true
            }
        }
        return false
    }

    eventData() {
        for (var i in this.state.myTripsData) {
            if (!this.props.data('channels', this.state.myTripsData[i]['events'])) {
                return false;
            }
        }
        return true;
    }

    ownerData() {
        for (var i in this.state.myTripsData) {
            const id = this.state.myTripsData[i]['tripOrganizerId'];
            if (!this.props.data('users', id)) {
                return false;
            }
        }
        return true;
    }

    renderMemory(tripId, requestedGuests, reservedGuests, cancelRequested, listingTitle, fromDate, toDate, description, tripOrganizer, tripOrganizerId, imageUrlList, events, active) {
        if (this.props.isMemory(toDate)) {
            return (
                <section className='mt-memories-content'>
                    <div className='mt-img-container'>
                        <img src={imageUrlList[0]} />
                        {this.renderChannelDisplay(events)}
                    </div>
                    <div className='mt-organizer-container'>
                        <div className='mt-trip-title'>{listingTitle}</div>
                        <div className='mt-trip-organizer'>
                            <div className='mt-avatar-container'>
                                {this.renderImage(tripOrganizerId)}
                                <div className='mt-avatar-details'>
                                    <div className="mt-avatar-name">{this.props.data('users', tripOrganizerId)['firstName']} {this.props.data('users', tripOrganizerId)['lastName']}</div>
                                    <div className="mt-avatar-location">{this.props.data('users', tripOrganizerId)['city']}, {this.props.data('users', tripOrganizerId)['stateCountry']}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-trip-details'>
                        <div className='mt-feedback-row'>
                            <Link className='btn-one' push to={'/review?id=' + tripId}>Leave a comment</Link>
                        </div>
                    </div>
                    {/* {this.props.firebase.auth().currentUser.uid === tripOrganizerId && <div className='mt-trip-details'>
                        <div className='mt-feedback-row'>
                            <Link className='btn-one' push to={'/myTripsDetails/' + tripId + '/' + this.props.firebase.auth().currentUser.uid}>Manage Completed Trip</Link>
                        </div>
                    </div>} */}
                </section>
            )
        }
    }

    renderMemories() {

        return (
            <section className='mt-container'>
                <div className='mt-header'>
                    <div className='mt-current-title'>Memories</div>
                </div>

                <div className='mt-trips-container'>
                    {this.state.myTripsData.map(({ tripId, requestedGuests, reservedGuests, cancelRequested, listingTitle, fromDate, toDate, description, tripOrganizer, tripOrganizerId, imageUrlList, events, active }) => (
                        this.renderMemory(tripId, requestedGuests, reservedGuests, cancelRequested, listingTitle, fromDate, toDate, description, tripOrganizer, tripOrganizerId, imageUrlList, events, active)

                    ))
                    }
                </div>
            </section>
        )
    }

    renderTrip(tripId, requestedGuests, reservedGuests, cancelRequested, listingTitle, fromDate, toDate, description, tripOrganizer, tripOrganizerId, imageUrlList, events, active) {
        console.log(toDate);
        if (!this.props.isMemory(toDate)) {
            return (
                <section className='mt-content fadein'>
                    <Link to={{
                        pathname: "/myTripsDetails/" + tripId + "/" + tripOrganizerId
                    }}>
                        <div className="mt-img-container">
                            <img src={imageUrlList[0]} />
                            {this.renderChannelDisplay(events)}
                        </div>
                        <div className="mt-organizer-container">
                            <div className="mt-trip-title">{listingTitle}</div>
                            <div className="mt-trip-organizer">
                                <div className="mt-avatar-container">
                                    {this.renderImage(tripOrganizerId)}
                                    {/* {
                                        this.props.data('users', tripOrganizer)['imageUrl']
                                    } */}
                                    <div className='mt-avatar-details'>

                                        <div className="mt-avatar-name">{this.props.data('users', tripOrganizerId)['firstName']} {this.props.data('users', tripOrganizerId)['lastName']}</div>
                                        <div className="mt-avatar-location">{this.props.data('users', tripOrganizerId)['city']}, {this.props.data('users', tripOrganizerId)['stateCountry']}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-trip-details">
                            <div className="mt-details-row">
                                <div>Status</div>
                                <div className='org-tag'
                                    style={{
                                        backgroundColor:
                                            (this.state.currentUserId === tripOrganizerId) ? ("#3d98ff") :
                                                (this.includesGuests(requestedGuests, this.state.currentUserId)) ? ("#51F26C") :
                                                    (this.includesGuests(cancelRequested, this.state.currentUserId)) ? ("#FF4646") :
                                                        (this.includesGuests(reservedGuests, this.state.currentUserId)) ? ("#1BC938") :
                                                            ""
                                    }}>

                                    {
                                        (this.state.currentUserId === tripOrganizerId) ? "Organizer" :
                                            (this.includesGuests(requestedGuests, this.state.currentUserId)) ? "Join Requested" :
                                                (this.includesGuests(cancelRequested, this.state.currentUserId)) ? "Cancel Requested" :
                                                    (this.includesGuests(reservedGuests, this.state.currentUserId)) ? "Reserved" :
                                                        ""

                                    }

                                </div>
                            </div>
                            <div className="mt-details-row">
                                <div>Arrive</div>
                                <div>{this.props.formatDateRange(fromDate, toDate, 'from')}</div>
                            </div>
                            <div className="mt-details-row">
                                <div>Depart</div>
                                <div>{this.props.formatDateRange(fromDate, toDate, 'to')}</div>
                            </div>
                        </div>
                    </Link>
                </section>
            )
        } else {
            return (
                <div></div>
            )
        }

    }


    render() {
        if (this.state.redirectAddress) {
            return (
                <Redirect to={this.state.redirectAddress} />
            )
        }
        if (this.state.myTripsData && this.eventData() && this.ownerData()) {
            return (
                <div>
                    <head>
                        <meta charSet='UTF-8' />
                        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />
                        <title>My Trips</title>
                    </head>

                    <body>
                        <section className='mt-page'>
                            <section className='mt-container'>
                                <div className='mt-header'>
                                    <div className='mt-current-title'>Current Trips</div>
                                </div>

                                <div className='mt-trips-container'>
                                    {this.state.myTripsData.map(({ tripId, requestedGuests, reservedGuests, cancelRequested, listingTitle, fromDate, toDate, description, tripOrganizer, tripOrganizerId, imageUrlList, events, active }) => (
                                        this.renderTrip(tripId, requestedGuests, reservedGuests, cancelRequested, listingTitle, fromDate, toDate, description, tripOrganizer, tripOrganizerId, imageUrlList, events, active)

                                    ))
                                    }
                                </div>
                            </section>
                            {this.renderMemories()}
                        </section>





                    </body>
                </div>
            )
        } else {
            return (
                <Loading></Loading>
            )
        }
    }
}

export default myTripsNew;