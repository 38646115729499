import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import ReactEncrypt from 'react-encrypt'

import * as serviceWorker from './serviceWorker';

import App from './components/App';

import NavBar from './components/Navigation/NavBar';

const encryptKey="ewfWE@#%$rfdsefgdsf";


ReactDOM.render(<ReactEncrypt
    encryptKey={encryptKey}
  ><App /></ReactEncrypt>, document.getElementById('root'));

serviceWorker.unregister();