import React, { Component } from 'react'
import firebase from '../Firebase'
import '../../css/index.css'
import Loading from '../Loading'
import OtherAccount from '../Account/OtherAccount.js';

class JoinReqView extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            
        }

        this.requestCancelFromJoin = this.requestCancelFromJoin.bind(this)
        this.findRequestedSpots = this.findRequestedSpots.bind(this)
       
    }

    componentDidMount() {
        this.setState({
            listingData: this.props.listingData,
            userData: this.props.userData,
            currentUserId: this.props.currentUserId,
            tripId: this.props.tripId
        })
    }

    findRequestedSpots() {
        if (this.state.listingData) {
            console.log(this.state.currentUserId)
            
            for (var i = 0; i < this.state.listingData.requestedGuests.length; i++) {
                if (this.state.listingData.requestedGuests[i].requestedUserId == this.state.currentUserId) {
                    return this.state.listingData.requestedGuests[i].requestedSpots
                }
            }

        }
    }


    requestCancelFromJoin() {
        if (this.state.userData) {
            var currentTripId = this.state.tripId
            var currentMyTrips = this.state.userData["myTrips"]
            console.log(currentMyTrips)
            currentMyTrips = currentMyTrips.filter(function(tripData) {
                if (tripData.tripId !== currentTripId) {
                    return tripData
                }
            })
            this.props.updateData("users", this.state.currentUserId, "myTrips", currentMyTrips)
            console.log(currentMyTrips)
            var currentRequestedGuests = this.state.listingData.requestedGuests
            var currentUserId = this.state.currentUserId
            currentRequestedGuests = currentRequestedGuests.filter(function(requestData) {
                if (requestData.requestedUserId !== currentUserId) {
                    return requestData
                }
            })
            this.props.updateData("listings", this.state.tripId, "requestedGuests", currentRequestedGuests)
        }

    }


    render() {
        if (this.state.listingData) {
            return(
                
                <section className='dash-sidebar'>

                    <section className='trip-status'>
                        {this.props.renderDates()}
                        <div className='trip-info'>
                            <div className='trip-info-row'>
                                <div>Booking Status:</div>
                                <div>
                                    <div className='join-tag'>
                                        Join Requested
                                    </div>
                                </div>
                            </div>
                            
                            <div className='trip-info-row'>
                                <div>Requested Spots:</div>
                                <div>
                                    {this.findRequestedSpots()}
                                </div>
                            </div>
                            
                            <div className='trip-info-row'>
                                <div>Price per Spot:</div>
                                <div>${this.state.listingData.pricePerSpot}</div>
                            </div>
                            
                            <div className='trip-info-row'>
                                <div>Total:</div>
                                <div>${parseInt(this.state.listingData.pricePerSpot.replace(/,/g, ''), 10) * parseInt(this.findRequestedSpots(), 10)}</div>
                            </div>
                            <div className='ld-btn-container'>
                                <OtherAccount
                                    firebase = {this.props.firebase}
                                    data = {this.props.data}
                                    request = {this.props.request}
                                    updateData = {this.props.updateData}
                                    writeData = {this.props.writeData}
                                    viewId = {this.state.listingData['tripOrganizerId']}
                                    buttonOnly = {true}
                                />
                            </div>
                        </div>
                    </section>

                    <section className='trip-manage'>
                        <div className='trip-manage-header'>Manage your Request</div>
                        <div className='trip-manage-area'>
                            <div className='trip-manage-msg'>The organizer has not accepted your request yet. Cancel your request for free at any time.</div>
                            <div className='request-cancel-2 btn-one' onClick={this.requestCancelFromJoin}>Cancel</div>
                        </div>

                    </section>

                </section>

            )
        } else {
            return (
                <Loading/>
            )
        }
    }
}

export default JoinReqView