import React, {Component} from 'react';

import example from '../../images/example.jpg';

import map from '../../images/map.png'
import NavBar from '../Navigation';
import Loading from '../Loading';
import Map from '../Map/index.js'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

var listingCoords = null
class Channels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            channel: "",
            displayName: '',
            displayDates: 'dates',
            displayLocation: 'location',
            displayImage: '',
            loading: true,
            listingCoords: null,
            hoveredId: null,
            selectedListing: null,

        };
        this.currentListings = this.currentListings.bind(this);
        this.renderListings = this.renderListings.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this)
        this.handleMoueLeave = this.handleMoueLeave.bind(this)
        this.scrollToListing = this.scrollToListing.bind(this)
        this.listingFull = this.listingFull.bind(this);
    }

    scrollToListing(listingId) {
        this.setState({selectedListing: listingId})
        var listingElement = document.getElementById("listing_" + listingId)
        listingElement.scrollIntoView({behavior: 'smooth'})
    }

    handleMouseEnter(listingId) {
        this.setState({hoveredId: listingId})

    }

    handleMoueLeave() {
        this.setState({hoveredId: null})
    }

    currentListings() {
        var listingCoordsHolder = []
        var keys = Object.keys(this.props.data('channels', this.props.channel));
        //take out display field (and others?)
        if (keys.length === 1) {
            return 1;
        }
        for (var i = 0; i < keys.length; i += 1) {
            if (keys[i] !== 'display') {
                if (!this.props.data('listings', keys[i])) {
                    return false;
                } else {
                    if (parseInt(this.props.data('listings', keys[i])["availableSpots"], 10) !== 0) {
                        listingCoordsHolder.push({
                            listingId: keys[i],
                            lat: this.props.data('listings', keys[i])['address'][1], 
                            lng: this.props.data('listings', keys[i])['address'][2]
                        })
                    }
                    
                    if (!this.props.data('users', this.props.data('listings', keys[i])['tripOrganizerId'])) {
                        return false;
                    }
                }
            }
        }
        listingCoords = listingCoordsHolder
        return true;
    }

    listingFull(id) {
        const listingData = this.props.data('listings', id);
        var total = 0;
        const reservedList = listingData['reservedGuests'];
        for (var i in reservedList) {
            total += parseInt(reservedList[i]['reservedSpots'], 10)
        }
        return (listingData['availableSpots'] === 0)
    }

    renderListings() {
        var keys = Object.keys(this.props.data('channels', this.props.channel));
        
        //take out display field (and others?)
        for (var i = 0; i < keys.length; i += 1) {
            if (keys[i] === 'display') {
                keys.splice(i, 1);
                i -= 1;
            } else if (this.listingFull(keys[i])) {
                keys.splice(i, 1);
                i -= 1;
            } else if (this.props.isMemory(this.props.data('listings', keys[i])['toDate'])) {
                keys.splice(i, 1);
                i -= 1;
            }
        }
        

        return (
            <div>
                {keys.map((id) => (
                    // <Link className='idv-listing' to={"/listing?id="+id}>
                    <div 
                        onMouseEnter={() => this.handleMouseEnter(id)}
                        onMouseLeave={() => this.handleMoueLeave()}
                        id={"listing_"+id}
                    >
                        <Link 
                            className='idv-listing fadein' 
                            style={{
                                border:
                                (id === this.state.selectedListing) ? ('1px solid #E14658') : ('1px solid #e5e5e5')
                            }}
                            
                            to={{
                            pathname: "/myTripsDetails/" + id + "/" + this.props.data('listings', id)['tripOrganizerId']
                        }}>

                            <div className='ex-listing-img'>
                                <div className='ex-listing-img-area'><img src={this.props.data('listings', id)['imageUrlList'][0]}/></div>
                                <div className='ex-avatar'>
                                    <div className='ex-avatar-img-area'><img src={this.props.data('users', this.props.data('listings', id)['tripOrganizerId'])['imageUrl']}/></div>
                                    <div className='ex-avatar-details'>
                                        <div className='ex-avatar-name'>{this.props.data('users', this.props.data('listings', id)['tripOrganizerId'])['firstName']} {this.props.data('users', this.props.data('listings', id)['tripOrganizerId'])['lastName']}</div>
                                        <div className='ex-avatar-location'>{this.props.data('users', this.props.data('listings', id)['tripOrganizerId'])['city']}, {this.props.data('users', this.props.data('listings', id)['tripOrganizerId'])['stateCountry']}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='ex-listing-content'>
                                <div>
                                    <div className='ex-listing-title'>{this.props.data('listings', id)['listingTitle']}</div>
                                    <div className='ex-listing-description'>
                                        {this.props.data('listings', id)['description']}
                                        <div className='ex-listing-description-fade'></div>
                                    </div>
                                </div>   
                                <div className='ex-listing-footer'>
                                    <div className='ex-listing-actions'>&#160;</div>
                                    <div className='ex-listing-date-price'>
                                        <div className='ex-listing-date'>{this.props.formatDateRange(this.props.data('listings', id)['fromDate'], this.props.data('listings', id)['toDate'])}</div>
                                        <div className='ex-listing-price'>${this.props.data('listings', id)['pricePerSpot']} per spot</div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        if (!this.props.data('channels', this.props.channel)) {
            return (
                <Loading notFound={true}></Loading>
            )
        } else if ((!this.props.data('channels', this.props.channel)) || (!this.currentListings())) {
            return(
                <Loading></Loading>
            )
        } else {
            if (this.currentListings() === 1) {
                return (
                    <div>
                        <head>
                            <meta charSet='UTF-8'/>
                            <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                            <title>Events</title>
                        </head>
                        <body>
                            <NavBar
                                firebase = {this.props.firebase}
                                currentUser = {this.props.currentUser}
                                data = {this.props.data}
                                emptyCache = {this.props.emptyCache}
                            ></NavBar>
                            <section className='ex-header'>
                                <div className='ex-event'>{this.props.data('channels', this.props.channel).display.split("<p>")[0]}</div>
                                <div className='ex-event-details'>
                                    <div className='ex-date'>{this.props.data('channels', this.props.channel).display.split("<p>")[1]}</div>
                                    <div className='ex-location'>{this.props.data('channels', this.props.channel).display.split("<p>")[2]}</div>
                                </div>
                            </section>
        
                            <section className='exchange'>
                                <section className='ex-listing'>
                                    <div className='ex-no-listing-container'>
                                        <div className='ex-no-listing-header'>There are currently no listings</div>
                                        <div className='ex-no-listing-subheader'>Be the first person to organize a trip!</div>
                                        <Link to="/listingCreate?id=1"><div className='btn-one'>Create Listing</div></Link>
                                    </div>
                                </section>
        
                                <section className='ex-map'>
                                    <div className="cl-map-container" style={{height: "100%"}}>
                                        <Map
                                            
                                            addressSearch = {false}
                                            channelView = {true}
                                            eventCoord = {this.props.data('channels', this.props.channel).display.split("<p>")[4]}
                                            listingCoords = {[]}
                                            data = {this.props.data}
                                            isMemory = {this.props.isMemory}
                                        ></Map>
                                    </div>
                                </section>

                            </section>
                        </body>
                    </div>
                )
            } else {
                return (
                        <div>
                            <head>
                                <meta charSet='UTF-8'/>
                                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0'/>
                                <title>Events</title>
                            </head>
                            <body>
                                <NavBar
                                    firebase = {this.props.firebase}
                                    data = {this.props.data}
                                    view = ""
                                    emptyCache = {this.props.emptyCache}
                                ></NavBar>
                                <section className='ex-header'>
                                    <div className='ex-event'>{this.props.data('channels', this.props.channel).display.split("<p>")[0]}</div>
                                    <div className='ex-event-details'>
                                        <div className='ex-date'>{this.props.data('channels', this.props.channel).display.split("<p>")[1]}</div>
                                        <div className='ex-location'>{this.props.data('channels', this.props.channel).display.split("<p>")[2]}</div>
                                    </div>
                                </section>
            
                                <section className='exchange'>
                                    <section className='ex-listing'>
                                        {this.renderListings()}
                                    </section>
            
                                    <section className='ex-map'>
                                        <div className="cl-map-container" style={{height: "100%"}}>
                                            <Map
                                                scrollToListing = {this.scrollToListing}
                                                hoveredId = {this.state.hoveredId}
                                                addressSearch = {false}
                                                channelView = {true}
                                                listingCoords = {listingCoords}
                                                eventCoord = {this.props.data('channels', this.props.channel).display.split("<p>")[4]}
                                                data = {this.props.data}
                                                isMemory = {this.props.isMemory}
                                            ></Map>

                                        </div>
                                    </section>
            
                                </section>
                            </body>
                        </div>
                )
            }
        }
    }
}

export default Channels;