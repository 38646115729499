import React, {Component} from 'react';

import Loading from '../Loading';
import MyAccount from './MyAccount.js';
import OtherAccount from './OtherAccount.js';

import profile from '../../images/profile.jpg';
import instagram from '../../images/instagram.svg';
import facebook from '../../images/facebook.svg';
import snapchat from '../../images/snapchat.svg';
import twitter from '../../images/twitter.svg';

import NavBar from '../Navigation';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";


class Reviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
        }  

        this.renderReviews = this.renderReviews.bind(this);
        this.renderReview = this.renderReview.bind(this);
    }

    componentWillMount() {
        /*
        var comments = this.props.data('users', this.props.viewId)['comments'];
        
        var commentMap = {};
        //TODO: sort by time
        for (var i in comments) {
            var commentArray = comments[i].split("<p>");
            var date = commentArray[2];
            var dateArray = date.split("/");
            var dateValue = (dateArray[2] * 384) + (dateArray[0] * 32) + (dateArray[1]);
            commentMap[dateValue] = commentArray;
        }

        this.setState({comments: comments});*/
    }

    renderReviews() {
        const u = this.props.data('users', this.props.viewId);
        if (u['reviews'].length === 0) {
            return (
                <div className='profile-review-item'>
                    <div className='profile-review-content'>
                        {'No one has left a comment on ' + u['firstName'] + ' ' + u['lastName'] +' profile yet.'}
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    {u['reviews'].map(({comment, date, fromId})=>(this.renderReview(comment, date, fromId)))}
                </div>
            )
        }
    }

    renderReview(comment, date, fromId) {
        const u = this.props.data('users', fromId);
        return (
            <div className='profile-review-item'>
                <div className='profile-review-row'>

                    <Link push to={"/account?id=" + fromId} className='profile-avatar-area'>
                        <img src={u['imageUrl']}/>
                        <div className='profile-avatar-details'>
                            <div className='profile-avatar-name'>{u['firstName'] + " " + u['lastName']}</div>
                            <div className='profile-avatar-location'>{u['city'] + ", " + u['stateCountry']}</div>
                        </div>
                    </Link>

                    <div className='profile-review-timestamp'>
                        {date}
                    </div>
                    {/*{this.renderEditButton()}*/}
                </div>
                <div className='profile-review-content'>
                    {comment}
                </div>
            </div>
        )
    }

    render() {
        //<div className='profile-review-header'>{u['firstName'] + ' ' + u['lastName'] + ' has '}<strong>[joined 4 trips]</strong> and <strong>[organized 2 trips]</strong></div>
        const u = this.props.data('users', this.props.viewId);
        return (
            <section className='profile-review-container'>
                {this.renderReviews()}
            </section>
        )
    }
}

export default Reviews;